import React from 'react';
import {AppComponent, GenericComponent} from "../../../../utils/proto/general";

import {Button, Grid, Icon, Menu, Segment} from 'semantic-ui-react'
import {getLocalString} from "../../../../../utils/localString";
import {riskProbabilityOptions} from "../../risk/utils/probability";
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {Link} from "react-router-dom";


export class RiskSetupQuestionForm extends AppComponent {



    renderOption(option, pos){

        let {question} = option;
        // console.log(`${question} => ${probability}`);
        return  <Menu.Item
            as={'a'}
            key={pos}
            onClick={()=>{
                this.props.onAnswerSelected(pos);
            }}>
            

            {Number(pos) === Number(this.props.selectedAnswer) ? <Icon name={'check'} color={'teal'} /> : null}
            {question}
        </Menu.Item>
    }

    renderItems(){
        let {options} = this.props;
        if (!options) options = [];

        return options.map((option,pos) => this.renderOption(option, pos))
    }



    render(){
        return <Menu vertical>
            {this.renderItems()}
        </Menu>
    }

}



let lowestProb = Object.keys(riskProbabilityOptions).map(x=>Number(x));
lowestProb.sort();

lowestProb = lowestProb[1];

export class RiskSetupForm extends BasicUpdateForm('risk', undefined, (props)=>props.item.id) {


    getFormMeta(){
        return [

            {name: 'is_starred', type: 'checkbox_button', icon: 'star', title: 'star'},
            {name: 'question', type: 'question'},
            {name: 'probability', type: 'answers'},
            // {name: 'probability', type: 'text'},
        ]

    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);
        if (item.extra.selectedAnswer !== undefined){
            item.is_setting_up = false;
        } else {
            item.probability = lowestProb;
        }



    }

    renderCustomField(meta){

        let {type} = meta;

        let value = this.state[meta.name];
        let {extra = {question: '', answers: []}} = this.state;
        let {selectedAnswer = -1, answers} = extra;
        let fieldProps = this.getFieldProps(meta);
        // console.log(this.state);
        // console.log(this.props.item);
        switch (type){
            case 'answers':
                //console.log(this.state);
                return <RiskSetupQuestionForm {...this.props}
                                              {...fieldProps}
                                              options={extra.answers}
                                              selectedAnswer={selectedAnswer}
                                              onAnswerSelected={(answerId)=>{

                                                  let answer = answers[answerId];
                                                  let {probability} = answer;

                                                  let updatedExtra = {...extra, selectedAnswer: answerId};
                                                  let updatedState = {
                                                      changed: true,
                                                      probability,
                                                      extra: updatedExtra,
                                                  };

                                                  this.setState(updatedState);

                                              }}

                />;
            case 'question':
                return <h4>{extra.question}</h4>;
            case 'description':
                return <p>{value}</p>;

            default:
                return super.renderCustomField(meta);
        }

    }

    renderFields(){
        let {fieldsList} = this.getRenderedFields();

        let star = fieldsList.splice(0, 1);


        return <div>
            <div style={{marginLeft: 'calc(100% - 100px)', marginTop: '-80px', marginBottom: '30px'}}>{star}</div>
            {fieldsList}
        </div>
    }

    renderNextButton(){

        return <Segment textAlign={'center'}>
            <Link to={this.purl('/controls')}>
                <Button color={'green'}  content={getLocalString(this.props, 'go_to_controls')}/>
            </Link>
        </Segment>

    }

    componentDidUpdate(prevProps, prevState){

        if (this.state.id !== prevState.id){
            this.setState({complete: false});
        }

    }

    renderComplete(){

        let {nextItem} = this.props;


        if (nextItem && this.props.item.shouldGoToNext){


            this.props.history.push(this.purl(`/risks/${nextItem.id}`));
            return super.renderComplete();

        } else {

            return this.renderNextButton();
        }



    }

}







export class SetupRiskView extends WithExtras(GenericComponent, ['risk']) {




    renderMenuWithItems(items, id){


        return <Menu vertical>
            {items.map((i, pos) => <Menu.Item
                key={i.id}
                active={(Number(i.id) === Number(id)) || (pos === 0 && id === 0)}
                as={'a'}
                onClick={()=>{
                    this.props.history.push(this.purl(`/risks/${i.id}`));
                }}
            >

                {i.is_setting_up ? null : <Icon name='check' />}
                {i.acronym || i.id || getLocalString(this.props, 'na')}


            </Menu.Item>)}


        </Menu>
    }

    renderItemForm(item, nextItem){

        return <Segment>

            <h1>{getLocalString(this.props, 'risk_setup')}</h1>



            <RiskSetupForm {...this.props} item={item} nextItem={nextItem} storeName={'risk'}/>
        </Segment>;
    }


    renderWithVerification(){

        // Get items
        let {list, items: itemsById} = this.props.state.risk;



        let items = list.map(x=>itemsById[x.id]);

        let selectedId = Number(this.props.match.params.id) || 0;


        let selectedItem = Number(selectedId) ? itemsById[selectedId] : items.length ? items[0] : null;

        //console.log({selectedId, selectedItem, itemsById, items});


        // Caclulate nextItem
        let otherItems = [...items].reverse();

        // Remove items until we find the selected one



        while (otherItems.length > 0){
            let i = otherItems.pop();
            if (selectedItem && i && (i.id == selectedItem.id)) {
                break;
            }

        }

        let nextItem = otherItems.length ? otherItems.pop() : null;
        while (otherItems.length > 0 && !nextItem.is_setting_up) nextItem = otherItems.pop();



        return <Grid padded>
            <Grid.Column width={4}>


                <Button color={'green'} onClick={()=>{this.props.history.push(this.purl('/controls'))}}>
                    {getLocalString(this.props, 'go_to_controls')}
                    <Icon name={'arrow right'} />
                </Button>
                <br/>

                <br/>
                <div>
                    <Icon name={'check'} />
                    <span style={{color: '#5d5c5c'}}>{getLocalString(this.props, 'near_risk_indicates_setup_status')}</span>
                </div>



                {this.renderMenuWithItems(items, selectedId)}


            </Grid.Column>

            <Grid.Column width={12}>
                {selectedItem ? this.renderItemForm(selectedItem, nextItem) : null}
            </Grid.Column>
        </Grid>

    }

}
