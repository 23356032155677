import React from 'react';
import {getLocalString} from "../../../../../utils/localString";
import {AppComponent} from "../../../../utils/proto/general";

import {Tab} from 'semantic-ui-react';
import {RiskCategoryActivityMatrixView} from "./activities/risk_categories";
import {ControlAreaActivityMatrixView} from "./activities/control_area";

/*
*
   { menuItem: 'Tab 1', render: () => <Tab.Pane>Tab 1 Content</Tab.Pane> },
   { menuItem: 'Tab 2', render: () => <Tab.Pane>Tab 2 Content</Tab.Pane> },
   { menuItem: 'Tab 3', render: () => <Tab.Pane>Tab 3 Content</Tab.Pane> },
*
* */





export class SetupConfigTabView extends AppComponent {

    tabs = {
        'risks': {menuItem: getLocalString(this.props, 'risks'), render:()=><RiskCategoryActivityMatrixView {...this.props}/>},
        'controls':  {menuItem: getLocalString(this.props, 'controls'), render:()=><ControlAreaActivityMatrixView {...this.props} />},
        // 'activities':  {menuItem: getLocalString(this.props, 'activities'), render:()=><AppComponent />},
    };


    renderWithVerification(){

        let {config_type = 'risks'} = this.props.match.params;

        // Add position to the tabs
        let tabEntries = Object.values(this.tabs);


        tabEntries.forEach((tab, position)=>{
            tab.position = position;
        });


        let selectedPane = this.tabs[config_type].position;


        return <Tab
            panes={tabEntries}
            defaultActiveIndex={selectedPane}

        />

    }

}