import React from 'react';


import {WithList} from "../../utils/proto/mixins/withList";
import {GenericComponent} from "../../utils/proto/general";
import {getCurrentCompany} from "../../../utils/sessionHelper";
import {Dropdown, Menu} from 'semantic-ui-react'
import {getLocalString} from "../../../utils/localString";
import {getCompaniesForUserFromRelations} from "../../../mvvm/reducers/utils/company";





export class CompaniesDropdown extends WithList(GenericComponent, '/api/user_company_relation/list'){



    get companies(){
        let relations = this.state.payload || [];

        return getCompaniesForUserFromRelations(this.props, relations);
    }


    renderOptions(companies){

        if (!companies.length) return <div style={{margin: 10, marginBottom: 15, fontSize: 10, color: 'grey'}}>{getLocalString(this.props, 'no_companies_in_dropdown_text')}</div>;





        return companies.map((c, pos)=>c ? <Dropdown.Item
            key={pos}
            onClick={()=>{this.props.history.push(`/companies/${c.id}`)}}>{c.name}</Dropdown.Item> : <div key={pos}>{getLocalString(this.props, 'no_such_company')}</div>)
    }


    renderWithVerification(){

        let {companies} = this;
        let currentCompany = getCurrentCompany(this.props);

        let text = getLocalString(this.props, 'select_company');
        if ((currentCompany && currentCompany.id)) text = currentCompany.name;

        return <Menu.Item>
                <Dropdown text={text} button basic fluid>
                    <Dropdown.Menu>
                        <Dropdown.Header content={getLocalString(this.props, 'companies')} />
                        {this.renderOptions(companies)}
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Item>


    }


}