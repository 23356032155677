import React from 'react';
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {ControlDropdown} from "../../../utils/components/form/components/store_dropdown/control_dropdown";
import {
    ProjectUserDropDown
} from "../../../utils/components/form/components/store_dropdown/user_dropdown";
import {Grid, Responsive} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";


export class CreateTaskForm extends BasicCreateForm('task'){

    getFormMeta(){
        return [

            {name: 'control_id', title: 'control', type: 'control_dropdown'},
            {name: 'description', title: 'description', type: 'text'},
            {name: 'user_id', title: 'user', type: 'user_dropdown'},
            {name: 'date', title: 'date', type: 'date'},
            {name: 'no_date', title: 'no_due_date', type: 'checkbox'},
        ]
    }


    prepareItemForSubmission(item){
        if (item.no_date) item.date = null;
        else if (!item.date) item.date = new Date();
    }




    renderCustomField(meta){

        let {type} = meta;
        let fieldProps = this.getFieldProps(meta);

        switch (type){

             case 'control_dropdown':
                return <ControlDropdown {...this.props} {...fieldProps}  />;
             case 'user_dropdown':
                return <ProjectUserDropDown {...this.props} {...fieldProps} onlyProject />;
            default:
                return super.renderCustomField(meta);

        }
    }

    renderFormHeader(){
        return <h3>{getLocalString(this.props, 'new_task')}</h3>
    }


    renderFields() {
        let {fieldsList} = this.getRenderedFields();

        let [control, description, user, date, no_date] = fieldsList;

        fieldsList[0] = <div style={{maxWidth: 400}}>{control}</div>;
        fieldsList[1] = <div style={{minWidth: 400}}>{description}</div>;

        fieldsList[3] = <div>
            {this.state.no_date ? <p style={{textAlign: 'center', color: '#1b1c1d'}}>{getLocalString(this.props, 'no_due_date')}</p> : date}{no_date}
        </div>;

        delete fieldsList[4];

        return <div>

            <Responsive minWidth={1440}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {fieldsList}
                </div>


            </Responsive>

            <Responsive maxWidth={1439} minWidth={0}>

                <Grid>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {control}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {description}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {user}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        {date}
                        {no_date}
                    </Grid.Column>
                </Grid>

            </Responsive>


        </div>;
    }





}