import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicCreateForm} from "../../utils/proto/mixins/form/create";

class FormProto extends BasicCreateForm('user_type') {


    title = () => getLocalString(this.props, 'create_user_type');

    getFormMeta() {
        return [
            {name: 'level', title: 'level', type: 'number'},
            {name: 'value', title: 'value', type: 'text'},
        ]
    }


}


export const AdminUserTypeCreateForm = (props) => <FormProto {...props} storeName={'user_type'}/>;