import React from 'react';
import {RiskForm} from "./form";
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {AttachProblemsField} from "./fields/problems";
import {getLocalString} from "../../../../utils/localString";
import {getDefaultFromQuery, getQueryParamsMap} from "../../../../utils/path";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";

export class RiskCreateForm extends RiskForm(BasicCreateForm('risk')){


    state ={
        ...super.state || {},
        probability:   1.8,
        attached_problems: getDefaultFromQuery('problem_id', 'string', val => (val ? {[val]: true} : {})),
        is_opportunity: getDefaultFromQuery('is_opportunity', 'boolean')
    };

    getFormMeta() {
        return [
            ...super.getFormMeta(),
            {name: 'attached_problems', type: 'attached_problems'}
        ]
    }

    renderPreForm(){

        const risk = this.state;

        const title = risk.is_opportunity ? `create_opportunity` : `create_risk`;

        return <h3>{getLocalString(this.props, title)}</h3>

    }


    renderCustomField(meta) {

        let props = this.getFieldProps(meta);
        if (meta.type === 'attached_problems'){
            return <div style={{marginTop: 50}}>
                <h1>{getLocalString(this.props, 'link_problems')}</h1>
                <AttachProblemsField {...this.props} {...props} />
            </div>
        }

        return super.renderCustomField(meta);
    }

    renderComplete(){

        const risk = this.state.payload;
        const qParams = getQueryParamsMap();
        const {ret_problem, goBackToMonitoring} = qParams;
        if (ret_problem) {
            this.props.history.push(this.purl(`/problems/${ret_problem}`));
        } else if (goBackToMonitoring) {
            const [reportId, reportItemId] = goBackToMonitoring.split(',');
            this.props.history.push(this.purl(`/monitoring_reports/${reportId}/${reportItemId}`))
        } else {

            this.props.history.push(this.purl(risk.is_opportunity ? '/opportunities' : '/risks'));
        }

        return null;
    }

    renderWithVerification(){

        return <div>
            <NavBackTopLeftButton />
            {super.renderWithVerification()}
        </div>
    }

}
