import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {AuditList} from "./list";
import {AuditCreateForm} from "./create";
import {AuditUpdateForm} from "./update";
import {AuditsFutureList} from "./future";


class Router extends WithExtras(WithRouterLayout(GenericComponent), ['audit', 'control']){

    prefix = this.purl('/audits' );

    routesMeta = {
        '/create': {Component: AuditCreateForm},
        '/future': {Component: AuditsFutureList},
        '/:id': {Component:AuditUpdateForm},
        '*': {Component: AuditList},
    };

}



export default Router;