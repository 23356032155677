import {action_set_subscriptions} from "../../../mvvm/reducers/custom/subscriptions";

import {fetchApi} from "../../../mvvm/api/fetch";


export const WithSubscription = (sc) => class extends sc {





    state = {

        ...super.state,
        plansAreLoaded: false,
    };



    componentDidUpdate(prevProps, prevState, snapshot){

        if (prevState.company_id && (prevState.company_id !== this.company_id)) {
            this.getSubscriptions()
        }

    }

    get company_id(){

        let [,,company_id,] = window.location.pathname.split('/');


        return company_id;
    }

    get store(){

        return this.props.state.subscriptions;
    }


    getSubscriptions = ()=>{


        let {dispatch} = this.props;

        fetchApi('/api/subscriptions/list', {company_id: this.company_id}).then(({body})=>{

            let {payload, success} = body;
            dispatch({type: action_set_subscriptions, data: success ? payload || [] : []});


            this.setState({plansAreLoaded: true, company_id: this.company_id});
        });


    };

    componentDidMount(){
        if (super.componentDidMount) super.componentDidMount();

        this.getSubscriptions();

     }


    renderWithoutSubscriptions(){

        this.props.history.push(`/subscribe/${this.company_id}`);


        return null;
    }


    renderWithInactiveSubscription(sub){
        return 'Not active';
    }


     renderWithVerification(){

        let {subscriptions} = this.store;

        let {plansAreLoaded} = this.state || {};

        // If we haven't loaded yet, proceed as before
        if (!plansAreLoaded) return super.renderWithVerification();


        // User doesn't have any subscriptions
        if (!(subscriptions && subscriptions.length)){
            return this.renderWithoutSubscriptions();
        }


        for (let sub of subscriptions){
            if (sub.state === 'active'){
                return super.renderWithVerification();
            }
        }


        return this.renderWithoutSubscriptions();

     }

};