import React from "react";


export class SidebarSecondaryMenu extends React.Component {


    static Menu =  class  extends React.Component {


        render (){
            return <div className="rh-sb-submenu">
                {this.props.children}
            </div>
        }
    };





    render(){


        return <div className={this.props.expanded ? 'rh-sb-secondary expanded' : 'rh-sb-secondary'}>
            {this.props.children}
        </div>
    }

}




