import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicCreateForm} from "../../utils/proto/mixins/form/create";



export class LocaleCreateForm extends BasicCreateForm('locale') {


    title = () => getLocalString(this.props, 'create_locale');


    // key: {...defaultString,  unique: true},
//         display_name: {...defaultString, unique: true}

    getFormMeta() {
        return [
            {name: 'key', title: 'key', type: 'text'},
            {name: 'display_name', title: 'display_name', type: 'text'},
        ]
    }


}