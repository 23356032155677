import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {AppComponent} from "../general";


export const WithRouterLayout = (sc) => class extends sc {

    prefix = '';
    postfix = '';
    routesMeta = {

        'sample': {route: '/sample', Component: AppComponent}

    };

    get _prefix(){

        if (typeof this.prefix === 'function') return this.prefix();
        return this.props.prefix || this.prefix;
    }
    get _postfix(){

        if (typeof this.postfix === 'function') return this.postfix();
        return this.props.postfix || this.postfix;
    }
    get _routesMeta(){
        return this.props.routesMeta || this.routesMeta;
    }


    getRoute({key, meta, routeIndex}){
        let {Component, route=key, ignorePrefix=false, ignorePostfix=false} = meta;
        let compiledRoute = `${ignorePrefix ? '' : this._prefix}${route}${ignorePostfix ? '' : this._postfix}`;
        //console.dir({compiledRoute, Component});
        return <Route key={key} path={compiledRoute} render={(rp)=><Component routeIndex={routeIndex} routeKey={key} routeMeta={meta} {...this.props} {...rp}/>} />;
    }

    getRoutes(){
        let metas = this._routesMeta;
        return Object.entries(metas).map(([key, meta], routeIndex)=>this.getRoute({key,meta, routeIndex}));
    }

    renderRoute(){
        return <Switch>
            {this.getRoutes()}
        </Switch>
    }

    renderWithVerification(){
       return this.renderRoute();
    }
};