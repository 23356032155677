import React from 'react';

import {Button, List, Segment} from 'semantic-ui-react';
import {GenericComponent} from "../../../utils/proto/general";

import {getLocalString} from "../../../../utils/localString";
import {getFromStoreById} from "../../../../utils/getFromStore";
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {WithStoreList} from "../../../utils/proto/mixins/withList";
import {getCurrentProjectId} from "../../../../utils/sessionHelper";


class UserRelationList extends WithStoreList(GenericComponent, 'user_project_relation' ){


    renderItem(map){
        let {user, rel, index} = map;
        return <List.Item key={rel.id}>
            <List.Content floated={'right'}>
                <Button
                    content={getLocalString(this.props, 'delete')}
                    color={'red'}
                    size={'tiny'}
                    loading={this.isRemovingItem(rel)}
                    onClick={()=>{
                                this.removeItem(rel, index)
                           }}
                />
            </List.Content>
            <List.Content verticalAlign='middle'>
                <List.Header>{user.first_name} {user.last_name}</List.Header>
                {user.email}
            </List.Content>
        </List.Item>


    }

    renderItems(){

        // Get relations
        let {items} = this;
        let {userType} = this.props;

        let project_id = getCurrentProjectId(this.props);

        let maps = items.map(rel => {

                let user = getFromStoreById(this.props, 'user', rel.user_id);
                if (rel.access_level == userType && rel.project_id == project_id) return {user, rel};
                else return null;
            }
        ).filter(x=>!!x);


        return maps.map(map=>this.renderItem(map));


    }


    renderWithVerification(){
        return <List>
            {this.renderItems()}
        </List>;
    }
}

class CreateRelation extends BasicCreateForm('user_project_relation'){

    getFormMeta(){
        return [
            {name: 'email', title:'invite_by_email', type: 'email'}
        ]
    }



    prepareItemForSubmission(item) {

        super.prepareItemForSubmission(item);
        item.access_level = this.props.userType;
    }


    renderAfterSubmit(){

        let {onGoBack} = this.props;

        return <Segment textAlign={'center'}>
            <Button
                color={'green'}
                icon={'check'}
                content={getLocalString(this.props, 'invite_sent')}
                onClick={()=>{
                    this.setIncomplete();
                    onGoBack();}}
            />
        </Segment>
    }

}

export class ProjectAccessList extends GenericComponent {


    state = {
        creating: false,
    };

    storeName = 'user_project_relation';
    userType = 3;

    renderWithList(){
        return <Segment>
            <UserRelationList {...this.props} storeName={this.storeName} userType={this.userType} />
            <Button fluid
                    color={'blue'}
                    icon={'plus'}
                    onClick={()=>{this.setState({creating: true})}}
                    content={getLocalString(this.props, 'invite_new_person')}
            />
        </Segment>
    }

    renderWithCreate(){
        return <Segment>
            <CreateRelation {...this.props} storeName={this.storeName} userType={this.userType} onGoBack={()=>{this.setState({creating: false})}}/>
        </Segment>
    }

    renderWithVerification(){

        let {creating} = this.state;

        if (creating) return this.renderWithCreate();
        else return this.renderWithList();
    }

}