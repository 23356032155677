import React from 'react';
import {RiskProbabilityField} from "./utils/probability";
import {Form} from 'semantic-ui-react';
import {FlexRow} from "../../../utils/components/flexRow";
import {getFromStoreById} from "../../../../utils/getFromStore";
import {getLocalString} from "../../../../utils/localString";
import {getDefaultFromQuery} from "../../../../utils/path";


export const RiskForm = (sc) => class extends sc {

    state = {
        ...super.state,
        attached_problems: getDefaultFromQuery('problem_id', undefined, id=>({[id]: true})),
        is_opportunity: getDefaultFromQuery('is_opportunity', 'boolean')

    };

    getFormMeta(){
        return [

            {name: 'is_opportunity', type: 'checkbox_button', title: 'opportunity', icon: 'check'},
            {name: 'is_starred', type: 'checkbox_button', icon: 'star', title: 'star'},


            //{name: 'level', title: 'level', type: 'label'},
            {name: 'risk_category_id', title: 'risk_category', type: 'search_dropdown',
                storeName: 'risk_category',
                textFieldName: 'name',
                allowNull: false,
            },

            {name: 'description', title: 'description', type: 'textarea'},

            {name: 'probability', title: 'probability', type: 'probability', defaultValue: 1},
            {name: 'motivation', title: 'motivation', type: 'froala_field'},

        ];
    }

    prepareItemForSubmission(item){
        item.level=3;
    }


    renderCustomField(meta){

        let {type} = meta;

        switch(type){
            case 'probability':
                return this.renderProbabilityField(meta);
            default:
                return super.renderCustomField(meta);
        }
    }

    renderProbabilityField(meta){

        let value = Number(this.getValueForField(meta));

        // Handler for a NaN
        if (isNaN(value)) return <span style={{color: 'red'}}>NaN</span>;

        let props = this.getFieldProps(meta);

        return <Form.Field key={props.key}>
            <label>{props.label}</label>
            <RiskProbabilityField {...{...props, value}} {...this.props} is_opportunity={this.state.is_opportunity}/>
        </Form.Field>
    }

    renderMainFields(fields){
        return fields;
    }


    renderPreForm(){


        const {fieldsList} = this.getRenderedFields();

        const toggles = fieldsList.splice(0,2);


        return <FlexRow justify={'space-between'}>
            <div>
                {super.renderPreForm()}
            </div>

            <div>
                {toggles}
            </div>
        </FlexRow>

    }


    renderFields(){

        const {fieldsList} = this.getRenderedFields();

        // Remove toggles
        fieldsList.splice(0,2);

        return <div>

            {this.renderMainFields(fieldsList)}


        </div>

    }

};
