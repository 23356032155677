import React from 'react';
import {getLocalString} from "../../../../../utils/localString";
import {KPIReportSelector} from "./kpi";
import {Button} from "semantic-ui-react";
import {GenericComponent, purl} from "../../../../utils/proto/general";
import {WithApi} from "../../../../utils/proto/mixins/api";
import {FlexRow} from "../../../../utils/components/flexRow";


export class ReportCreateView extends  WithApi(GenericComponent) {

    state = {
        ...super.state,

        selectedKpis: {},


    };


    startEvaluation(){
        const {state} = this;

        this.api('/api/monitoring_report/create', state,
            {
                onRequestSuccess: (resp, options) => {

                    if (resp && resp.body && resp.body.payload && resp.body.payload.id){
                        this.props.history.push(purl(this.props, `/monitoring_reports/${resp.body.payload.id}`))
                    } else {
                        return super.onRequestSuccess(resp, options);
                    }

                }
            }
        );

    }




    renderWithVerification(){
        return <div>
            <h2>{getLocalString(this.props, 'select_kpis')}</h2>

            <FlexRow justify={'flex-end'}>

                <div>
                    <Button
                        color={'green'}
                        icon={'plus'}
                        content={getLocalString(this.props, 'not_found?_create_one')}
                        onClick={()=>{this.props.history.push(purl(this.props, '/kpis/create?goBackTo=create_monitoring'))}}
                    />
                </div>

            </FlexRow>

            <KPIReportSelector {...this.props} selectedKpis={this.state.selectedKpis} onKpiClick={(kpi)=>{


                this.setState({selectedKpis: {[kpi.id]: kpi}});

            }}/>



            <div>

                <Button
                    color={'green'}
                    icon={'flag'}
                    onClick={this.startEvaluation.bind(this)}
                    content={getLocalString(this.props, 'start_evaluation')}

                />
            </div>

        </div>;
    }
}