import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicUpdateForm} from "../../utils/proto/mixins/form/update";

class FormProto extends BasicUpdateForm('project_type') {


    title = () => getLocalString(this.props, 'edit_user_form');

    getFormMeta() {
        return [

            {name: 'name', title: 'name', type: 'text'},
            {name: 'business_menu', title: 'is_a_business_project', type: 'checkbox'},
            {name: 'consulting_menu', title: 'is_a_consulting_project', type: 'checkbox'},
            {name: 'acronym', title: 'acronym', type: 'text'}
        ]
    }

    prepareItemField(meta, item){
        let {type, name} = meta;

        switch (type){
            case 'checkbox':
                item[name] = !!item[name];
                break;
            default:
                super.prepareItemField(meta,item);
        }
    }

}


export const AdminProjectTypeEditForm = (props) => <FormProto {...props} storeName={'project_type'}/>;