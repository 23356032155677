import React from 'react';
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";
import {LevelsOfImpactField} from "./loi";

import {Grid} from 'semantic-ui-react';
import {RiskCategoriesField} from "./riskCategories";

class ProjectThresholdSettingsProto extends BasicUpdateForm('project',undefined,(props)=>getCurrentProjectId(props)) {


    getFormMeta(){

        return [
            {name: 'weight_severity', title: 'weight_severity', type: 'number'},
            {name: 'risk_categories_thresholds', title: 'risk_categories', type: 'risk_categories'},
            {name: 'levels_of_impact', title: 'levels_of_impact', type: 'loi'},
        ]

    }




    renderCustomField(meta){

        let {type} = meta;

        let props = this.getFieldProps(meta);
        switch (type){
            case 'loi':
                return <LevelsOfImpactField  {...this.props} {...props} />;
            case 'risk_categories':
                return <RiskCategoriesField {...this.props} {...props} />;

            default:
                return super.renderCustomField(meta);
        }
    }


    renderFields() {
        let {fieldsMap} = this.getRenderedFields();
        return <Grid>

                <Grid.Column mobile={16}  tablet={16}  computer={4} >
                    {fieldsMap['weight_severity']}
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}  >
                    {fieldsMap['levels_of_impact']}
                </Grid.Column>
                <Grid.Column computer={6} tablet={8} mobile={16}>
                    {fieldsMap['risk_categories_thresholds']}
                </Grid.Column>

        </Grid>
    }

}


export const ProjectThresholdSettings = (p) => <ProjectThresholdSettingsProto {...p} storeName={'project'} />;