import React from 'react';
import {TableWithStoreList} from "../../utils/components/collections/table/store";
import {getLocalString} from "../../../utils/localString";
import {Button} from 'semantic-ui-react'
import {
  getCurrentCompany,
  getCurrentCompanyId,
  getCurrentProjectId,
  isCompanyOwner
} from "../../../utils/sessionHelper";
import {curl, GenericComponent} from "../../utils/proto/general";
import {FlexRow} from "../../utils/components/flexRow";

export class ProjectList extends TableWithStoreList('project') {

  getMeta() {
	return {
	  name: {type: 'text', collapsing: true},
	  //description: {type: 'text'},
	  // last_report_date: {type: 'date', collapsing: true},
	  actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},
	}

  }

  getLinkToCreate() {
	return this.curl('/create_project');
  }

  onCellClick({item: project}) {

	this.props.history.push(this.curl(`/projects/${project.id}`));

  }

  renderActionButtons({item}) {

	let project_id = getCurrentProjectId(this.props);
	let isSame = project_id === item.id;
	return <Button
	  disabled={isSame || undefined}
	  color={'red'}
	  size={'tiny'}
	  content={isSame ? getLocalString(this.props, 'project_is_selected') : getLocalString(this.props, 'delete')}
	  onClick={isSame ? undefined : () => {


		if (window.confirm(getLocalString(this.props, 'confirm_to_delete'))) {
		  this.removeItem(item);
		}

	  }}
	/>

  }


}


class ProjectLocalList extends ProjectList {


  getData() {

	return this.props.projects;
  }


  renderTableFooter() {
	return null;
  }


  renderPreTable() {
	return <h3>{this.props.title}</h3>

  }


}


const getBuckets = () => [
  {shortTitle: 'main_strategy', longTitle: 'company_main_strategy', items: [], color: 'green'},
  {shortTitle: 'level_1', longTitle: 'level_1_strategies', items: [], color: 'blue'},
  {shortTitle: 'level_2', longTitle: 'level_2_strategies', items: [], color: 'orange'},

];


export const Cube = (props) => {

  const {bucket, company, index} = props;

  const classNames = ['statistic', 'colored'];

  const {shortTitle, color, items} = bucket;

  if (color) classNames.push(color);

  const expected_number = company.project_level_requirements[index];


  return <div className={classNames.join(' ')}>

	<div className={'title'}>{getLocalString(props, shortTitle)}</div>
	<div className={'value'}>
	  {items.length}/{expected_number}
	</div>
  </div>;
};


export const Cubes = (props) => {

  const {buckets} = props;


  return <div className={'statistic-group even'}>
	{buckets.map((bucket, index) => <Cube key={index} {...props} index={index} bucket={bucket}/>)}
  </div>

};


export class ProjectListView extends GenericComponent {


  get projects() {

	const company_id = getCurrentCompanyId(this.props);

	const projects = this.props.state.project.items;

	const filteredProjects = Object.values(projects).filter(x =>
	  Number(x.company_id) === Number(company_id)
	);


	return filteredProjects || [];


  }


  get buckets() {

	const buckets = getBuckets();

	const {projects} = this;

	for (const project of projects) {

	  const bucket = buckets[project.level];
	  bucket.items.push(project);

	}

	return buckets;
  }

  renderWithVerification() {

	const {buckets, props} = this;
	const company = getCurrentCompany(props);


	const isOwner = isCompanyOwner(props);

	const companyTables = buckets.map((bucket, index) => {
	  return (
		<ProjectLocalList
		  key={index}
		  {...props}
		  company={company}
		  index={index}
		  bucket={bucket}
		  projects={bucket.items}
		  title={getLocalString(props, bucket.longTitle)}
		/>
	  )
	});

	return <div>

	  <div style={{display: 'flex', flexDirection: 'row-reverse', marginTop: 20}}>
		{isOwner ? <Button
		  icon={'plus'}
		  color={'green'}
		  content={getLocalString(props, 'create_project')}
		  onClick={() => {
			props.history.push(curl(props, '/create_project'))
		  }
		  }
		/> : null}
	  </div>


	  <FlexRow style={{margin: '20px 0'}} justify={'center'}><h1>{company.name}</h1></FlexRow>
	  <Cubes {...props} buckets={buckets} company={company}/>

	  {companyTables}
	</div>

  }

}
