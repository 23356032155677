import React from 'react';
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {Button, Table} from "semantic-ui-react";
import {FlexRow} from "../../../utils/components/flexRow";
import {getLocalString} from "../../../../utils/localString";

export class KPIList extends TableWithStoreList('kpi'){

    renderTableBody(data){

        if (! (data && data.length)){
            return this.renderWithoutItems();
        }

        let otherRows = data.map((i, k)=>this.renderRow(i, k));

        let top = otherRows.splice(0,3);

        return <Table.Body>

            {top}
            {this.state.expanded ? otherRows : null}
        </Table.Body>

    }

    renderTableFooter() {

        if (this.state.expanded) return null;

        return <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={this.colSpan}>
                    <FlexRow justify={'center'}>
                        <Button
                            color={'blue'}
                            icon={'plus'}
                            content={getLocalString(this.props, 'show_others')}
                            onClick={()=>{this.setState({expanded: true})}}
                            size={'tiny'}
                        />
                    </FlexRow>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>


    }


    getMeta(){
        return {
            description: {type: 'text'},
            'great_value': {title: 'kpi_great_value_header'},
            'user_id':{title: 'user', collapsing: true, type: 'external_value', store: 'user', targetField: 'full_name'},
            'date': {title: 'date', type: 'date', collapsing: true},

            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},
        }
    }



    getCellContent(p){
        let {item, key} = p;
        switch (key){
            case 'great_value':
                return (item.result_thresholds && item.result_thresholds[0]) || 0;
            default:
                return super.getCellContent(p);
        }
    }

}