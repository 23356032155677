export const getUrlParts = () => {
    return window.location.pathname.split('/');
};


export const getUrlPart = (index) => {
    const parts = getUrlParts();

    return index > parts.length-1 ? '' : parts[index];
};



export const getQueryParamsMap = () => {

    const map = {};

    const {search} = window.location;

    if (!search.length) return map;

    const paramsString = search.substr(1);
    const paramPairs = paramsString.split("&");

    for (let pair of paramPairs){
        const pairParts = pair.split("=");
        if (pairParts.length === 1){
            map[pair] = true;
        } else if (pairParts.length === 2){
            const [key, val] = pairParts;
            map[key] = val;
        }

    }

    return map;
};



const defaultConverter = (value, type) => {
    switch (type){

        case 'boolean':
            return Boolean(Number(value));
        case 'number':
            return Number(value);

        default:
            return value;
    }
};



export const getDefaultFromQuery = (name, type='string', converter=defaultConverter) => {
    const paramValues = getQueryParamsMap();

    if (!paramValues[name]) return undefined;
    //console.log(paramValues);
    return converter(decodeURI(paramValues[name]), type);
};