import React from 'react';
import {
    getCurrentProject,
    getCurrentUser
} from "../../../../utils/sessionHelper";
import {getLocalString} from "../../../../utils/localString";
import {Button} from "semantic-ui-react";
import {GenericComponent} from "../../../utils/proto/general";
import {CometChatBase} from "../../../utils/components/cometchat";



const chat_appid = '53309';
window.chat_appid = chat_appid;
window.chat_auth = 'ba63934c5ef8f417ea4c9c976aca46e7';




export class CometChat extends CometChatBase {

    state = {
        ...super.state,
        chatEnabled: false,
    };

    loadChat(){



        const {innerWidth, innerHeight} = window;

        let chat_width = Math.round(innerWidth * 0.9);
        let chat_height = Math.round(innerHeight * 0.9);

        // Mobile layout
        if (innerWidth < innerHeight) {
            chat_width = Math.round(innerWidth * 0.8);
            chat_height = Math.round(innerHeight * 0.9);
        }




        if (this.state.chatEnabled) return;


        (async () => {
            const project = getCurrentProject(this.props);

            const user = getCurrentUser(this.props);
            window.chat_id = user.cometchat_id;



            const chat_groupid = project.cometchat_id;



            const chat_js = document.createElement('script'); chat_js.type = 'text/javascript';
            chat_js.src = 'https://fast.cometondemand.net/'+chat_appid+'x_xchatx_xcorex_xembedcode.js';


            chat_js.onload = function() {
                const chat_iframe = {};
                chat_iframe.module="synergy";
                chat_iframe.style=`min-width: ${chat_width}px;min-height: ${chat_height}px;`;
                chat_iframe.width=chat_width;
                chat_iframe.height=chat_height;

                const args = [
                    ['guid', chat_groupid],
                   // ['chat_id', user.cometchat_id]
                ];
                const argsString  = args.map(x=>x.join('=')).join('&');

                chat_iframe.src=`https://${chat_appid}.cometondemand.net/cometchat_embedded.php?${argsString}`;
                if(typeof(window.addEmbedIframe)=="function"){window.addEmbedIframe(chat_iframe, ()=>{


                });}
            };
            const chat_script = document.getElementsByTagName('script')[0]; chat_script.parentNode.insertBefore(chat_js, chat_script);
        })();
        this.setState({chatEnabled: true});




    }

    checkAndEnableChat(){
        const {props} = this;
        const project = getCurrentProject(props);
        const user = getCurrentUser(props);

        if (project && project.cometchat_id && user && user.cometchat_id && !this.state.chatEnabled) {
            this.loadChat();
        }
    }

    componentDidMount() {

        super.componentDidMount();
        this.checkAndEnableChat();
    }

    componentDidUpdate(){
        this.checkAndEnableChat();
    }



    renderContainer(){
        return <div style={{
            display: 'flex',
            justifyContent: 'center'
        }}>
            <div id="cometchat_embed_synergy_container"/>
        </div>
    }



    renderCometchat(){
        const {props} = this;

        const project = getCurrentProject(props);
        const user = getCurrentUser(props);

        if (project && user) {

            if (project.cometchat_id) {

                if (user.cometchat_id){
                   return this.renderContainer();
                } else {

                   return this.renderActivateForUserButton();

                }


            } else {
                return this.renderActivateForProjectButton();
            }



        } else {
            console.log({project, user});
            return getLocalString(this.props, 'no_project_or_user');
        }




    }



    renderWithVerification() {


        return this.renderCometchat();

        // return <div>
        //
        //     {this.renderCometchat()}
        //
        //     <div>
        //         <Button
        //             content={getLocalString(this.props, 'authorize_cometchat')}
        //             icon={'user'}
        //             onClick={() => {
        //                 this.api('/api/user/enableCometchat');
        //             }
        //             }
        //         />
        //
        //
        //         <Button
        //             content={getLocalString(this.props, 'create_cometchat_group')}
        //             icon={'users'}
        //             onClick={() => {
        //                 this.api('/api/project/enableCometchat', getCurrentProject(this.props));
        //             }
        //             }
        //         />
        //     </div>
        //
        // </div>


     }
}