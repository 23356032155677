import React from 'react';
import {Dropdown, Form} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";


export class StoreDropdown extends React.Component {


    storeName = null;

    get store(){

        let {storeName} = this;
        if (!storeName) return  {};


        let store = this.props.state[storeName];

        return store || {}}



    get storeList(){
        return Object.values(this.store.items|| {} ) ;
    }

    getItemValue(item){
        return item.id;
    }

    renderItemText(){
        return <div> I don't know</div>
    }

    getOptionWithItem(item){

        return {
            value: this.getItemValue(item),
            text: this.renderItemText(item)

        }
    }

    get options(){

        let list = this.storeList;
        return list.map(item=>this.getOptionWithItem(item))

    }

    onChange(e, args){
        this.props.onChange(e, args);
    }

    render(){


        return <Form.Field>
            <label>{this.props.label}</label>
            <Dropdown
            options={this.options}
            name={this.props.name}
            value={this.props.value}
            onChange={this.onChange.bind(this)}
            search
            placeholder={this.props.placeholder || getLocalString(this.props, 'select_value_from_dropdown')}

        />
        </Form.Field>
    }

}
