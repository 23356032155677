import React from 'react';
import {TableWithStoreList} from "../../utils/components/collections/table/store";


class UserAdminListPrototype extends TableWithStoreList('user') {

    storeName= ()=>'user';

    getMeta() {
        return {
            id: {type: 'text', title: 'ID'},
            email: {title: 'email'},
            first_name: {title: 'first_name'},
            last_name: {title: 'last_name'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}

        }
    }

    renderFooter = () => null;

    getLinkToItem = (item) => `/admin/users/${item.id}`;


}


export const AdminUserList = UserAdminListPrototype;