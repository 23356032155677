import React from 'react';
import {GenericComponent} from "../utils/proto/general";
import {AuthRouter} from "./router";
import {action_update_session} from "../../mvvm/reducers/custom/session";
import {getCurrentCompanyId} from "../../utils/sessionHelper";


export const WithSession = (SuperComponent) => {
    return class extends GenericComponent {


        /**
         * Session assembler
         * @returns {{locale, user}}
         */
        get session(){
            let {user, locale} = this.props.state.session || {};
            return user && {user, locale};
        }

        renderWithoutSession(){
            return <AuthRouter {...this.props} />
        }

        renderWithVerification() {

            // Check if we do have a session
            let {session} = this;
            if (!session) return this.renderWithoutSession();



            return <SuperComponent {...this.props} session={session}/>;

        }


        shouldRedirect(rootElement){

            const elements = ['admin', 'report', 'tasks'];


            for (let e of elements){
                if (e === rootElement) return false;
            }

            return true;

        }

        onSessionExists = ({payload})=>{
            this.props.dispatch({type: action_update_session, data: payload});


            let [,rootElement, currentCompanyId] = window.location.pathname.split('/');



            let {company_id} = payload;

            if (this.shouldRedirect(rootElement) && (!currentCompanyId && company_id)){
                this.props.history.push(`/companies/${company_id}`);
            }

            return {};
        };

        componentDidMount(){
            this.api(`/api/session/get`, {},  {onSuccessResponse: this.onSessionExists, onErrorResponse: ()=>{ return {}}});
        }
    };
};