import React from 'react';

import {LoginForm} from "./login";
import {RegistrationForm} from "./register";
import {Route, Switch} from 'react-router-dom';
import {StateComponent} from "../utils/proto/general";

export class  AuthRouter extends StateComponent {

    renderWithVerification() {

        return <Switch>
            <Route path={'/register'} render={(rp) => <RegistrationForm {...this.props} {...rp} />}/>
            <Route exact path={'*'} render={(rp) => <LoginForm {...this.props} {...rp}  />}/>
        </Switch>

    }


}