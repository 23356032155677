import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {TableWithEditableRows} from "../../../utils/components/collections/table/editable/mixin";


const TableWithCategories = (storeName) => class extends TableWithEditableRows(TableWithStoreList(storeName)){


    get editMeta(){

        return {

            name: {type: 'text'},
            ...super.editMeta,
        }

    };

    renderPreTable(){
        return null;
    }


    renderTableFooter(){
        return null;
    }


    getMeta(){
        return {
            name: {title: 'name', type: 'text'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }

};




export const RiskCategoriesTable = TableWithCategories('risk_category');
export const ControlAreasTable = TableWithCategories('control_area');
export const CompanyActivitiesTable = TableWithCategories('company_activity');
export const ProblemCategoriesTable = TableWithCategories('problem_category');


