import React from 'react';
import {StoreDropdown} from "./index";
import {getCurrentProjectId} from "../../../../../../utils/sessionHelper";



export class ControlDropdown extends StoreDropdown {

    storeName='control';

    renderItemText(item){

        return item.description;
    }



    get storeList(){
        let project_id = getCurrentProjectId(this.props);

        return super.storeList.filter(x=>x.project_id == project_id);
    }
}