import React from 'react';
import {RiskTemplateForm} from "./form";
import {BasicCreateForm} from "../../../../utils/proto/mixins/form/create";
import {getDefaultFromQuery, getQueryParamsMap} from "../../../../../utils/path";
import {FlexRow} from "../../../../utils/components/flexRow";
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
import {NavBackTopLeftButton, NavToButton} from "../../../../utils/components/button/nav";
import {purl} from "../../../../utils/proto/general";



export class CreateTemplateRisk extends RiskTemplateForm(BasicCreateForm('risk')){


    state = {
        ...super.state,
        probability: 1.8,
        level: 1,

    };

    renderComplete(){


        const isOpportunity =getDefaultFromQuery('is_opportunity', 'boolean')


        this.props.history.push(
            this.purl(isOpportunity ? `/templates/opportunities` : `/templates/risks`)
        );



        return null;
    }


    prepareItemForSubmission(item) {

        super.prepareItemForSubmission && super.prepareItemForSubmission(item);

        item.is_opportunity = getDefaultFromQuery('is_opportunity', 'boolean')

    }


    renderWithVerification(){


        return <div>
            <FlexRow justify={'right'}>

                <NavToButton
                    icon={'plus'}
                    content={getLocalString(this.props, 'create_control')}
                    to={purl(this.props, '/templates/controls/create')}
                />

            </FlexRow>

            <div>
                {super.renderWithVerification()}
            </div>
        </div>
    }


}
