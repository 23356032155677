import React from 'react';
import {getLocalString} from "../../../../../../utils/localString";





export const withExternalValue = (sc) => class extends sc {


    renderExternalValue(key, meta, item){
        let {targetField, store, idField=key, placeholder='none'} = meta;

        let id = item[idField];

        let target = this.props.state[store].items[id] || {};

        return target[targetField] || getLocalString(this.props, placeholder);
    }



    getCellContent(p){
        let {meta, item, key} = p;
        switch (meta.type){
            case 'external_value':
                return this.renderExternalValue(key, meta, item);
            default:
                return super.getCellContent(p)
        }
    }
};