import React from 'react';
import {Button} from "semantic-ui-react";
import {withRouter} from "react-router-dom"
import {FlexRow} from "../flexRow";
import {getLocalString} from "../../../../utils/localString";
import {connect} from "react-redux";


export const NavBackButton = withRouter(({history, ...props}) => <Button
    onClick={(e)=>{e.preventDefault();history.goBack()}}
    {...props}/>);


export const NavToButton = withRouter(({history, to, ...props}) => <Button
    onClick={(e)=>{e.preventDefault();history.push(to)}}
    {...props}/>);



export const NavBackTopLeftButton = connect( ({user, locale, locale_entry})=>({state: {user, locale, locale_entry}}))(({leftContent=null, rightContent=null, ...props})=> <FlexRow justify={'space-between'}>
    <div>
        <NavBackButton content={getLocalString(props, 'go_back')} icon={'arrow left'} />
        {leftContent}
    </div>

    <div>
        {rightContent}
    </div>
</FlexRow>);
