import React from 'react';
import {BasicUpdateForm} from "../../utils/proto/mixins/form/update";
import {getCurrentCompanyId} from "../../../utils/sessionHelper";
import {ProjectLevelsRequirementFields} from "./fields/levels";



export class CompanyEditForm extends BasicUpdateForm('company', `/api/company/update`, (props)=>getCurrentCompanyId(props)) {

    getFormMeta(){
        return [
            {name: 'name', title: 'name', type: 'text'},
            {name: 'address', title: 'address', type: 'text'},

            {name: 'project_level_requirements', type: 'project_level_requirements'}

        ];
    }



    renderCustomField(meta){

        const props = this.getFieldProps(meta);


        switch (meta.type){

            case 'project_level_requirements':
                return <ProjectLevelsRequirementFields {...this.props} {...props} />;

            default:
                return super.renderCustomField(meta);
        }

    }

}
