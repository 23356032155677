import React from 'react';
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {Button, Table, Label} from 'semantic-ui-react'
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {getLocalString} from "../../../../utils/localString";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";






export const RelationTable = (relationStoreName, primary, secondary) => class extends WithExtras(TableWithStoreList(relationStoreName), [secondary]) {



    get relationMeta(){
        return {
            relation: {type: 'relation', title: 'relation'}
        };
    }

    get actionsMeta(){
        return {
            actions: {type: 'actions', title: 'actions', navigates: false, collapsing: true}

        }
    }


    get primaryItem(){
        return this.props.match.params.id;
    }

    getSecondaryItem(relation){

        let secondary_id = relation[`${secondary}_id`];

        return this.props.state[secondary].items[secondary_id];
    }

    getData(){
        let data = super.getData();

        return data.filter(relation => {
            return Number(relation[`${primary}_id`]) === Number(this.primary_id);
        })
    }



    getMeta(){
        return {
            ...this.relationMeta,
            ...this.actionsMeta,
        }
    }

    renderRelation({item: relation}){

        let target = this.getSecondaryItem(relation);


        return <div><Label color={'teal'} size={'tiny'}>{target.acronym}</Label><br/><br/>{target.description}</div>;
    }


    renderRemoveButton(args){

        let {item} = args;
        return <Button
            color={'red'}
            icon={'remove'}
            content={getLocalString(this.props, 'remove')}
            onClick={(e)=>{

                e.preventDefault();
                if (window.confirm(getLocalString(this.props, 'delete_relation_?'))){
                    this.removeItem(item, item.storeIndex);
                }
            }}

        />
    }

    renderActions({item, index}){


        return this.renderRemoveButton({index, item});
    }

    getCellContent(args){
        let {meta} = args;

        switch (meta.type){
            case 'relation':
                return this.renderRelation(args);
            case 'actions':
                return this.renderActions(args);
            default:
                return super.getCellContent(args);
        }
    }



    get primary_id(){
        return this.props.match.params.id;
    }

    renderTableFooter(){
        return null;
    }

};

