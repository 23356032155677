import React from 'react';


import {WithList} from "../../utils/proto/mixins/withList";
import {GenericComponent} from "../../utils/proto/general";
import {getCurrentCompany, getCurrentCompanyId, getCurrentProject} from "../../../utils/sessionHelper";
import {Dropdown, Menu, Button} from 'semantic-ui-react'
import {getLocalString} from "../../../utils/localString";
import {WithProjects} from "../../company/mixins/withProjects";

export class ProjectsDropdown extends WithProjects(WithList(GenericComponent, '/api/user_project_relation/list')){

    renderOptions(projects){

        if (!projects.length) return <span>{getLocalString(this.props, 'no_projects_in_dropdown')}</span>;


        return projects.map(c=><Dropdown.Item
            key={c.id}
            onClick={()=>{this.props.history.push(`/companies/${c.company_id}/projects/${c.id}`)}}>{c.name}</Dropdown.Item>)
    }


    renderWithVerification(){

        let {projects} = this;




        let currentProject= getCurrentProject(this.props);
        let currentCompanyId = getCurrentCompanyId(this.props);

        // Don't render dropdown if we are not in the company
        if (!currentCompanyId) return null;


        let text = getLocalString(this.props, 'select_project');
        if ((currentProject && currentProject.id)) text = currentProject.name;

        return <Menu.Item><Dropdown text={text} button color={'blue'} fluid>
            <Dropdown.Menu>
                <Dropdown.Header content={getLocalString(this.props, 'projects')} />
                {this.renderOptions(projects)}
            </Dropdown.Menu>
        </Dropdown></Menu.Item>


    }


}