import React from 'react';
import {AuditForm} from "./form";
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";
import {getFromStoreById} from "../../../../utils/getFromStore";

export class AuditUpdateForm extends AuditForm(BasicUpdateForm('audit')){



    renderWithVerification(){

        const control = getFromStoreById(this.props, 'control', this.state && this.state.control_id) || {};

        return <div>

            <NavBackTopLeftButton />
            <h2>{control.description}</h2>
            {super.renderWithVerification()}
        </div>
    }
}
