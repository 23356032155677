import {GenericComponent} from "../../../../utils/proto/general";
import {WithRouterLayout} from "../../../../utils/proto/layouts/router";
import {SetupConfigTabView} from "./tab_view";


export default class TemplateSetupView extends WithRouterLayout(GenericComponent) {




    prefix = this.purl('/templates/setup_config');

    routesMeta = {
        '/:config_type': {Component: SetupConfigTabView},
        '*': {Component: SetupConfigTabView},
    }





}