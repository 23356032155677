import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {AppComponent} from "../../../utils/proto/general";

import {Button, Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../utils/localString";
import {RiskReportView} from "./risk";
import {ControlsReportList} from "./control/list";
import {ImprovementsReportList} from "./improvements/list";
import {ProblemReportList, ProblemReportView} from "./problem/list";
import {OpportunitiesReportView} from "./opportunities";



export default class ReportView extends WithRouterLayout(AppComponent){

    prefix = this.purl('/reports');

    routesMeta = {


            '/problems': {Component: ProblemReportView},
            '/risks': {Component: RiskReportView},
            '/opportunities': {Component: OpportunitiesReportView},
            '/controls': {Component: ControlsReportList},
            '/improvements': {Component: ImprovementsReportList}

    };


    renderWithVerification(){

        return <Segment basic padded>

            <Segment basic padded className={'hide-on-print'} >
                <Button
                basic
                color={'red'}
                content={getLocalString(this.props, 'go_back')}
                icon={'arrow left'}

                onClick={()=>{
                    this.props.history.goBack();
                }}
                />

                <Button
                    color={'green'}
                    icon={'print'}
                    content={getLocalString(this.props, 'print')}
                    onClick={()=>{
                        window.print();
                    }}

                />
            </Segment>

            <Segment basic padded>
                <div className={'move-up-on-print'}>
                    {super.renderWithVerification()}
                </div>

            </Segment>

        </Segment>


    }

}