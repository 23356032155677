import  React from 'react';
import {WithStoreList} from "../utils/proto/mixins/withList";
import {GenericComponent} from "../utils/proto/general";
import {getCompaniesForUserFromRelations} from "../../mvvm/reducers/utils/company";
import {Icon} from "semantic-ui-react";





const CompanyListItem = (props) => {

    const {company} = props;
    const {id, name} = company;

    return <div
        className={'rh-sb-item'}
        onClick={()=>{props.history.push(`/companies/${id}`)}}
        >

        <div className={'icon'}>
            <Icon name={'building'} />
        </div>
        <div className={'content small'}>  {name}</div>

    </div>

};

export class SidebarSecondaryCompanyList extends WithStoreList(GenericComponent, 'user_company_relation') {


    get companies()
    {
        let relations = this.state.payload || [];

        return getCompaniesForUserFromRelations(this.props, relations);
    }





    renderWithVerification(){

        return <div className="rh-sb-list">

            {(this.companies || []).map(company=>
                <CompanyListItem {...this.props} company={company}/>
            )}

        </div>
    }

}