import React from 'react';

import {getLocalString} from "../../../../../utils/localString";

import {Button} from 'semantic-ui-react';
import {ControlListProto} from "../../control/list";
import {purl} from "../../../../utils/proto/general";
export class TemplateControlListProro extends ControlListProto {


    getMeta(){

        return {
            level: {type: 'number', collapsing: true},
            ...super.getMeta()
        }
    }

    storeName = 'control';
    showPresent = null;

    getLinkToItem = (item)=> this.purl(`/templates/controls/${item.id}`);

    getLinkToCreate(){
        return this.purl(`/templates/controls/create`);
    }

    renderConnectedControls(item){
        let {risk_control_relation} = this.props.state;

        let relations = risk_control_relation.itemsByControlId[item.id];
        let relationsCount = Object.entries(relations || {}).length;

        return this.renderRelationsCount(relationsCount);
    }

    renderRelationsCount(count){

        let props = {
            basic: true,
            color: 'red',
            content: getLocalString(this.props, `no_relations`)
        };

        if (count) {
            props.color = 'blue';
            props.content = getLocalString(this.props, `edit_relations`);
        }


        return <Button {...props} label={count || `0`} />

    }

    getCellContent(p){
        let {item, key} = p;
        switch (key){
            case 'connected_risks':
                return this.renderConnectedControls(item);
            default:
                return super.getCellContent(p)
        }
    }


}


export const TemplateControlList =(props) => {



    return <div>

        <h1>{getLocalString(props, 'template_controls')}</h1>


        <Button
            color={'green'}
            icon={'plus'}
            content={getLocalString(props, 'create_control')}
            onClick={()=>{props.history.push(purl(props, '/templates/controls/create'))}}
        />

        <TemplateControlListProro {...props} />


    </div>
}