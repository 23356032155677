import React from 'react';
import {getLocalString} from "../../../../utils/localString";


import {Button, Segment} from 'semantic-ui-react';
export const PresenceSelector = (props) => {

    let {onChange, value: currentValue, name} = props;


    const setValue = (value) => {
        if (onChange){
            onChange(null, {name, value});
        }

        console.log('onChange is not passed to presence selector');
    };

    return <Segment basic>
        <Button.Group size={'large'}>

            <Button
                content={getLocalString(props, 'present')}
                onClick={(e)=>{e.preventDefault();setValue(true)}}
                color={currentValue ? 'green' : undefined}

            />
            <Button.Or text={getLocalString(props, 'or')}/>
            <Button
                content={getLocalString(props, 'not_present')}
                onClick={(e)=>{e.preventDefault();setValue(false)}}
                color={currentValue ? undefined : 'red'}
            />

        </Button.Group>
    </Segment>

};


