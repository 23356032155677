import React from 'react';
import {AppComponent} from "../../utils/proto/general";

import {Grid} from 'semantic-ui-react';
import {CompanyEditForm} from "./update";
import {CompanyAccessList} from "./advisors";
import {getLocalString} from "../../../utils/localString";
import {SubscriptionSettings} from "./subscription/index";
import {getCurrentProject} from "../../../utils/sessionHelper";

export class CompanySettingsView extends AppComponent {

    renderWithVerification(){



        return <Grid>
            <Grid.Column mobile={16} tablet={8} computer={4}>
                <CompanyEditForm {...this.props} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
                <h1>{getLocalString(this.props, 'company_owners')}</h1>
                <CompanyAccessList {...this.props} userType={2} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={8} computer={4}>
                <h1>{getLocalString(this.props, 'company_advisors')}</h1>
                <CompanyAccessList {...this.props} userType={3} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={4}>
            <h1>{getLocalString(this.props, 'plan')}</h1>
            <SubscriptionSettings {...this.props} />
            </Grid.Column>





        </Grid>
    }



}