import React from 'react';
import  {TemplateControlForm} from "./form";
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";



export class ControlTemplateUpdateForm extends TemplateControlForm(BasicUpdateForm('control')){

}


