import {WithApi} from "../api";
import {WithState} from "../redux";
import {GenericFormPrototype} from "../../../components/form/prototype";
import {WithSubmit} from "./submit";



export const getIdFromRoute = (props) => {
    return props.match.params.id;
};


export const WithStoreItem = (sc, storeName, idFunc=getIdFromRoute) => class extends sc {


    get updateUrl(){
        return this.submitUrl;
    }

    get id(){
        return idFunc(this.props);
    }


    static getDerivedStateFromProps(newProps, prevState){

        let id = idFunc(newProps);

        // Get the item
        let item = newProps.state[storeName].items[id];


        if (!item) return prevState;
        if (!prevState) return {...item};
        // Now find this item in the store


        if (prevState.id === item.id) return prevState;

        return {...(prevState || {}), ...item}

    }

    getSubmitButtonProps(){
        return {
            ...super.getSubmitButtonProps(),
            color: 'green'
        }
    }

};





export const BasicUpdateForm = (storeName, updateUrl=`/api/${storeName}/update`, idFunc=getIdFromRoute, protoClass=GenericFormPrototype) => WithSubmit(WithStoreItem(WithApi(WithState(protoClass)), storeName, idFunc), updateUrl);