import {Store} from "../store";

export const action_set_subscriptions = 'action_add_subscriptions';
export const action_drop_subscriptions = 'action_drop_subscriptions';


export class SubscriptionsStore extends Store {



    get defaultState(){
        return {
            subscriptions: [],
        }
    }


    onAction(state, action){


        switch (action.type){

            case action_set_subscriptions:
                return {...state, subscriptions: action.data};
            case action_drop_subscriptions:
                return this.defaultState;

            default:
                return state;
        }
    }

}