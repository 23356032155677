import React from 'react';
import {GenericComponent} from "../../../utils/proto/general";
import {ActiveSubscriptionItem} from "./activeItem";
import {SubscriptionSelector} from "./subscriptionOffer";



const SubscriptionItem = (props) => {

    let {subscription} = props;


    let {state} = subscription;


    if (state === 'active'){
        return <ActiveSubscriptionItem  {...props} />
    } else {

        return <SubscriptionSelector {...props} />
    }


};


export class SubscriptionSettings extends GenericComponent {



    get subscriptions() {
        return this.props.state.subscriptions.subscriptions;
    }


    renderWithVerification(){

        return this.subscriptions.map((sub, pos)=><SubscriptionItem key={pos} {...this.props} subscription={sub} />)
    }

}