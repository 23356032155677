import React from 'react';
import {AppComponent} from "../../../../utils/proto/general";

import {Segment, Input} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {levelsOfImpact} from "../../utils/loi";


export class LevelsOfImpactField extends AppComponent{


    renderDescription(){
        return <span>{getLocalString(this.props, 'levels_of_impact_description')}</span>
    }



    renderField(opts, pos){
        let loi = (this.props && this.props.value) || levelsOfImpact.map(()=>0);

        let {name, color} = opts;
        return <Segment basic key={name} textAlign={'left'}>
                <Input
                  fluid
                  label={{tag: true, color, content: getLocalString(this.props, name)}}
                  labelPosition={'right'}
                  value={loi[pos]}
                  onChange={(e, {value})=>{
                        loi[pos] = value;
                        this.props.onChange(e, {value: loi});
                  }}
                />
            </Segment>

    }

    renderFields(){

        return levelsOfImpact.map((o, pos) =>this.renderField(o, pos))

    }

    renderForm(){

        return <Segment>
            <h1>{getLocalString(this.props, 'levels_of_impact')}</h1>
            <br/>
            {this.renderDescription()}
            <br/>
            {this.renderFields()}

        </Segment>
    }

    renderWithVerification(){
        return this.renderForm();
    }
}