import React from 'react';
import {WithRouterLayout} from "../../utils/proto/layouts/router";
import {GenericComponent} from "../../utils/proto/general";
import {AdminUserTypeCreateForm} from "./create";
import {AdminUserTypeList} from "./list";
import {AdminUserTypeEditForm} from "./edit";


export default class AdminUserTypeView extends WithRouterLayout(GenericComponent){



    prefix = '/admin/user_types';
    routesMeta = {
        '/create': {Component: AdminUserTypeCreateForm},
        '/:id' : {Component: AdminUserTypeEditForm},
        '*': {Component: AdminUserTypeList}
    }

}