import React from 'react';
import {SortableTablePrototype} from "./basic";
import {Button, Table, Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {WithStoreList} from "../../../proto/mixins/withList";
import {WithApi} from "../../../proto/mixins/api";
import {withExternalValue} from "./modifiers/withExternalValue";


export const TableWithStore = (sc) => class extends withExternalValue(sc) {





    getCellContent(args){

        let {meta} = args;
        let {type} = meta;
        switch (type){
            case 'actions':
                return this.renderActionButtons(args);

            default:
                return super.getCellContent(args);
        }
    }

    getData(){
        return [...this.payload || []];
    }

    getNoDataMessage(){
        let key = `items`;
        return `${getLocalString(this.props, 'there_are_no')} ${getLocalString(this.props, key).toLowerCase()}`;
    }

    getCreateButtonProps(){
        return {
            color: 'green',
            content: getLocalString(this.props, 'add_new_item'),
            icon: 'plus',
            onClick: ()=>{
                this.props.history.push(this.getLinkToCreate());
            }}

    }




    renderCreateButton(){
        return <Button
            {...this.getCreateButtonProps()}
        />
    }

    renderWithoutData(){
        return <Segment basic padded textAlign={'center'}>
            <h1>{getLocalString(this.props, 'no_data')}</h1>
            <br/>
            <br/>
            {this.renderCreateButton()}
        </Segment>
    }

    renderTableFooter(){
        let columnCount = Object.entries(this.getMeta()).length;

        return <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={columnCount}>
                    {this.renderCreateButton()}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>

    }

};




export const TableWithStoreList = (storeName, multipleName=`${storeName}s`, url=`/api/${storeName}/list`) => class extends WithStoreList(WithApi(TableWithStore(SortableTablePrototype)), storeName, url){



    renderPreTable(){
        return <h1>{getLocalString(this.props, multipleName)}</h1>;
    }

    renderActionButtons({item}){
        let loading = this.isRemovingItem(item);
        return <Button
            color={'red'}
            size={'tiny'}
            content={getLocalString(this.props, 'delete')}
            loading={loading}
            onClick={()=>{

                if (window.confirm(getLocalString(this.props, 'confirm_to_delete'))){
                    this.removeItem(item, item.storeIndex);
                }

            }}
        />

    }

    getData(){
        const store = this.props.state[storeName];

        const {list, items} = store;

        return (list || []).map(x=>{

            if (items[x.id]) return items[x.id];
            else return null;
        }).filter(x=>!!x);

    }


    getLinkToItem(item){
        return this.purl(`/${multipleName}/${item.id}`);
    }

    getLinkToCreate(){
        return this.purl(`/${multipleName}/create`, );
    }


};
