import React from 'react';
import {SidebarPrimaryMenu as Menu} from "../../../../utils/proto/layouts/sidebar/primary";
import {SidebarPrimaryBasicItems} from "../../../sidebar/main/basic";
import {TasksPrimaryMenuItem, UserSettingsMenuItem} from "../../../sidebar/main/user";
import {getLocalString} from "../../../../../utils/localString";
import {purl} from "../../../../utils/proto/general";
import {TemplateStateToggle} from "./templateState";
import {getCurrentProject} from "../../../../../utils/sessionHelper";




const buildRoute = (props, key) => {
        return purl(props, `/${key}`);
};

const buildTemplateRoute = (props, key) => {
        return purl(props, `/templates/${key}`)
};

const to = (props, to) => () => {
        props.history.push(to);
};



const isOnTemplateRoute = (key) => {
        const [,,,,,,route] = window.location.pathname.split('/');
        return route===key;
};

const isOnRoute = (key) => {
const [,,,,,route] = window.location.pathname.split('/');
 return route===key;
};


const routableMenuItems = [
        {key: `problems`,icon:`trophy`, hint: `goals`},
        {key: `risks`,icon:`bomb`, hint: `risks`},
        // {key: `opportunities`,icon:`paper plane outline`, hint: `opportunities`},
        {key: `controls`,icon:`handshake outline icon`, hint: `controls`},
        //{key: `improvements`,icon:`thumbs up`, hint: `improvements`},
        {key: `tasks`,icon:`check square outline icon`, hint: `tasks`},
];


const routableTemplateMenuItems = [
        {key: `problems`,icon:`trophy`, hint: `goals`},
        {key: `risks`,icon:`bomb`, hint: `risks`, route: buildTemplateRoute, isOnRoute: isOnTemplateRoute},
        // {key: `opportunities`,icon:`paper plane outline`, hint: `opportunities`, route: buildTemplateRoute, isOnRoute: isOnTemplateRoute},
        {key: `controls`,icon:`handshake outline icon`, hint: `controls`, route: buildTemplateRoute, isOnRoute: isOnTemplateRoute},
        //{key: `improvements`,icon:`thumbs up`, hint: `improvements`, route: buildTemplateRoute, isOnRoute: isOnTemplateRoute},
        {key: `setup_config`,icon:`wrench`, hint: `setup_configuration`, route: buildTemplateRoute, isOnRoute: isOnTemplateRoute},

];

const ProjectMenuItems = (props) => {

        const project = getCurrentProject(props);

        const items = project && project.is_template ? routableTemplateMenuItems : routableMenuItems;



        return items.map(({key, icon, hint, route=buildRoute, isOnRoute: onRoute=isOnRoute}, index)=>{
            if (typeof route === 'function') route = route(props, key);
            return <Menu.Item
                key={index}
                className={onRoute(key) ? 'active' : ''}
                onClick={to(props, route)}
                icon={icon}
                hint={getLocalString(props,hint)}
            />})
};





export const ProjectSidebarPrimaryMenu = (props) => {


        return <Menu {...props}>

                <Menu.Menu>
                        <SidebarPrimaryBasicItems {...props}/>
                        <ProjectMenuItems {...props} />
                        <TemplateStateToggle {...props} />

                </Menu.Menu>

                <Menu.Menu>


                        <TasksPrimaryMenuItem  {...props} />
                        <Menu.Item
                            icon={'setting'}
                            hint={getLocalString(props, 'project_settings')}
                            onClick={()=>{props.history.push(purl(props, '/settings'))}}
                        />
                        <UserSettingsMenuItem {...props} />
                </Menu.Menu>

        </Menu>;

};
