function getCurrent(props, storeName) {

    if (!props) throw `No props`;
    if (!props.state) throw `No state in props`;

    let {session} = props.state;
    let store = props.state[storeName];

    if (!session) return {};
    if (!store) throw  `No ${storeName} store in getCurrentUser`;

    let item = session[storeName] || {};

    if (!item) return {}; //throw `Session has no item in getCurrent(..., ${storeName}, ${sessionKey})`;

    return store.items ? store.items[item.id] : null;
}


/**
 * Gets a user object pointed from a current session
 * @param props
 */
export function getCurrentUser(props) {

    return getCurrent(props, 'user');
}



export function getCurrentCompany(props) {

    if (props.currentCompany) return props.currentCompany.company;

    const id = getCurrentCompanyId(props);
    if (!id) return undefined;


    const store = props.state.company;

    return store.items[id];
}


export function getCurrentProject(props) {

    if (props.currentProject) return props.currentProject.project;

    const id = getCurrentProjectId(props);
    if (!id) return undefined;


    const store = props.state.project;

    return store.items[id];
}

export function getCurrentCompanyId(props){
    let routeId =  (props.currentCompany || {}).company_id;
    if (routeId) return routeId;

    // If we didn't find in in route params, try to find in route parts
    // 2 and 4
    let [,routeIndex,company_id] = window.location.pathname.split('/');

    if (routeIndex === 'companies' && company_id) return company_id;
    return null;
}

export function getCurrentProjectId(props) {
    let routeId =  (props.currentProject || {}).project_id;
    if (routeId) return routeId;

    // If we didn't find in in route params, try to find in route parts
    // 2 and 4
    let [,,,routeIndex,project_id] = window.location.pathname.split('/');

    if (routeIndex === 'projects' && project_id) return project_id;
    return null;
}



export function isCompanyOwner(props){

        let user = getCurrentUser(props);
        let company_id = getCurrentCompanyId(props);


        let {user_company_relation} = props.state;


        let ownership = Object.values(user_company_relation.items).filter(rel => {


            return rel.user_id == user.id &&
                   rel.company_id == company_id &&
                   rel.access_level === 2;
        });


        return !!ownership.length;
}