import React from 'react';
import {Form, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";
import {WithTextFilter} from "./text";
import {WithStoreDropdownFilter} from "./storeDropdown";
import {ExpandableV2} from "../../../expandable";

export const WithFilterProto = (sc) => class extends sc {


    state = {
        ...super.state || {},
        filterBy: {
        }
    };


    get filterMeta() {
        return {
            test: {}
        }
    }


    filterItemByMeta(args) {

        const filterValue = this.getFilterFieldValue(args);
        const {item, key} = args;


        return filterValue === item[key];
    }

    getFilteredData(data){

        const {filterMeta} = this;
        const metaEntries = Object.entries(filterMeta);

        return (data||[]).filter(item=>{

            for (const [key, meta] of metaEntries){
                if (!this.filterItemByMeta({item, key, meta})) return false;
            }

            return true;
        });

    }


    onFiltersSubmitted(e){

        if (e && e.preventDefault) e.preventDefault();
        // If we have a submit-based filter, use it here
    }


    onBasicFieldChangeHandler(name){

        return (e, {value})=>{

            const {filterBy} = this.state;
            filterBy[name] = value;

            this.setState({filterBy, filtered: true});
        }
    }


    getFilterFieldValue(args){
        const {filterBy} = this.state;
        const value = filterBy[args.key] || '';

        return value;
    }

    getFilterFieldProps(args){

        const {meta, key} = args;
        const {title=key} = meta;
        const value = this.getFilterFieldValue(args);
        const localTitle = getLocalString(this.props, title);



        return {
            key,
            value,
            label: localTitle,
            onChange: this.onBasicFieldChangeHandler(key)
        }
    }

    renderFilterField(args){

        const props = this.getFilterFieldProps(args);

        return <Form.Input {...props} />;

    }

    renderFilterFields(){
        const {filterMeta} = this;
        return Object.entries(filterMeta).map(([key, meta], position)=>this.renderFilterField({key, meta, position}));
    }

    renderFormContent(){
        return  <Segment style={{margin: '20px 0'}}>
            <h3>{getLocalString(this.props, 'filters')}</h3>
            {this.renderFilterFields()}
        </Segment>
    }

    renderFilters(){
        return <ExpandableV2 titleRow={getLocalString(this.props, 'filters')}><Form onSubmit={this.onFiltersSubmitted.bind(this)} className={'hide-on-print'}>
            {this.renderFormContent()}

        </Form></ExpandableV2>
    }

    renderWithVerification(){
        return <div>

                {this.renderFilters()}


            {super.renderWithVerification()}
        </div>
    }

};



export const WithFilter = (sc) => WithStoreDropdownFilter(WithTextFilter(WithFilterProto(sc)));