import React from 'react';
import {GenericComponent} from "../proto/general";
import {getCurrentProject, getCurrentUser} from "../../../utils/sessionHelper";
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../utils/localString";



const chat_appid = '53309';
window.chat_appid = chat_appid;
window.chat_auth = 'ba63934c5ef8f417ea4c9c976aca46e7';



export class CometChatBase extends GenericComponent {

    state = {
        ...super.state,
        chatEnabled: false,

        cssRef: null,
        jsRef: null
    };




    loadCSS(){
        const chat_css = document.createElement('link');
        chat_css.rel = 'stylesheet';
        chat_css.type = 'text/css';
        chat_css.href = 'https://fast.cometondemand.net/'+chat_appid+'x_xchat.css';

        document.getElementsByTagName("head")[0].appendChild(chat_css);
        this.setState({cssRef: chat_css})
    }


    componentDidMount(){
        this.loadCSS();
    }




    shouldLaunchChat(){
        const {props} = this;

        const project = getCurrentProject(props);
        const user = getCurrentUser(props);

        return project && user && project.cometchat_id && user.cometchat_id;
    }


    saveChatUserGroupInfo(){

        const {props} = this;

        const project = getCurrentProject(props);
        const user = getCurrentUser(props);


        window.chat_id = user.cometchat_id;
        window.chat_guid = project.cometchat_id;
        window.chat_position = 'right';



    }



    renderActivateForUserButton(){
        //const user = getCurrentUser(props);
        return <div  className="cometchat-floating-container">

            <Button
                content={getLocalString(this.props, 'authorize_cometchat')}
                icon={'user'}
                color={'teal'}
                size={'tiny'}
                onClick={() => {
                    this.api('/api/user/enableCometchat');
                }
                }
            />

        </div>

    }


    renderActivateForProjectButton(){
        const project = getCurrentProject(this.props);
        return <div className="cometchat-floating-container">

            <Button
                content={getLocalString(this.props, 'create_cometchat_group')}
                color={'teal'}
                size={'tiny'}
                icon={'users'}
                onClick={() => {
                    this.api('/api/project/enableCometchat', project);
                }
                }
            />

        </div>
    }





}

export class FloatingCometChat extends CometChatBase {


    launchFloatingCometchat(){

        (async function() {

            const chat_js = document.createElement('script');
            chat_js.type = 'text/javascript';
            chat_js.src = 'https://fast.cometondemand.net/'+chat_appid+'x_xchat.js';
            const chat_script = document.getElementsByTagName('script')[0];
            chat_script.parentNode.insertBefore(chat_js, chat_script);

        })();

        this.setState({
            chatEnabled: true
        })

    }


    componentDidMount() {
        super.componentDidMount();

        if (this.shouldLaunchChat()){

            this.saveChatUserGroupInfo();
            this.launchFloatingCometchat();
        }

    }


    componentDidUpdate(){

        if (this.shouldLaunchChat() &&  !this.state.chatEnabled){
            this.saveChatUserGroupInfo();
            this.launchFloatingCometchat();
        }

    }

    renderActivateChatButton(){

        const project = getCurrentProject(this.props);


        return <div className={'cometchat-floating-container'}>
            <div style={{width: 200, height: 200, backgroundColor: 'red'}}  />
            <a onClick={()=>{

                const {jqcc} = window;

                if (!jqcc){
                    console.error(`No JQCC`);
                    return;
                }

                if (!jqcc.cometchat){
                    console.error(`No cometchat in JQCC`);
                    return;
                }

                console.log(`Launching group chat with GUID: ${project.cometchat_id}`);
                jqcc.cometchat.launch({
                    guid: project.cometchat_id
                })


            }}>
                {getLocalString(this.props, 'chat_in_group')}</a>
        </div>
    }


    renderWithVerification(){

        //if (this.shouldLaunchChat()) return null;

        const {props} = this;

        const project = getCurrentProject(props);
        const user = getCurrentUser(props);



        if (!user.cometchat_id) return this.renderActivateForUserButton();
        if (!project.cometchat_id) return this.renderActivateForProjectButton();



        return this.renderActivateChatButton();
    }


}

