import {SidebarPrimaryMenu as Menu} from "../utils/proto/layouts/sidebar/primary";
import {SidebarPrimaryBasicItems} from "../company/sidebar/main/basic";
import {TasksPrimaryMenuItem, UserSettingsMenuItem} from "../company/sidebar/main/user";
import React from "react";

export const DefaultPrimarySidebar = (props) => {


    return <Menu {...props}>

        <Menu.Menu>
            <SidebarPrimaryBasicItems {...props}/>
        </Menu.Menu>

        <Menu.Menu>
            <TasksPrimaryMenuItem {...props} />
            <UserSettingsMenuItem {...props} />
        </Menu.Menu>

    </Menu>;

};