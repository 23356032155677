import React from "react";
import {Icon, Popup} from "semantic-ui-react";
class Item extends React.Component {

    render () {

        const {icon: iconName, content, children, onClick, square, className, hint} = this.props;


        const icon = iconName && <Icon name={iconName}/>;

        const item = <div
            style={{
                display: 'flex',
                justifyContent: 'center',
            }}
            onClick={onClick}
        >
            <div className={`rh-sb-submenu-item ${square ? 'square' : ''} ${className || ''}`}>
                {icon || content || children}
            </div>
        </div>;


        return hint ? <Popup size={'tiny'} position={'right center'} trigger={item} content={hint} /> : item;
    }


}



export class SidebarPrimaryMenu extends React.Component {



    static Menu =  class  extends React.Component {


        render (){
            return <div className="rh-sb-submenu">
                {this.props.children}
            </div>
        }
    };



    static Item = Item;



    render(){


        return <div className={'rh-sb-primary'}>
            {this.props.children}
        </div>
    }

}