import React from 'react';
import {WithActivitiesMatrix} from "./prototype";
import {MatrixPrototype} from "../../../../../utils/components/collections/matrix/basic";
import {GenericComponent} from "../../../../../utils/proto/general";
import {Segment} from 'semantic-ui-react';
import {WithExtras} from "../../../../../utils/proto/mixins/withExtras";
import {WithApi} from "../../../../../utils/proto/mixins/api";

class ControlAreaActivityMatrix extends WithActivitiesMatrix(WithApi(MatrixPrototype)){

    horizontalStore = 'control_area';
    relationStore = 'control_activity_setup_config';


}



export class ControlAreaActivityMatrixView extends WithExtras(GenericComponent, ['company_activity', 'control_area', 'control_activity_setup_config']) {


    renderWithVerification(){

        return <Segment>
            <ControlAreaActivityMatrix {...this.props}  />
        </Segment>

    }
}