import React, {Component} from 'react';
import {Segment, Icon, List} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";


export const riskProbabilityOptions = {


    3.0: {title: 'highest_probability', color: 'red'},
    2.4: {title: 'high_probability', color: 'orange'},
    1.8: {title: 'medium_probability', color: 'yellow'},
    1.2: {title: 'low_probability', color: 'green'},
    0.6: {title: 'lowest_probability', color: 'teal'},
};


export const opportunityProbabilityOptions = {
    3.0: {title: 'highest_probability', color: 'teal'},
    2.4: {title: 'high_probability', color: 'green'},
    1.8: {title: 'medium_probability', color: 'yellow'},
    1.2: {title: 'low_probability', color: 'orange'},
    0.6: {title: 'lowest_probability', color: 'red'},
};

export function getIconForRiskProbability(probability, is_opportunity=false){
    let options = is_opportunity ? opportunityProbabilityOptions[probability] : riskProbabilityOptions[probability];

    let {icon='arrow up', color='red'} = options || {};

    return <Icon name={icon} color={color} />;
}

export function getTitleForRiskProbability(probability, is_opportunity=false){
    let options =  is_opportunity ? opportunityProbabilityOptions[probability] : riskProbabilityOptions[probability];

    let {title='none'} = options || {};

    return title;
}

export class RiskProbabilityField extends Component{



    renderItem(level, options, position){
        let {value, onChange, name='probability', is_opportunity=false} = this.props;

        let {title='probability'} = options;

        let active = (Number(value) === Number(level)) || undefined;
        return <List.Item
            key={position}
            active={active}
            onClick={()=>{
                onChange(null, {name: name, value: Number(level)})
            }}
        >
            {getIconForRiskProbability(level, is_opportunity)}
            <List.Content>
                {getLocalString(this.props, title)}
            </List.Content>
        </List.Item>
    }

    renderItems(){
        return Object.entries((this.props.is_opportunity ? opportunityProbabilityOptions : riskProbabilityOptions) ).map(([s,o], p)=>this.renderItem(s,o,p));
    }

    render(){
        return <Segment basic>
            <List selection>
                {this.renderItems()}
            </List>
        </Segment>
    }
}