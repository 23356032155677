import React from 'react';
import {getLocalString} from "../../utils/localString";
import {Link} from 'react-router-dom';
import {Button, Card, Image, Segment} from 'semantic-ui-react';
import {SubmitForm} from "../utils/proto/mixins/form/submit";

import {action_update_session} from "../../mvvm/reducers/custom/session";
import {getCurrentCompanyId} from "../../utils/sessionHelper";


export class LoginForm extends SubmitForm(`/api/session/create`) {

    title = () => getLocalString(this.props, 'authorization');

    getFormMeta() {
        return [
            {name: 'email', title: 'email', type: 'email'},
            {name: 'password', title: 'password', type: 'password'}
        ]
    }

    renderForm() {

        let form = super.renderForm();

        return <Segment basic padded>

            <Image centered={true} size={'large'} src={'/api/logo.png'}/>
            <br/>
            <br/>
            <br/>
            <Card centered>
                <Card.Content>{form}</Card.Content>
            </Card>
        </Segment>;
    }

    renderFormFooter() {
        return <Button
            color={'green'}
            content={getLocalString(this.props, 'login')}
            icon={'user'}
            fluid
            loading={this._isSubmitting}
            disabled={!this._isEnabled}
        />
    }


    onSuccessResponse = (p) => {


        let {payload} = p;


        this.props.dispatch({type: action_update_session, data: payload});


        let {company_id} = payload;
        let currentCompanyId = getCurrentCompanyId(this.props);

        if (!currentCompanyId && company_id){
            this.props.history.push(`/companies/${company_id}`);
        }

        return null;
    };

    getSubmitButtonProps() {
        return {
            ...super.getSubmitButtonProps(),
            content: getLocalString(this.props, 'login'),
            icon: 'log in',
        }
    }


    renderPostForm() {
        return <div>
            <br/>
            <Link to={'/register'}><Button fluid basic color={'blue'}>{getLocalString(this.props, 'register')}</Button></Link><br/>
            <Button
                onClick={()=>{ window.Intercom('show');}}
                fluid size={'tiny'} basic color={'grey'}>{getLocalString(this.props, 'forgot_password')}</Button>

        </div>;
    }

}
