import {GlobalStore} from "../global";

export class LocaleReducer extends GlobalStore {




    get mapsMeta() {

        return {
            ...super.mapsMeta,
            entriesByKey: {path: ['key', 'locale_id']},
            entriesByLocale: {path: ['locale_id', 'key']},
        };

    };

}