import React from 'react';
import {AppComponent} from "../../../../utils/proto/general";

import {Button, Dropdown, List, Icon, Input} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";


import {riskProbabilityOptions} from "../../risk/utils/probability";

//
// export const riskProbabilityOptions = {
//     3.0: {title: 'high_probability', color: 'red'},
//     2.0: {title: 'medium_probability', color: 'orange'},
//     1.0: {title: 'low_probability', color: 'green'},
//     0.0: {title: 'no_probability', color: 'black', icon: 'arrow down'},
//
//
// };


export class RiskTemplateAnswersForm extends AppComponent {




    setValue(value){
        let extra = this.props.value || {};
        let answers = value;
        extra.answers = answers;
        this.props.onChange(null, {name: this.props.name, value: extra});
    }


    onAddQuestionButtonClick(value){
        let newValue = [...value, {question: '', probability: Object.keys(riskProbabilityOptions)[0]}];
        this.setValue(newValue);
    };


    renderQuestion(item, position, value){


        let options = Object.entries(riskProbabilityOptions).map(([prob, meta])=>{
            return {
                value: prob,
                text: getLocalString(this.props, meta.title),
                icon: <Icon color={meta.color} name={meta.icon || `arrow up`} />
            }
        });



        return <List.Item key={position}>

            <Input type={'text'} value={item.question} onChange={(e, {value: strValue})=>{

                item.question = strValue;
                this.setValue(value);

            }
            }/>
            &nbsp;
            &nbsp;
            <Dropdown options={options} value={item.probability} onChange={(e, {value: ddValue})=>{
                item.probability = ddValue;
                this.setValue(value);
            }}/>
            &nbsp;
            &nbsp;
            <Button
                basic
                size={'tiny'}
                color={'red'}
                content={getLocalString(this.props, 'remove_this_question')} onClick={(e)=>{
                e.preventDefault();

                value = value.filter((x,p)=> position !== p);

                this.setValue(value);

            }} />

        </List.Item>
    }

    renderQuestions(value){
        return value.map((item, position)=>this.renderQuestion(item, position, value));
    }

    renderWithVerification(){
        let {value} = this.props;
        if (!value) value = {};
        let {answers = []} = value;
        if (!answers) answers = [];

        return <List>
            {this.renderQuestions(answers)}
            <Button fluid content={getLocalString(this.props, 'add_question')} onClick={(e)=>{e.preventDefault();this.onAddQuestionButtonClick(answers);}}/>

        </List>
    }


}