import React, {Component} from 'react';


import {combineReducers, createStore} from 'redux'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom';

// Application REDUX components
import {middlewares, reducers} from './mvvm/models/index';

// Application UI Components
import App from './components/shell';


// FROALA

// Require Editor JS files.
//import 'froala-editor/js/froala_editor.pkgd.min.js';





//import 'font-awesome/css/font-awesome.css';
import "react-datepicker/dist/react-datepicker.css";



// Import internal SCSS
import "./styles/riskhub.scss";


//global['$'] = $;





const reducer = combineReducers(reducers);
const store = createStore(reducer, middlewares);




class ProvidedApplication extends Component {
    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        );
    }
}

export default ProvidedApplication;
