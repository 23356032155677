import React from 'react';

import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {ProblemCreateForm} from "./create";
import {ProblemUpdateForm} from "./update";
import {ProblemList} from "./list";

import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {ProblemItemView} from "./view";

class Router extends WithExtras(WithRouterLayout(GenericComponent), ['problem_category', 'problem', 'kpi_report_item']){

    storesMeta = ['problem', 'problem_category'];
    prefix = this.purl('/problems');


    routesMeta = {
        '/create': {Component: ProblemCreateForm},
        '/:id/update': {Component: ProblemUpdateForm},
        '/:id': {Component: ProblemItemView},
        '*': {Component: ProblemList},
    };




}



export default Router;