import React from 'react';
import {WithRouterLayout} from "../../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../../utils/proto/general";


import {Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {ControlTemplateCreateForm} from "./create";
import {ControlTemplateUpdateForm} from "./update";
import {TemplateControlList} from "./list";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";

class Router extends WithExtras(WithRouterLayout(GenericComponent),['control', 'control_area', 'risk_control_relation'] ) {


    prefix = this.purl('/templates/controls');

    routesMeta = {
        '/create': {Component: ControlTemplateCreateForm},
        '/:id': {Component: ControlTemplateUpdateForm},
        '*': {Component: TemplateControlList},
    };


}



export default Router;