import React from 'react';

export const TableWithEditableTextCell = (sc) => class extends sc {



    renderEditTextCell(args){

        const {item, key} = args;
        const {editedForm} = this.state;

        const formValue = editedForm[key];
        const itemValue = item[key];
        const noValue = formValue === undefined;


        const value = noValue ? itemValue : formValue;

        return <form onSubmit={this.onSaveClick.bind(this)}>
            <input value={value} className={'editable-table-text-cell'} onChange={this.getOnTextChangeHandler(key)} />
        </form>


    }


    getOnTextChangeHandler(key){
        return (e) => {
            const {value} = e.target;
            const {editedForm} = this.state;
            this.setState({
                editedForm: {
                    ...editedForm || {},
                    [key]: value,
                }
            })

        };
    };

    renderEditCell(args){
        const {editMeta} = args;
        if (editMeta.type === 'text'){
            return this.renderEditTextCell(args);
        }


        return super.renderEditCell ? super.renderEditCell(args) : null;
    }



};