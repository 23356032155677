import {
    action_remove_from_global_list, action_update_global,
    action_update_global_list,
    action_update_in_global_list
} from "../../../../mvvm/reducers/global";


export const WithList = (sc, url) => class extends sc {


    state = {
        ...super.state || {},
        payload: null,
    };

    get url(){
        return (this.props && this.props.url) ||
               url;
    }



    onDataLoaded = (p, o) => {
        let {payload} = p;
        return {payload};
    };

    requestData(){
        if (this.api)
            this.api(this.url, {}, {
                onSuccessResponse: this.onDataLoaded
            })
    }

    componentDidMount(){
        if (super.componentDidMount) super.componentDidMount();

        if (this.props.noRefresh){
            return;
        }

        this.requestData()

    }




};





export const WithCRUD = (sc, storeName) => class extends sc{

    state = {
        ...super.state,

        updating: null,
        removing: null,
    };

    get remove_url(){ return `/api/${storeName}/remove`}
    get update_url(){return `/api/${storeName}/update`}

    isRemovingItem = (item)=>(this.state.removing && this.state.removing.id)  === (item && item.id);
    isUpdatingItem = (item)=>(this.state.updating && this.state.updating.id)  === (item && item.updating);



     /* REMOVE FUNCTIONALITY */
    beforeRemove = (item) => {
        return this.beforeRequest({removing: item})
    };

    removeFromStore = (listPosition, item)=> {

        new Promise(()=>{this.props.dispatch({type: action_remove_from_global_list, index: listPosition, data: item, storeName});
                                 this.props.dispatch({type: action_update_global, storeName, data: {[storeName]: {[item.id]: null}} })})();


        return {};
    };

    afterRemove(state){
        if (!state) return state;

        state.removing = null;

        return this.afterRequest({...state});
    };

    removeItem(item, listPosition, options={}){


        this.api(this.remove_url, item, {
            afterRequest: (state)=> this.afterRemove(state),
            beforeRequest: ()=> this.beforeRemove(item),
            onSuccessResponse: ()=> this.removeFromStore(listPosition, item),
            ...options
        })
    }

    /* REMOVE FUNCTIONALITY */
    beforeUpdate = (item) => {
        return this.beforeRequest({updating: item})
    };

    afterUpdate = (state) => {
        if (!state) return state;
        state.updating = null;
        return this.afterRequest({...state});
    };


    updateItemInStore = ({payload, listPosition}) => {

        new Promise(()=>{


            this.props.dispatch({
                type: action_update_in_global_list,
                index: listPosition,
                data: payload,
                storeName
            });

            this.props.dispatch({type: action_update_global, storeName, data: {[storeName]: {[payload.id]: payload}} })



        })();

        return {};
    };

    updateItem(item, listPosition, options={}){
        this.api(this.update_url, item, {
            afterRequest: (state)=> this.afterUpdate(state, listPosition),
            beforeRequest: ()=> this.beforeUpdate(item),

            // Nothing changes, effectively
            onSuccessResponse: ({payload}) => {this.updateItemInStore({payload, listPosition})},
            ...options
        })
    }


};


export const WithStoreList = (sc, storeName, url=`/api/${storeName}/list`) =>
    class extends WithCRUD(WithList(sc, url), storeName){

    state = {
        ...super.state,
    };



    get items(){


        // Get items from store
        let {list} = this.props.state[storeName];

        // Now map item from store
        let storeList = list.map(this.mapItemFromPayload);

        return storeList;
    }

    getItemId(item){
        let id = 0;
        switch (typeof item){
            case 'number':
                id = item;
                break;
            default:
                id = item.id;
                break;
        }

        return id;
    }

    mapItemFromPayload = (item)=>{
        let {items: map} = this.props.state[storeName];
        let id = this.getItemId(item);
        return map[id];

    };

    onDataLoaded = ({payload}) => {

        this.props.dispatch({
            type: action_update_global_list,
            data: payload,
            storeName,
        });


        return {payload};
    };

};