import {ControlList, ControlListProto} from "../../control/list";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {WithFilter} from "../../../../utils/components/collections/list/filter/withFilter";


export class ControlsReportList extends WithFilter(WithExtras(ControlListProto, ['control_area', 'risk_control_relation'])){


    showPresent = true;

    state = {
        ...super.state,
        filterBy: {

        }
    };

    getMeta() {
        const meta = super.getMeta();

        const {efficacy, acronym, description, control_area} = meta;

        return {
            //is_present: {type: 'check', title: 'is_present'},
            efficacy:  {title: 'efficacy', type: 'efficacy',  collapsing: true},
            acronym,
            description,
            //control_area,
            control_area:  {title: 'control_area', type: 'text',  collapsing: true},
            //connected_risks: {title: 'connected_risks', type: 'connected_risks', collapsing: true, navigates: false},

        }
    }

    get filterMeta(){

        return {
            acronym: {type: 'text'},
            description: {type: 'text'},
            control_area_id: {type: 'storeDropdown', store: 'control_area', text: item=>item.name}
        }

    }



    getData(){
        const data = super.getData();
        const shortList = data.filter(x=>x.is_present === this.showPresent);

        return this.getFilteredData(shortList);
    }


    renderCreateButton(){
        return null;
    }

    onCellClick(){

    }

    renderReportButton(){
        return null

    }


}