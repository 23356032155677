import React from 'react';
import {Menu} from 'semantic-ui-react';
import {getLocalString} from "../../../../utils/localString";


export const WithNavMenuLayout = (sc) => class extends sc {

    prefix = '';
    postfix = '';

    getMenuItems(){
        return {
            'sample' : {route: '/sample', title: 'sample'},
        }
    }

    getMenuItemOptions(item, position){

        let options = {};
        let {route, includePrefix=true, includePostfix=true} = item;
        let key = route || position;

        let compiledRoute = `${(includePrefix && this.prefix) || ''}${route}${(includePostfix && this.postfix || '')}`;
        let {pathname = ''} = this.props.location;

        let active = pathname.indexOf(compiledRoute) > -1 || undefined;

        return {
            ...options,
            key,

            active,
            as: 'a',
            onClick: ()=>{ this.props.history.push(compiledRoute)}
        }

    }

    getMenuOptions(){
        return {
            vertical: true,
            fluid: true,
        }
    }


    renderAfterList(){
        return null;
    }
    renderBeforeList(){
        return null;
    }

    renderMenu(){
        return <Menu {...this.getMenuOptions()}>
            {this.renderBeforeList()}
            {this.renderMenuItems()}
            {this.renderAfterList()}
        </Menu>
    }

    renderMenuItem(item, key){
        return <Menu.Item {...this.getMenuItemOptions(item, key)}>
            {this.renderMenuItemContent(item, key)}
        </Menu.Item>
    }

    renderMenuItemContent(item, key){
        let {title = key} = item;
        return getLocalString(this.props, title);
    }

    renderMenuItems(){
          return Object.entries(this.getMenuItems()).map(([key, item])=>this.renderMenuItem(item, key));
    }

    renderWithVerification(){
        return this.renderMenu();
    }
};
