import React from 'react';
import {Form} from "semantic-ui-react";

const typeName = 'text';

export const WithTextFilter = (sc) => class extends sc {



    filterItemByMeta(args) {

        const filterValue = this.getFilterFieldValue(args);
        const {item, key, meta} = args;

        if (meta.type === typeName){


            const value = item[key] || '';

            return value.toLowerCase().indexOf( (filterValue||'').toLowerCase()) > -1;
        }

        return super.filterItemByMeta(args);
    }


    renderFilterField(args){
        const props = this.getFilterFieldProps(args);

        if (args.meta.type === typeName){
            return <Form.Input {...props} />;
        }

        return super.renderFilterField(args);




    }

};