import React from 'react';
import {getIconForRiskProbability, getTitleForRiskProbability} from "./utils/probability";
import {getLocalString} from "../../../../utils/localString";

import {Button, Popup, Accordion, Icon, Segment, Message} from 'semantic-ui-react';
import {getRiskIcon, getRiskLevel} from "./utils/category";
import {GenericComponent} from "../../../utils/proto/general";
import {WithStoreList} from "../../../utils/proto/mixins/withList";
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {WithStarFilter} from "../../../utils/components/collections/table/modifiers/withStarFilter";
import {WithFilter} from "../../../utils/components/collections/list/filter/withFilter";
import {ExpandableV2} from "../../../utils/components/expandable";
import {FlexRow} from "../../../utils/components/flexRow";



export const ResidualRiskIcon = (props) => {

    const {inherent_risk, residual_risk, is_opportunity} = props.item;

    const inherent_risk_title = is_opportunity ? 'inherent_value' : 'inherent_risk';
    const residual_risk_title = is_opportunity ? 'residual_value' : 'residual_risk'

    return  <Popup
        trigger={getRiskIcon(props, residual_risk, is_opportunity)}
        content={`${getLocalString(props, inherent_risk_title)}: ${inherent_risk.toFixed(2)}\n\r `+
                 `${getLocalString(props, residual_risk_title)}: ${residual_risk.toFixed(2)}`} />;

};


const renderTitleRowFor = (props, items, title, titleStyle) => {

    const count = items.length;

    return <span>
        <span style={{
            fontWeight: 'bold',

                ...(titleStyle || {})
        }}>
            {getLocalString(props,title)}
        </span>

        <span style={{
            color: '#858585',
            marginLeft: 20
        }}>
            {count} {getLocalString(props, 'items')}
        </span>

    </span>;
};

export class RiskView extends WithFilter(WithStoreList(GenericComponent, 'risk')) {

    showOpportunity = false;

    state = {
        ...super.state || {},
        activeIndex: 0,
        filterBy: {

        },
    };

    get filterMeta(){

        return {
            acronym: {type: 'text'},
            description: {type: 'text'},
            risk_category_id: {type: 'storeDropdown', store: 'risk_category', text: item=>item.name}
        }
    }


    get items(){

        const items = super.items;
        const {showOpportunity} = this;


        if (showOpportunity !== undefined){
            return items.filter(x=>x.is_opportunity === showOpportunity);
        }

        return items;
    }

    renderWithGroups(){

        let {items} = this;
        const groups = {
            2: [],
            1: [],
            0: []
        };

        items.forEach(risk => {
           const level = getRiskLevel(this.props, risk.residual_risk, risk.is_opportunity);
           if (!groups[level]) groups[level] = [];
           groups[level].push(risk);
        });

        const showHighRiskGroup = groups[2].length;

        return <Segment basic>
            <h1>{getLocalString(this.props, this.showOpportunity ? 'opportunities' : 'risks')}</h1>
            <Button
                color={'green'}
                content={getLocalString(this.props, 'add_new_item')}
                icon={'plus'}

                onClick={()=>{this.props.history.push(this.purl(`/risks/create?is_opportunity=${this.showOpportunity ? '1' : '0'}`))}}

            />

            <Button
                color={'green'}
                content={getLocalString(this.props, 'report')}
                icon={'table'}
                onClick={()=>{this.props.history.push(this.purl(`/reports/${this.showOpportunity ? 'opportunities' : 'risks'}`))}}
            />


            <br/><br/>

            <div>
                <ExpandableV2 titleRow={
                    renderTitleRowFor(this.props, groups[2], !this.showOpportunity ? 'high_risks_title' : 'low_opportunities_title', {fontSize: 30})
                }
                expanded>
                    <br/>
                    {!showHighRiskGroup && <FlexRow justify={'center'}><Message>{getLocalString(this.props, !this.showOpportunity ? 'no_error_risks_well_done_message' : 'no_low_opportunities_well_done_message')}</Message></FlexRow> || null}
                    {showHighRiskGroup && <RiskList {...this.props} risks={groups[2]} noRefresh={true} isOpportunity={this.showOpportunity} /> || null}
                </ExpandableV2>

                <ExpandableV2 titleRow={
                    renderTitleRowFor(this.props, groups[1], !this.showOpportunity ? 'medium_risks_title' : 'medium_opportunities_title')
                }>
                    <br/>
                    <RiskList {...this.props} risks={groups[1]} noRefresh={true} isOpportunity={this.showOpportunity} />
                </ExpandableV2>

                <ExpandableV2 titleRow={
                    renderTitleRowFor(this.props, groups[0], !this.showOpportunity ? 'ok_risks_title' : 'ok_opportunities_title')
                }>
                    <br/>
                    <RiskList {...this.props} risks={groups[0]} noRefresh={true} isOpportunity={this.showOpportunity}  />
                </ExpandableV2>

            </div>

        </Segment>
    }

    renderWithoutGroups(){

        let {items} = this;


        let sortedItems = [...items];
        sortedItems.forEach((x,i)=>{
            x.storeIndex = i
        });


        sortedItems.sort((b, a)=>Number(a.residual_risk) - Number(b.residual_risk));

        const filteredItems = this.getFilteredData(sortedItems);


        return <RiskList {...this.props} risks={filteredItems} noRefresh={true} />
    }

    shouldRenderWithGroups(){

        const {filterBy} = this.state;
        for (const x of Object.values(filterBy)){

            if ( (x !== null) && (x !== '')) return false;
        }

        return true;

    }

    renderWithVerification(){

        const shouldRenderWithGroups = this.shouldRenderWithGroups();

        return <div>
            {this.renderFilters()}
            {shouldRenderWithGroups ? this.renderWithGroups() : this.renderWithoutGroups()}
        </div>

    }

}

export class RiskList extends WithStarFilter(WithExtras(TableWithStoreList('risk'), ['risk_category'])){

    disableCreate = true;

    state = {
        ...super.state,
        orderBy: 'residual_risk',
        reverse: !this.props.isOpportunity,
    };

    getMeta(){
        return {
            residual_risk: {title: ' ', type: 'residual_risk', collapsing: true},
            acronym: {title: 'acronym', type: 'text', collapsing: true},
            description: {title: 'description', type: 'text'},
            risk_category_id: {title: 'category', type: 'risk_category', collapsing: true},
            //level: {title: 'level', type: 'level', collapsing: true},
            //connected_crimes: {title: 'problems', type: 'connected_crimes', collapsing: true, navigates: false},
            //connected_controls: {title: 'controls', type: 'connected_controls', collapsing: true, navigates: false},


            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }

    renderPreTable(){
        return null;
    }

    renderTableFooter(){
        return (!(this.disableCreate || false)) ? super.renderTableFooter() : null;
    }

    getData(){
        let risks =  this.props.risks || [];
        const starredData =  this.getStarredData(risks);

        return starredData;
    }

    renderConnectedProblems(item){
        let {risk_problem_relation} = this.props.state;
        let relations = risk_problem_relation.itemsByRiskId && risk_problem_relation.itemsByRiskId[item.id];
        let relationsCount = Object.entries(relations || {}).length;

        return this.renderRelationsCount(relationsCount);
    }

    renderConnectedControls(item){
        let {risk_control_relation} = this.props.state;

        let relations = risk_control_relation.itemsByRiskId && risk_control_relation.itemsByRiskId[item.id];
        let relationsCount = Object.entries(relations || {}).length;

        return this.renderRelationsCount(relationsCount);
    }

    renderRelationsCount(count){

        let props = {
            basic: true,
            color: 'red',
            content: getLocalString(this.props, `no_relations`)
        };

        if (count) {
            props.color = 'blue';
            props.content = getLocalString(this.props, `connected`);
        }

        return <Button {...props} label={count || `0`} />

    }

    renderProbabilityCell(item){

        let icon = getIconForRiskProbability(item.probability, item.is_opportunity);
        let text = getTitleForRiskProbability(item.probability, item.is_opportunity);
        return <span>{icon}&nbsp;{getLocalString(this.props, text)}</span>;

    }


    getCellContent(args){
        let {meta, item} = args;
        switch (meta.type){

            case 'level':
                return this.renderProbabilityCell(item);
            case 'residual_risk':
                return <ResidualRiskIcon {...this.props} item={item} />;
            case 'connected_crimes':
                return this.renderConnectedProblems(item);
            case 'connected_controls':
                return this.renderConnectedControls(item);
            case 'risk_category':
                let rc = this.props.state.risk_category.items[item.risk_category_id];
                return rc && rc.name || getLocalString(this.props, 'no_category');
            default:
                return super.getCellContent(args)
        }


    }

}



