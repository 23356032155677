
import React from 'react';

export const CenterAlignedComponent = (props) => {



    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }}>
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }}>
            {props.children}
        </div>
    </div>

}