

import React from 'react';
import {RiskTemplateForm} from "./form";
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {GenericComponent, purl} from "../../../../utils/proto/general";
import {getLocalString} from "../../../../../utils/localString";
import {Segment} from "semantic-ui-react";
import {RiskProblemRelationCreateForm, RiskProblemRelationsTable} from "../../risk/update/problem";
import {RiskControlRelationCreateForm, RiskControlRelationsTable} from "../../risk/update/control";
import {FlexRow} from "../../../../utils/components/flexRow";
import {NavToButton} from "../../../../utils/components/button/nav";

class TemplateRiskForm extends RiskTemplateForm(BasicUpdateForm('risk')){


}


export class UpdateTemplateRisk extends GenericComponent {



    renderWithVerification(){


        return <Segment basic>

            <TemplateRiskForm {...this.props} />

            <h1>{getLocalString(this.props, 'relations')}</h1>
            <Segment>
                <RiskProblemRelationsTable {...this.props} />
                <Segment padded basic>
                    <h3>{getLocalString(this.props, 'add_relation')}</h3>

                    <RiskProblemRelationCreateForm {...this.props} />
                </Segment>
            </Segment>



            <Segment>
                <FlexRow justify={'space-between'}>
                    <div>
                        <h3>{getLocalString(this.props, 'control_risk_relations')}</h3>
                    </div>

                    <div>
                        <NavToButton to={purl(this.props, `/templates/controls/create`)}
                        content={getLocalString(this.props, 'create_new_control')}
                                     icon={'plus'}
                                     color={'green'}
                        />
                    </div>
                </FlexRow>
                <RiskControlRelationsTable {...this.props} />
                <Segment padded basic>
                    <h3>{getLocalString(this.props, 'add_relation')}</h3>
                    <RiskControlRelationCreateForm {...this.props} />
                </Segment>
            </Segment>


        </Segment>
    }
}
