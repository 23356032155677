import React, {useState} from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicCreateForm} from "../../utils/proto/mixins/form/create";
import {WithExtras} from "../../utils/proto/mixins/withExtras";
import {Button, Card, Grid, Input, Segment} from "semantic-ui-react";
import {FlexRow} from "../../utils/components/flexRow";

const basicMeta = [
    {name: 'name', title: 'name', type: 'text'},
    {name: 'create_from_template', title: 'create_from_template', type: 'checkbox', defaultValue: true},
];




const TemplateCard = ({
    template,
    name,
    value,
    onChange,
    ...props
}) => {


    const isSelected = Number(template.id) === Number(value);

    const selectItem = (e) => {
        e && e.preventDefault && e.preventDefault();
        onChange(e, {name, value: template.id});
    };

    return <FlexRow justify={'center'}>

        <Card>
           <Card.Content>
               <Card.Header>
                   {template.name}
               </Card.Header>
               <Card.Meta>
                   {template.acronym}
               </Card.Meta>

               <Card.Description>
                   {template.description}
               </Card.Description>
           </Card.Content>

            <Card.Content extra>

                <Button
                    disabled={isSelected}
                    onClick={selectItem}
                    content={ getLocalString(props, isSelected ? 'selected' : 'select_template')}
                    fluid
                    color={'green'}
                />

            </Card.Content>

        </Card>


    </FlexRow>

};


const SelectTemplateProjectView = ({...props}) => {


    const [searchText, setSearchText] = useState("");

    const templateProjects = props.state.template_project.list;

    const filteredTemplates = templateProjects.filter( ({acronym, name, description})=>{
       if (setSearchText) {

           return `${name}${description}${acronym}`.toLowerCase().indexOf(`${searchText}`.toLowerCase()) > -1

       }

       return true;
    });

    const cards = filteredTemplates.map( (template) => <TemplateCard {...props} template={template} /> );

    const cardsInGrid = cards.map((card, index)=><Grid.Column key={index} mobile={16} tablet={8} computer={4}>
        {card}
    </Grid.Column>);
    return <div>

        <h2>{getLocalString(props, 'select_template')}</h2>

        <Segment basic={true}>
            <h4>{getLocalString(props, 'Filter')}</h4>
            <Input placeholder={getLocalString(props, 'template_name')} type={'text'} value={searchText} onChange={(e)=>{setSearchText(e.target.value)}}/>
        </Segment>
        <Grid>
            {cardsInGrid}
        </Grid>

    </div>

};



class ProjectCreateFormProto extends WithExtras(BasicCreateForm('project'), ['template_project', 'project_type']){



    templateProjectMeta = [
        ...basicMeta,
        {
         name: 'template_project_id',
         title: 'use_template',
         type: 'template',
         allowNull: false
        }

    ];

    scratchProjectMeta = [
        ...basicMeta,

        {
            name: 'acronym',
            type: 'text',
            title: 'create_acronym'
        }
    ];



    renderTemplateField(meta){

        const fieldProps = this.getFieldProps(meta);

        return <SelectTemplateProjectView {...this.props} {...fieldProps} />
    }

    renderCustomField(meta){

        let {type} = meta;

        switch(type){
            case 'template':
                return this.renderTemplateField(meta);
            default:
                return super.renderCustomField(meta);
        }
    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);

        item.is_template = false;
    }

    renderComplete(){


        let {payload} = this.state;

        if (payload && payload.id){
            this.props.history.push(this.curl(`/projects/${payload.id}`));
        }

        return null;
    }

    getFormMeta = ()=>{


        let {create_from_template} = this.state || {};

        if (create_from_template === undefined) create_from_template = true;

        let meta = create_from_template ?
            this.templateProjectMeta :
            this.scratchProjectMeta;



        return meta;

    }
}



export const ProjectCreateForm = (p)=><ProjectCreateFormProto {...p} storeName={'project'}/>;