import React from 'react';
import {AppComponent} from "../utils/proto/general";

import {Segment} from 'semantic-ui-react'
import {WithRouterLayout} from "../utils/proto/layouts/router";
import {getCurrentCompany} from "../../utils/sessionHelper";
import {getLocalString} from "../../utils/localString";
import {CompanySettingsView} from "./settings";
import {WithCompany} from "../shell/mixins/withCompany";
import {ProjectCreateForm} from "./project/create";
import {ProjectTemplateCreateForm} from "./project/template/create";
import {WithSubscription} from "./mixins/withSubscription";
import {ProjectListView} from "./project/list";
import {FroalaTestPage} from "./froala/sample";
import {WithSidebarLayout} from "../utils/proto/layouts/sidebar";
import {CompanySidebarPrimaryMenu} from "./sidebar/main";
import {CompanySidebarSecondaryMenu} from "./sidebar/secondary";


class MainView extends WithSubscription(WithRouterLayout(AppComponent)){
    prefix='/companies/:company_id';
    routesMeta = {
        //'/prc' : {Component: PrcList},
        //'/users': { Component: AppComponent},
        //'/subscription': { Component: AppComponent},


        '/create_project': {Component: ProjectCreateForm},
        '/create_template': {Component: ProjectTemplateCreateForm},
        '/settings': {Component: CompanySettingsView},
        '/froala': {Component: FroalaTestPage},
        '/projects': {Component: ProjectListView},
        '*': {Component: (p)=>{p.history.push(this.curl('/projects'));return null;}},
    };


    renderWithoutCompany(){
        return <Segment textAlign={'center'} vertical>
            {getLocalString(this.props, 'no_company_selected')}
        </Segment>
    }


    renderWithVerification(){

        let company = getCurrentCompany(this.props);
        if (!(company && company.id)) return this.renderWithoutCompany();
        return super.renderWithVerification();
    }
}


const View = WithSidebarLayout(CompanySidebarPrimaryMenu, CompanySidebarSecondaryMenu, MainView);

export const CompanyView = WithCompany(View);