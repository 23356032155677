import React from 'react';
import {Segment} from "semantic-ui-react";
import {ProjectsTaskAndKpiView} from "./list";
import {CreateTaskForm} from "./create";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {GenericComponent} from "../../../utils/proto/general";

export default class TaskView extends WithExtras(GenericComponent, ['task','control']){



    renderWithVerification(){

        let {props} = this;

        return <Segment basic>


            <Segment basic>
                <ProjectsTaskAndKpiView {...props} />
            </Segment>

            <Segment basic>
                <CreateTaskForm {...props} />
            </Segment>

        </Segment>
    }


}