import {ControlsReportList} from "../control/list";
import {Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
import React from "react";


export class ImprovementsReportList extends ControlsReportList{

    showPresent = false;



    renderPreTable(){

        return <Segment basic>
            <h1>{getLocalString(this.props, 'improvements')}</h1>
        </Segment>
    }

}