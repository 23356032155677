import React from 'react';
import {RiskForm} from "../form";

import {Button, Segment, Tab} from 'semantic-ui-react';
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {GenericComponent, purl} from "../../../../utils/proto/general";
import {getLocalString} from "../../../../../utils/localString";
import {RiskControlRelationCreateForm, RiskControlRelationsTable} from "./control";

import {RiskProblemRelationCreateForm, RiskProblemRelationsTable} from "./problem";
import {FlexRow} from "../../../../utils/components/flexRow";
import {NavBackTopLeftButton} from "../../../../utils/components/button/nav";
import {ExpandableV2} from "../../../../utils/components/expandable";
import {getFromStoreById} from "../../../../../utils/getFromStore";


class RiskUpdateForm extends RiskForm(BasicUpdateForm('risk')){


    getFormMeta() {
        const superMeta =  super.getFormMeta();

        // superMeta[5].expandable = true;
        // superMeta[6].expandable = true;

        return superMeta;
    }

    renderPreForm(){

        return null;

    }

    renderFields(){

        const {fieldsList} = this.getRenderedFields();

        const risk = this.state;

        const title = risk.is_opportunity ? `edit_opportunity` : `edit_risk`;

        const toggles = fieldsList.splice(0, 2);
        return <div>

            <NavBackTopLeftButton rightContent={<FlexRow>{toggles}</FlexRow>} />

            <h2>{getLocalString(this.props, title)} ({risk.acronym})</h2>
            {fieldsList.splice(0,3)}

            <ExpandableV2 style={{margin: "20px 0"}} titleRow={getLocalString(this.props, 'more')}>
                {fieldsList.splice(0,2)}
            </ExpandableV2>

        </div>


    }




}




export class RiskUpdateView extends GenericComponent {

    get risk_id(){
        return this.props.match.params.id;
    }


    panes = [

        { menuItem: getLocalString(this.props, 'control_relations'), render: () => <Tab.Pane>
                <Segment basic>
                    <Segment>
                        <FlexRow justify={'space-between'}>

                            <div>
                                <h3>{getLocalString(this.props, (getFromStoreById(this.props, 'risk', this.risk_id) || {}).is_opportunity ? 'risk_opportunity_relations' : 'risk_control_relations')}</h3>
                            </div>


                            <Button
                                onClick={()=>{this.props.history.push(purl(this.props, `/controls/create?risk_id=${this.risk_id}&ret_risk=${this.risk_id}&is_present=1`))}}
                                content={getLocalString(this.props, 'create')}
                                icon={'plus'}
                                color={'green'}
                                size={'small'}
                            />
                        </FlexRow>
                        <RiskControlRelationsTable {...this.props} />
                        <Segment padded basic>
                            <h3>{getLocalString(this.props, 'add_relation')}</h3>
                            <RiskControlRelationCreateForm {...this.props} />
                        </Segment>
                    </Segment>
                </Segment>
            </Tab.Pane> },

        { menuItem: getLocalString(this.props, 'problem_relations'), render: () => <Tab.Pane>
                <Segment basic>
                    <RiskProblemRelationsTable {...this.props} />
                    <Segment padded basic>
                        <h3>{getLocalString(this.props, 'add_relation')}</h3>
                        <RiskProblemRelationCreateForm {...this.props} />
                    </Segment>
                </Segment>
        </Tab.Pane> },

    ];


    renderWithVerification(){


        return <Segment basic>



            <Segment basic>
                <RiskUpdateForm {...this.props} />
            </Segment>


            <Tab panes={this.panes} />


        </Segment>
    }

}

