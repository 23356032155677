import React from 'react';
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {KPIForm} from "./form/form";
import {getDefaultFromQuery, getQueryParamsMap} from "../../../../utils/path";
import {purl} from "../../../utils/proto/general";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";



const getSearchMap = () => {

    const map = {};

    const search = window.location.search.substr(1);

    const parts = search.split("&");

    parts.forEach(part=> {

        const [key, value] = part.split('=');
        map[key] = value;
    });

    return map;
};


export class KPICreateForm extends BasicCreateForm('kpi', undefined, KPIForm){

    state = {
        ...super.state,
        description: getDefaultFromQuery('description', 'string')
    };

    componentDidMount(){
        super.componentDidMount && super.componentDidMount();

        const map = getSearchMap();

        let {problem_id} = map;

        if (problem_id){
            problem_id = Number(problem_id);
            this.setState({problem_id})
        }
    }


    renderComplete() {

        const {goBackTo, problem_id} = getQueryParamsMap();

        if (goBackTo && goBackTo === 'create_monitoring'){
            this.props.history.push(purl(this.props, '/monitoring_reports/create'));
        }

        if (problem_id){
            this.props.history.push(purl(this.props, `/problems/${problem_id}`));
        }

        return super.renderComplete();
    }


    renderWithVerification() {

        return <div>
            <NavBackTopLeftButton />
            { super.renderWithVerification()}
        </div>

    }

}
