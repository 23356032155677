import React from 'react';
import {Segment} from 'semantic-ui-react';
import {RiskList} from "../../risk/list";
import {GenericComponent} from "../../../../utils/proto/general";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {GoalsFilter} from "./filter";
import {getLocalString} from "../../../../../utils/localString";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";
import {getFromStoreById} from "../../../../../utils/getFromStore";
import {WithFilter} from "../../../../utils/components/collections/list/filter/withFilter";



export class RiskReportView extends WithExtras(GenericComponent, ['risk', 'risk_control_relation', 'risk_problem_relation', 'project', 'problem', 'control', 'problem_category']) {


    is_opportunity = false;

    state={
        ...super.state || {},

        value: null,
    };

    get items(){


        let {list} = this.props.state.risk || [];

        let sortedItems = [...list];
        sortedItems = sortedItems.filter(x=>x.is_opportunity === this.is_opportunity);
        sortedItems.sort((a,b)=>this.is_opportunity ? b.residual_risk - a.residual_risk : a.residual_risk - b.residual_risk);



        return sortedItems;

    }

    onFilterChange = (e, {value}) => {
          this.setState({value});
    };

    get categories(){
        // Get all the categories
        let project_id = getCurrentProjectId(this.props);

        // Get all the problem categories for that peoject

        let {problem_category} = this.props.state;

        let items = Object.values(problem_category.items);


        return items //.filter(x=>x.project_id == project_id);
    };

    getDefaultValue(){

        let {categories} = this;
        let map = {};

        for (let c of categories){
            map[c.id] = true;
        }


        return map;
    }

    get value(){
        let propsValue = this.state.value;
        return propsValue || this.getDefaultValue();

    }


    connectedToProblemArea(id, filters){

        // Get problems for that risk
        let map = this.props.state.risk_problem_relation.itemsByRiskId[id];
        if (!map) return false;

        // console.log('Connected Problems', map);
        let problem_ids = Object.keys(map);

        let problem_category_ids = problem_ids.map(pid=>{
            let problem = this.props.state.problem.items[pid];

            return (problem && problem.problem_category_id) || 0;
        });

        // console.log('Connected riskId -> Problem Category', `${id}->`, problem_category_ids);
        for (let pid of problem_category_ids){

            if (filters[pid]) return true;
        }



        return false;
    }


    getFilteredItems(){

        let {items, value: filters} = this;

        // If we don't have filters, just return basic thing
        if (!filters) return items;

        // console.log('Filters', filters);
        // If we do do the filtering
        return items.filter(({id})=>this.connectedToProblemArea(id, filters))


    }


    renderWithVerification(){

        let {items, value, categories} = this;

        //
        // <Segment className={'hide-on-print'} >
        //     <h5>{getLocalString(this.props, 'select_goals')}</h5>
        //     <GoalsFilter {...this.props} onChange={this.onFilterChange} value={value} categories={categories} />
        // </Segment>
        //

        return <Segment basic>
            <ReportRiskList {...this.props} risks={this.getFilteredItems(items)}  is_opportunity={this.is_opportunity} />
        </Segment>

    }

}

export class ReportRiskList extends WithFilter(RiskList) {


    storeName = 'risk';


    state = {

        ...super.state,
        orderBy: 'residual_risk',
        reverse: true,

        filterBy:{

        }
    };

    get filterMeta(){
        return {
            acronym: {type: 'text'},
            description: {type: 'text'},
            risk_category_id: {type: 'storeDropdown', store: 'risk_category', text: item=>item.name}
        }
    }

    getMeta(){
        return {
            risk_category_id: {title: 'category', type: 'external_value', targetField: 'name', store: 'risk_category', collapsing: true, navigates: false},
            acronym: {title: 'acronym', type: 'text'},
            risk: {title: 'result', type: 'residual_risk', collapsing: true, navigates: false},
            description: {title: 'description', type: 'text', navigates: false},
            level: {title: 'level', type: 'level', collapsing: true, navigates: false},
            //connected_crimes: {title: 'Problems', type: 'connected_crimes', collapsing: true, navigates: false},
            inherent_risk: {title: 'inherent_risk', navigates: false, collapsing: true},
            connected_controls: {title: 'Controls', type: 'connected_controls', collapsing: true, navigates: false},
            residual_risk: {title: 'residual_risk', navigates: false, collapsing: true},
        }
    }


    renderTableFooter(){
        return null;
    }


    renderConnectedProblems(item){
        let {risk_problem_relation} = this.props.state;
        let relations = risk_problem_relation.itemsByRiskId && risk_problem_relation.itemsByRiskId[item.id];
        let relationsCount = Object.entries(relations || {}).length;

        return relationsCount || 0;
    }

    renderConnectedControls(item){
        let {risk_control_relation} = this.props.state;

        let relations = risk_control_relation.itemsByRiskId && risk_control_relation.itemsByRiskId[item.id];



        let relationsList = Object.values(relations || {});

        let cids = relationsList.map(x=>x.control_id);



        let count = 0;

        cids.forEach(cid => {
            let control = getFromStoreById(this.props, 'control', cid);

            //console.log({cids, relationsList, cid, control});
             if (control && control.is_present) count++;
        });

        return count;
    }



    getData(){
        return this.getFilteredData(super.getData());
    }

}


