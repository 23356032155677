import React, {useState} from 'react';
import {Button, Label, Message, Segment} from "semantic-ui-react";
import {getRisksForProblemId} from "../../../../../../mvvm/reducers/utils/risk_problem_relation";
import {RiskList} from "../../../risk/list";
import {getLocalString} from "../../../../../../utils/localString";
import {FlexRow} from "../../../../../utils/components/flexRow";
import {NavToButton} from "../../../../../utils/components/button/nav";
import {purl} from "../../../../../utils/proto/general";



class RisksTable extends RiskList {

    state = {
        ...super.state,
        orderBy: 'acronym',
        reverse: true,
    };

    renderStarButton(){
        return null;
    }


    getMeta(){
        return {
            acronym: {title: 'acronym', type: 'text', collapsing: true},
            description: {title: 'description', type: 'text'},
            risk_category_id: {title: 'category', type: 'risk_category', collapsing: true},

            actions: {
                type: 'selector'
            }

         }
    }

    getData() {
        return this.props.risks;
    }


    renderSelectorButton({item}){
        const {selectedRisksMap} = this.props;

        const isSelected = !!selectedRisksMap[item.id];

        return <Label

            icon={isSelected ? 'check' : 'cancel'}
            color={isSelected ? 'green' : undefined}
            content={getLocalString(this.props, isSelected ? 'selected' : 'not_selected')}
        />

    }

    getCellContent(args){

        let {key, meta, item} = args;

        switch (meta.type){

            case 'selector':
                return this.renderSelectorButton(args);

            default:
                return super.getCellContent(args);
        }

    }

    renderPreTable() {
        return <div>
            <FlexRow justify={'center'}>
                <h3>{getLocalString(this.props, 'what_happenned_here_?')}</h3>
            </FlexRow>
        </div>
    }

    onCellClick({item}) {
        this.props.onCellClick(item);
    }
}

export const ImproveMonitoringRelationsView = ({report, item, onImproveRisks, ...props}) => {


    const [state, setState] = useState({});


    if (report.is_improved){
        return <Message
            header={getLocalString(props, 'improvements_are_submitted')}
            content={getLocalString(props, 'improvements_are_submitted_description')}
        />
    }

    const risks = getRisksForProblemId(props, item.problem_id);
    const onlyRisks = (risks || []).filter(x=>!x.is_opportunity);


    const toggleRisk = ({id: rid, ...risk}) =>  {
        //e && e.preventDefault && e.preventDefault();
        const newState = {...state};

        if (newState[rid]){
            delete newState[rid];
        } else {
            newState[rid] = risk;
        }

        setState(newState);
    };


    const onSubmitImprovements = (e) => {
        e && e.preventDefault && e.preventDefault();
        onImproveRisks(state);
    };
    //console.log(props);
    return <Segment>

        <FlexRow justify={'space-between'}>
            <div></div>

            <div>
                <div>
                    {report.is_complete ? <NavToButton
                        color={'blue'}
                        to={purl(props, `/risks/create?goBackToMonitoring=${report.id},${item.id}&problem_id=${item.problem_id}`)}
                        content={getLocalString(props, 'not_found_?_create_one')}
                    /> : null}
                </div>
            </div>
        </FlexRow>

        <RisksTable {...props} onCellClick={toggleRisk} risks={onlyRisks} selectedRisksMap={state} />

        <FlexRow justify={'center'}>
            <Button
                color={'green'}
                content={getLocalString(props, 'improve_the_strategy')}
                onClick={onSubmitImprovements}

            />
        </FlexRow>


    </Segment>;
};