import React from 'react';

export const FlexRow = (props) => {

    const {direction='row', justify='flex-start', style} = props;

    return <div style={{
        display: 'flex',
        flexDirection: direction,
        justifyContent: justify,
        ...style,
    }}>

        {props.children}

    </div>


};