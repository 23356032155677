import {TableWithStoreList} from "../../../../utils/components/collections/table/store";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";


export class KPIReportSelector extends TableWithStoreList('kpi'){




    renderPreTable(){
        return null;
    }

    renderTableFooter(){
        return null;
    }

    onCellClick(args){
        this.props.onKpiClick(args.item);
    }


    getTableProps(){
        return {
            celled: true,
            selectable: false,
            sortable: true,
        };
    }

    getRowProps(item){

        const props = super.getRowProps(item);

        const style = {
            cursor: 'pointer'
        };

        const {selectedKpis} = this.props;

        if (selectedKpis && selectedKpis[item.id]){

            style.color = '#0a8766';
            style.backgroundColor = '#d1f6e5'
        }

        props.style = style;

        return props;

    }

    getData() {
        const data =  super.getData();

        const project_id = getCurrentProjectId(this.props);

        return data.filter(({is_complete, project_id: pid})=>(!is_complete) && (Number(project_id) === Number(pid) ));
    }

    getMeta(){

        return {
            'description': {title: 'description', type: 'task_description'},
            'user_id':{title: 'user', collapsing: true, type: 'external_value', store: 'user', targetField: 'full_name'},
            'date': {title: 'due_date', type: 'date', collapsing: true},
        }
    }

}