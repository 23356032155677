import React from 'react';


export const levelsOfImpact = [
    {name: 'low', color: 'teal'},
    {name: 'medium', color: 'yellow'},
    {name: 'high', color: 'green'},
    {name: 'very_high', color: 'black'}
];


export const getLoiForRisk = (risk) => {

    if (risk.is_opportunity){

        return levelsOfImpact.map( (x, i)=>{

            const revIndex = levelsOfImpact.length-1-i;
            return {...x, color: levelsOfImpact[revIndex].color};
        });
    } else {
        return levelsOfImpact;
    }

};