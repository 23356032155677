import React from "react";
import {TasksList} from "../company/project/task/list";
import {getCurrentUser} from "../../utils/sessionHelper";
import {getLocalString} from "../../utils/localString";

import {KPIList} from "../company/project/kpi/list";
import {TableWithEditableRows} from "../utils/components/collections/table/editable/mixin";
import {getFromStoreById} from "../../utils/getFromStore";
import {WithExtras} from "../utils/proto/mixins/withExtras";
import {GenericComponent} from "../utils/proto/general";
import {Button, Icon, Table} from "semantic-ui-react";
import {FlexRow} from "../utils/components/flexRow";

 class Tasks extends TasksList{



    getMeta(){
        return {
            'project_name': {title: 'project', collapsing: true, navigates: false, type: 'nav_link', getLink: this.getLinkToProject},
            'description': {title: 'description', type: 'task_description'},
            //'user_id':{title: 'user', collapsing: true, type: 'external_value', store: 'user', targetField: 'full_name'},


            'date': {title: 'due_date', type: 'date', collapsing: true},
            'is_complete': {title: 'completion', type: 'mark_is_done', collapsing: true},

        }
    }

    getLinkToProject(item){
        let {company_id, project_id} = item;
        return `/companies/${company_id}/projects/${project_id}/tasks`;
    }

    renderPreTable(){
        return <h1>{getLocalString(this.props, 'my_tasks')}</h1>;
    }



    getData(){
        let data = super.getData(undefined, true);
        let user = getCurrentUser(this.props);

        if (!user) return [];

        const userTasks = data.filter(x=>x.user_id==user.id)

        return userTasks;
    }




 }



class MyKPI extends TableWithEditableRows(KPIList) {


    state = {

        ...super.state || {},
        orderBy: 'date'
    };

    get editMeta(){
        return {

            description: {type: 'text'},
            date: {type: 'date'},

            ...super.editMeta || {}
        }
    }



    getMeta(){

        const meta  = super.getMeta();

        delete meta.actions;
        delete meta.user_id;





        return {
            'project_name': {
                title: 'project',
                collapsing: true,
                navigates: false,
                type: 'nav_link',
                getText: this.getProjectName.bind(this),
                getLink: this.getLinkToProject.bind(this)},
            ...meta
        };
    }

    getProjectName(item){
        const project = getFromStoreById(this.props, 'project', item.project_id);

        return project ? project.name : getLocalString(this.props, 'no_project');
    }

    getLinkToProject(item){

        const project = getFromStoreById(this.props, 'project', item.project_id);

        let {project_id} = item;

        if (!project) return '/';

        let {company_id} = project;


        return `/companies/${company_id}/projects/${project_id}/tasks`;
    }


    getData(){

         let data = Object.values(this.props.state.kpi.items);
         let user = getCurrentUser(this.props);

         if (!user) return [];


         return data.filter(x=>x.user_id==user.id && !x.is_complete);

     }

}



export const MyTasksList = WithExtras( class extends GenericComponent {



    renderWithVerification(){
        const {props} = this;
        return <div>
            {/*<MyKPI {...props}/>*/}
            <Tasks {...props} />

        </div>
    }



}, ['user_project_relation', 'user_company_relation']);
