import React from 'react';
import {ControlForm} from "../form";
import {BasicCreateForm} from "../../../../utils/proto/mixins/form/create";
import {getLocalString} from "../../../../../utils/localString";
import {QuickRiskControlRelationsForm} from "./risk";
import {getDefaultFromQuery, getQueryParamsMap} from "../../../../../utils/path";
import {NavBackTopLeftButton} from "../../../../utils/components/button/nav";


export class ControlCreateForm extends ControlForm(BasicCreateForm('control')){

    // state = {
    //     ...super.state,
    // };


    state = {
        ...super.state,
        is_present: getDefaultFromQuery('is_present', 'boolean'),
        riskRelations: getDefaultFromQuery('risk_id', 'number', val=>({[val]: 3}))
    };

    renderPreForm(){
        const {is_present} = this.state;


        const text = is_present ? 'create_control' : 'create_improvement';


        return <h1>{getLocalString(this.props, text)}</h1>
    }

    prepareItemForSubmission(item){
        item.level=3;
        item.topology = 'preventive';
    }


    renderComplete(){

        const control = this.state.payload;
        const queryParams = getQueryParamsMap();

        if (queryParams['ret_risk']){
            this.props.history.push(this.purl(`/risks/${queryParams['ret_risk']}`));
        } else {
            this.props.history.push(this.purl(control.is_present ? '/controls' : '/improvements'));
        }


        return null;
    }



}


export class ControlCreateFormWithRelations extends ControlCreateForm{


    // state = {
    //     ...super.state,
    //
    // };




    renderWithVerification(){

        return <div>
            <NavBackTopLeftButton />
            {super.renderWithVerification()}

            <QuickRiskControlRelationsForm
                {...this.props}
                value={this.state.riskRelations || {}}

                onChange={(riskRelations)=>{
                this.setState({riskRelations});
            }} />


        </div>

    }

}
