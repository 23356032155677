import React from 'react';

import {Icon, Popup, Tab, Grid, Responsive, Button} from 'semantic-ui-react'
import {getLocalString} from "../../../../../../utils/localString";
import {ControlsTaskAndKpiView, ControlsTaskList} from "../../../task/list";
import {TableWithStoreList} from "../../../../../utils/components/collections/table/store";
import {auditTypesMeta} from "../../../audit/list";
import {CreateTaskForm} from "../../../task/create";
import {FlexRow} from "../../../../../utils/components/flexRow";
import {routeOnClick} from "../../../../../../utils/routeTo";



class CreateTaskInControlForm extends CreateTaskForm{

    getFormMeta(){
        return [

            {name: 'description', title: 'description', type: 'text'},
            {name: 'user_id', title: 'user', type: 'user_dropdown'},
            {name: 'date', title: 'date', type: 'date'},
            {name: 'no_date', title: 'no_due_date', type: 'checkbox'},
        ]
    }


    prepareItemForSubmission(item){
        if (item.no_date) item.date = null;

        let control_id = this.props.match.params.id;

        item.control_id = control_id;
    }



    renderFormHeader(){
        return <h3>{getLocalString(this.props, 'new_task')}</h3>
    }


    renderFields() {
        let {fieldsList} = this.getRenderedFields();

        let [description, user, date, no_date] = fieldsList;

        fieldsList[0] = <div style={{minWidth: 600}}>{description}</div>;

        fieldsList[2] = <div>
            {this.state.no_date ? <p style={{textAlign: 'center', color: '#1b1c1d'}}>{getLocalString(this.props, 'no_due_date')}</p> : date}{no_date}
        </div>;

        delete fieldsList[3];

        return <div>

            <Responsive minWidth={1440}>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    {fieldsList}
                </div>


            </Responsive>

            <Responsive maxWidth={1439} minWidth={0}>

                <Grid>

                    <Grid.Column mobile={16} tablet={6} computer={6}>
                        {description}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={6} computer={6}>
                        {user}
                    </Grid.Column>

                    <Grid.Column mobile={16} tablet={4} computer={4}>
                        {date}
                        {no_date}
                    </Grid.Column>
                </Grid>

            </Responsive>


        </div>;
    }





}



class ControlAuditList extends TableWithStoreList('audit') {


    renderAuditStatus({item}){
        //     {title: 'nc_full_title', abbreviation: 'nc', icon: 'minus', color: 'red'},
        let meta = auditTypesMeta[item.type];

        let view = <div>
            <Icon name={meta.icon} color={meta.color} />
            <span style={{marginLeft: 5, textTransform: 'uppercase'}}>{meta.abbreviation}</span>
        </div>;


        return <Popup trigger={view} content={getLocalString(this.props, meta.title)} />


    }


    getCellContent(args){

        let {meta} = args;
        let {type} = meta;
        switch (type){
            case 'audit_status':
                return this.renderAuditStatus(args);

            default:
                return super.getCellContent(args);
        }
    }

    renderPreTable() {
        return null;
    }


    getMeta(){
        return {

            title: {title: 'title'},
            status: {type: 'audit_status', title: 'status', collapsing: true},
            date: {title: 'on_date', collapsing: true, type: 'date'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},


        }
    }

    getData(){
        let data = super.getData();
        let control_id = this.props.match.params.id;

        if (!control_id) return [];

        return data.filter(x=>x.control_id == control_id);
    }


    getLinkToCreate(){

        let control_id = this.props.match.params.id;
        return super.getLinkToCreate()+`?control_id=${control_id}`;
    }

    renderWithVerification() {

        const linkToPlan = `${this.getLinkToCreate()}&type=3`;

        return <div>
            <FlexRow justify={'space-between'}>
                <div>
                    <h1>{getLocalString(this.props, 'audits')}</h1>
                </div>

                <div>
                    <Button
                        onClick={routeOnClick(this.props.history, linkToPlan)}
                        content={getLocalString(this.props, 'plan_an_audit')}
                        icon={'plus'}
                        color={'blue'}
                    />
                </div>
            </FlexRow>
            {super.renderWithVerification()}
        </div>
    }


}


export class AuditsAndTasksView extends React.Component{



    panes = [
        { menuItem: getLocalString(this.props, 'tasks'), render: () => <Tab.Pane>
                <ControlsTaskList {...this.props} />
                <CreateTaskInControlForm {...this.props} />
            </Tab.Pane> },

        { menuItem: getLocalString(this.props, 'audits'), render: () => <Tab.Pane><ControlAuditList {...this.props}/></Tab.Pane> },

    ];



    render(){

        return <Tab panes={this.panes} />
    }

}

