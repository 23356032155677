import React from 'react';
import {getLocalString} from "../../../../utils/localString";
import {Form, Segment} from "semantic-ui-react";





const levelsMeta = [


    {title: 'how_many_main_strategies'},
    {title: 'how_many_first_level_strategies'},
    {title: 'how_many_second_level_strategies'},

];


const Field = props => {


    const {index, meta} = props;

    let {value} = props;

    if (!value) value = levelsMeta.map(x=>0);





    return <div style={{
        //display: 'flex'

        marginTop: 15
    }}>

        <div
            className={'description'}

            style={{
                width: 200
            }}

        >{getLocalString(props, meta.title)}</div>

        <div className={'input'}>

            <Form.Input
                type={'number'}
                value={value[index]}
                onChange={(ev,{value: fieldVal})=>{
                    value[index] = fieldVal;

                    props.onChange(ev, {value})
                }}
            />

        </div>

    </div>


};



export const ProjectLevelsRequirementFields = (props) => {



    const renderedLevels = levelsMeta.map( (meta, index) =>
        <Field
            key={index}
            index={index}
            meta={meta}
            {...props}
        />
        );



    return <Segment>


        <h3>{getLocalString(props, 'ensure_success_company_title')}</h3>

        {renderedLevels}

    </Segment>

};