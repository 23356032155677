import React from 'react';
import {AppComponent} from "../../../proto/general";

import {Segment, Table} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";

export class MatrixPrototype extends AppComponent {


    /* VARIOUS PROPS */
    getPropsForTable(){
        return {
           celled: true,
            striped: true
        }
    }


    getPropsForRow(args){
        return {};
    }

    getPropsForColumnHeaderCell(args){
        return {};
    }


    getPropsForHeaderRow(){
        return {};
    }

    getPropsForHeaderRowItem(args){
        return {};
    }

    getPropsForCell(args){
        return {};
    }


    renderCellContent(args){
        return `${args.verticalPosition}:${args.horizontalPosition}`
    }

    renderHeaderRowCellContent(args){
        return `${args.position}`
    }

    renderHeaderColumnCellContent(args){
        return `Row ${args.position}`
    }

    /* Item getters */

    /**
     * Horizontal items
     * @returns {{}}
     */
    getHorizontalItems(){
        return [];
    }

    /**
     * Vertical items
     */
    getVerticalItems(){
       return [];
    }

    renderMatrix(){

        // Essentially matrix is a table, so we're going to build one
        let verticalItems = this.getVerticalItems();


        let rows = verticalItems.map((item, position) => this.renderRow({item, position, verticalItems}));

        return <Table {...this.getPropsForTable()}>
            {this.renderHeaderRow()}
            {rows}
            {this.renderFooterRow()}
        </Table>
    }

    renderCell(args){
        return <Table.Cell {...this.getPropsForCell(args)}>{this.renderCellContent(args)}</Table.Cell>
    }

    renderRow(args){

        let horizontalItems = this.getHorizontalItems();
        let headerColumn = this.renderHeaderColumn({...args, horizontalItems});

        let {item, position, verticalItems} = args;

        let cells = horizontalItems.map((horizontalItem, horizontalPosition)=>this.renderCell({
            verticalItem: item,
            horizontalItem,
            verticalPosition: position,
            horizontalPosition,
            horizontalItems,
            verticalItems
        }));

        return <Table.Row>
            {headerColumn}
            {cells}
        </Table.Row>

    }

    renderHeaderColumn(args){
        return <Table.HeaderCell>{this.renderHeaderColumnCellContent(args)}</Table.HeaderCell>
    }

    renderHeaderRow(){

        let items = this.getHorizontalItems();

        let hrItems = items.map((item, position)=>this.renderHeaderRowItem({item, position, items: hrItems}));
        return <Table.Row {...this.getPropsForHeaderRow()}>
            <Table.HeaderCell />
            {hrItems}
        </Table.Row>
    }

    /**
     * Renders Header Row Item
     * @param args
     * @returns {*}
     */
    renderHeaderRowItem(args){
        return <Table.HeaderCell {...this.getPropsForHeaderRowItem(args)}>{this.renderHeaderRowCellContent(args)}</Table.HeaderCell>
    }

    renderFooterRow(){
        return null;
    }

    renderMatrixShellFooter(){
        return null;
    }

    renderMatrixShellTitle(){
        return getLocalString(this.props, 'matrix');
    }

    /**
     *
     * @returns {*}
     */
    renderMatrixComponent(){
        return <Segment>
            {this.renderMatrixShellTitle()}
            {this.renderMatrix()}
            {this.renderMatrixShellFooter()}
        </Segment>
    }

    renderWithVerification(){
        return this.renderMatrixComponent();
    }

}