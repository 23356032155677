import React from 'react';


const getDefaultExpanded = () => {

    return window.innerWidth > 1450
};


export const WithSidebarLayout = (MainMenu, SecondaryMenu, Component) => class extends React.Component {


    state = {
        expanded: getDefaultExpanded()
    };


    toggleExpand = (expanded) => {
        if (expanded === undefined || typeof expanded !== 'boolean') expanded = !this.state.expanded;
        this.setState({expanded});
    };


    render(){


        const {props, state} = this;


        return <div className={'rh-sidebar'}>


            <MainMenu {...props} expanded={state.expanded} toggleSidebar={this.toggleExpand}/>
            <SecondaryMenu {...props} expanded={state.expanded} toggleSidebar={this.toggleExpand}/>

            <div className={`rh-sb-content ${state.expanded ? 'shifted' : ''}`} onClick={()=>{state.expanded && this.toggleExpand(false)}}>
                <Component {...props} expanded={state.expanded} toggleSidebar={this.toggleExpand}/>
            </div>


        </div>

    }



};