import React from 'react';
import {ProblemForm} from "./form";
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {getDefaultFromQuery, getQueryParamsMap} from "../../../../utils/path";


class ProblemCreateFormProto extends ProblemForm(BasicCreateForm('problem')){


    state ={
        ...super.state || {},
        severity: 3.0,

        description: getDefaultFromQuery('description')
    };

    renderComplete(){

        const params = getQueryParamsMap();

        if (params.goBackTo && params.goBackTo === 'createKPI'){
            this.props.history.push(this.purl(`/kpis/create?problem_id=${this.state.payload.id}`));
        } else {
            this.props.history.push(this.purl(`/problems`));
        }



        return null;
    }


}



export const ProblemCreateForm = (p) => <ProblemCreateFormProto storeName={'problem'} {...p}/>;