import {TableWithStoreList} from "../../../utils/components/collections/table/store";

export class MonitoringReportList extends TableWithStoreList('monitoring_report'){


    state = {
      orderBy: 'date',
      reverse: true,
    };


    getMeta(){

        return {
            //description: {type: 'text'},
            'createdAt': {title: 'date', type: 'date', collapsing: true},
            'is_complete': {title: 'completed', type: 'check', collapsing: true},
            'kpis_tracked': {title: 'kpis', type: 'number', collapsing: true},
            'total_tracked': {title: 'total', type: 'number', collapsing: true},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},

        }
    }

}
