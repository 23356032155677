import React from 'react';
import {GenericComponent} from "../utils/proto/general";
import {WithList} from "../utils/proto/mixins/withList";


import {Button, Card, Segment} from 'semantic-ui-react';
import {getLocalString} from "../../utils/localString";
import {WithSubscription} from "../company/mixins/withSubscription";


const RECURLY_LINK = `https://riskhub.recurly.com/subscribe`;

export default class SubscriptionView extends WithSubscription(WithList(GenericComponent, '/api/plans/list')) {


    get company_id(){
        return this.props.match.params.company_id;
    }

    get has_expired_subscriptions(){

        // If we couldn't load any plans or the plans didn't load yet
        if (!this.state.plansAreLoaded) return true;

        let {subscriptions = []} = this.props.state.subscriptions;

        let hasExpiredSubscriptions = false;

        for (let sub of subscriptions){
            if (sub.state === 'expired') hasExpiredSubscriptions = true;
        }



        return hasExpiredSubscriptions;
    }

    renderWithoutPlans(){
        return null;
    }

    renderPlan(prefix, planMeta){


        let [code, plan] = planMeta;


        let {title,
            description,
            price,
            redirects = false,
            redirect_to: url,
            button_color = redirects ? 'blue' : 'green',
            period = 'month',
            button_title
        } = plan;

        const btnContent = button_title ? getLocalString(this.props, button_title) : `€${price}/${getLocalString(this.props, period)}`;

        let account_id = `${prefix}-${this.company_id}`;
        if (!url) url = `${RECURLY_LINK}/${code}/${account_id}`;


        return <Card key={code}>

            <Card.Content header={getLocalString(this.props, title)} />
            <Card.Content content={getLocalString(this.props, description)} />
            <Card.Content extra>
                <Button as={'a'} href={url} fluid color={button_color}>
                    {btnContent}
                </Button>
            </Card.Content>
        </Card>;
    }

    renderPlans(prefix, plans){


        return plans.map((plan)=>
            this.renderPlan(prefix, plan)
        )
    }

    renderWithVerification(){


        //
        let {prefix, plans} = (this.state && this.state.payload) || {};
        let {has_expired_subscriptions} = this;
        let plansList = Object.entries(plans);


        if (has_expired_subscriptions){
            plansList = plansList.filter(([c, x])=>!x.is_trial);
        } else {
            plansList = plansList.filter(([c, x])=>x.is_trial);
        }

        //console.log({has_expired_subscriptions, plansList});
        if (! (plansList && plansList.length)) return this.renderWithoutPlans();



        return <Segment padded={'very'} basic>
            <h1>{getLocalString(this.props, 'select_a_plan')}</h1>
            <Segment basic>
                {getLocalString(this.props, 'select_a_plan_description')}
            </Segment>
            <Card.Group>
            {this.renderPlans(prefix, plansList)}
        </Card.Group>
        </Segment>
    }
}