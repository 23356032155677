import React from 'react';
import {GenericComponent} from "../../../utils/proto/general";
import {Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {WithCRUD} from "../../../utils/proto/mixins/withList";
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";



class Entry extends BasicUpdateForm('locale_entry', undefined, (props)=>props.id){

    getFormMeta() {
        return [
            {name: 'key', type: 'text', title: 'key'},
            {name: 'value', type: 'text', title: 'value'},
        ]


    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);
        item.locale_id = this.props.locale_id;
    }

}

class CreateEntry extends BasicCreateForm('locale_entry'){

    getFormMeta() {
        return [
            {name: 'key', type: 'text', title: 'key'},
            {name: 'value', type: 'text', title: 'value'},
        ]


    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);
        item.locale_id = this.props.locale_id;
    }

}



class EntriesList extends WithCRUD(GenericComponent, 'locale_entry'){


    renderWithoutEntries(){
        return <Segment textAlign={'center'}>
            {getLocalString(this.props, 'no_entries')}
        </Segment>
    }



    renderWithEntries(entries){

        return Object.values(entries).map((entry, position)=><Entry {...this.props}
            locale_id={this.props.locale_id}
            id={entry.id}
            key={position}


        />);
    }

    renderWithVerification(){

        let {entries} = this.props;
        if (! (entries && Object.values(entries).length)) return this.renderWithoutEntries();


        return this.renderWithEntries(entries);

    }

}


export class LocalizationEntryListView extends GenericComponent{


    get locale_id(){
        return this.props.locale_id;
    }


    get entries(){
        return this.props.state.locale_entry.entriesByLocale[this.locale_id];
    }

    renderWithVerification(){

        let {entries} = this;

        return <Segment style={{marginTop: 20}}>
            <h3>{getLocalString(this.props, 'locale_entries')}</h3>
            <EntriesList {...this.props} entries={entries}/>

            <h3>{getLocalString(this.props, 'add_new')}</h3>
            <CreateEntry {...this.props} locale_id={this.locale_id} />
        </Segment>
    }

}