import React from 'react';
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";


const basicMeta = [
    {name: 'name', title: 'name', type: 'text'},
    {name: 'acronym', title: 'acronym', type: 'text'},
];






export class ProjectTemplateCreateForm extends BasicCreateForm('project') {


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);

        item.is_template = true;
    }

    getFormMeta = ()=>{
        return basicMeta;
    }
}