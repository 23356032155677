import React from 'react';
import {RelationTable} from "../../utils/relationTable";
import {GenericComponent} from "../../../../utils/proto/general";


import {Button, Form, Dropdown, Icon} from 'semantic-ui-react'
import {getLocalString} from "../../../../../utils/localString";
import {levelsOfImpact} from "../../utils/loi";
import {action_add_to_global_list} from "../../../../../mvvm/reducers/global";
import {getFromStoreById} from "../../../../../utils/getFromStore";
import Responsive from "semantic-ui-react/dist/es/addons/Responsive/Responsive";
import {FlexRow} from "../../../../utils/components/flexRow";


export const LevelOfImpactDropdown = (props) =>{


    let options = levelsOfImpact.map( ({name, color}, index) => {
        return {value: index,
            text: getLocalString(props, name),
            icon: {color: color, name: 'arrow up'}
        }
    });

    return <Dropdown
        style={props.style}
        options={options}
        value={props.value}
        placeholder={getLocalString(props, 'select_level')}

        onChange={props.onChange}
    />


};

export const ControlEffectDirectionDropdown = (props) => {

    const {is_opportunity, style, value, onChange} = props;


    const getOptions = () => {
        if (is_opportunity){
            return [
                {value: true, text: getLocalString(props, 'increase_opportunity')},
                {value: false, text: getLocalString(props, 'decrease_opportunity')}
            ]
        }

        return [{value: true, text: getLocalString(props, 'increase_risk')},
            {value: false, text: getLocalString(props, 'decrease_risk')}]
    };

    const options = getOptions();

    return <Dropdown
        style={style}
        options={options}
        value={value}
        placeholder={getLocalString(props, 'effect_direction')}

        onChange={onChange}
    />

};

export class RiskControlRelationCreateForm extends GenericComponent {


    get items(){

        return this.props.state.control.list;
    }

    get options(){

        let {items} = this;


        let {risk_control_relation} = this.props.state;
        let {itemsByControlId} = risk_control_relation;
        items = items.filter(control=> {

            let controlMap = itemsByControlId[control.id];

            let hasRisk = controlMap && controlMap[this.primary_id];

            return !hasRisk
        });


        // Get risk_control relations
        return items.map( (item) => ({
            text: this.getControlDescription(item),
            value: item.id,
            label: {color: 'teal', content: item.acronym, size: 'tiny'}
        }))



    }


    get primary_id(){
        return this.props.match.params.id;
    }

    getControlDescription(item){
        const maxLength = 300;
        let {description} = item;
        // if (description && description.length > maxLength) {
        //     description = `${description.substr(0, maxLength)}...`
        // }

        return description;
    }



    renderOptionsDropdown(){


        return <Dropdown

            closeOnBlur
            value={this.state.control_id}
            placeholder={getLocalString(this.props, 'select_control')}
            search
            options={this.options}
            onChange={(e, {value})=>{this.setState({control_id: value})}}
        >
        </Dropdown>
    }


    renderLoiDropdown(){

        return <LevelOfImpactDropdown
            {...this.props}
            value={this.state.effect_level}
            onChange={(e, {value})=>{this.setState({effect_level: value})}}

        />
    }


    renderEffectDirection(){

        const risk = getFromStoreById(this.props, 'risk', this.primary_id);
        return <ControlEffectDirectionDropdown
            {...this.props}
            is_opportunity={risk.is_opportunity}
            value={this.state.is_positive}
            onChange={(e, {value})=>{this.setState({is_positive: value})}}

        />
    }



    onSuccessResponse = ({payload})=>{
        this.props.dispatch({type: action_add_to_global_list, storeName: 'risk_control_relation', data: payload});

        return {};
    };

    onSubmit = () => {

        let {effect_level, control_id} = this.state;
        let {primary_id: risk_id} = this;

        const risk = getFromStoreById(this.props, 'risk', risk_id);

        const is_positive = risk.is_opportunity;


        this.api('/api/risk_control_relation/create', {effect_level, control_id,  risk_id, is_positive}, {});

    };


    renderWithVerification(){

        const THRESHOLD_TABLET = 1400;
        const THRESHOLD_MOBILE = 700;

        const options =   <Form.Field>
            <label>{getLocalString(this.props, 'control')}</label>
            {this.renderOptionsDropdown()}
        </Form.Field>;

        const loi =  <Form.Field  style={{width: '150px'}}>
            <label>{getLocalString(this.props, 'level_of_impact')}</label>
            {this.renderLoiDropdown()}
        </Form.Field>;

        // const effect_direction = <Form.Field  style={{width: '150px'}}>
        //     <label>{getLocalString(this.props, 'effect_direction')}</label>
        //     {this.renderEffectDirection()}
        // </Form.Field>;

        const effect_direction = null;
        return <Form onSubmit={this.onSubmit}>

            <Responsive maxWidth={THRESHOLD_MOBILE}>
                <br/>
                <FlexRow justify={'center'}>
                    {options}
                </FlexRow>

                <br/>

                <FlexRow justify={'center'}>

                    <FlexRow direction={'column'}>

                        {loi}
                        {effect_direction}
                    </FlexRow>


                </FlexRow>

                <FlexRow justify={'center'}>

                    <Button
                        style={{marginTop: '10px'}}
                        onClick={this.onSubmit}
                        icon={'plus'}
                        content={getLocalString(this.props, 'create_relation')}
                        color={'teal'} />
                </FlexRow>


            </Responsive>

                <Responsive minWidth={THRESHOLD_MOBILE+1} maxWidth={THRESHOLD_TABLET}>

                    <FlexRow>
                        {options}
                    </FlexRow>
                    <br/>
                    <FlexRow>
                        {loi}
                        {effect_direction}
                    </FlexRow>

                    <FlexRow>
                        <Button
                            style={{marginTop: '10px'}}
                            onClick={this.onSubmit}
                            icon={'plus'}
                            content={getLocalString(this.props, 'create_relation')}
                            color={'teal'} />
                    </FlexRow>

                </Responsive>

                <Responsive minWidth={THRESHOLD_TABLET+1}>
                    <div style={{display: 'flex'}}>
                        <div style={{margin: '0 20px'}}>
                            {options}
                        </div>
                        {loi}
                        {effect_direction}

                    <Form.Field style={{marginLeft: '20px', minWidth: '200px'}}>
                        <Button style={{marginTop: '10px'}} onClick={this.onSubmit} icon={'plus'} content={getLocalString(this.props, 'create_relation')} color={'teal'} />
                    </Form.Field>

                    </div>
                </Responsive>









        </Form>
    }

}


export class RiskControlRelationsTable extends RelationTable('risk_control_relation', 'risk', 'control'){


    getMeta(){

        const meta = super.getMeta();

        const actions = meta.actions;
        delete meta.actions;

        return {
            //'effect_level':  {type: 'arrow_icon',  title: ' ', collapsing: true, navigates: false},
            'is_present': {name: 'is_present', type: 'check', title: 'is_present', collapsing: true},
            ...meta,
            'loi': {type: 'loi', title: 'level_of_impact'},
            //'is_positive': {type: 'is_positive', navigates: false},
            actions,
        }
    }


    getNoDataMessage(){
        return getLocalString(this.props, 'no_control_relations');
    }

    renderPreTable(){



        return null;

    }

    getLinkToItem(item){
        return this.purl(`/controls/${item.control_id}`);
    }


    renderEffectDirectionDropdown(item){
        const risk = getFromStoreById(this.props, 'risk', this.primary_id);

        return <ControlEffectDirectionDropdown
            {...this.props}
            value={item.is_positive}
            is_opportunity={risk.is_opportunity}
            onChange={ (e, {value})=>{
                this.updateItem({...item, is_positive: value}, item.storeIndex);
            }}

        />

    }

    getCellContent(args){
        let {meta} = args;
        let {item} = args;
        let secondary  = this.getSecondaryItem(item);


        switch (meta.type){



            case 'check':
                const {is_present} = secondary;
                return <Icon name={is_present ? 'check' : 'cancel'} color={is_present ? 'green' : 'red'} />;
            // case 'is_present':
            //     return <Icon
            case 'arrow_icon':


                let {effect_level} = item;
                let loi = levelsOfImpact[effect_level];

                let {color} = loi;

                return <Icon name={'arrow up'} color={color} />;

            case 'is_positive':
                return this.renderEffectDirectionDropdown(item);

            case 'loi':
                return <div style={{width: '80px'}}>
                    <LevelOfImpactDropdown
                        style={{
                            // width: '90%',
                            // float: 'left',
                            marginTop: '10px',
                        }}
                        {...this.props}
                        value={item.effect_level}
                        onChange={ (e, {value})=>{
                            this.updateItem({...item, effect_level: value}, item.storeIndex);
                        }}
                    />

                </div>;

            case 'actions':
                return this.renderRemoveButton({item});


            default:
                return super.getCellContent(args);
        }
    }






}
