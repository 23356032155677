import React from 'react';
import {WithList} from "../../../utils/proto/mixins/withList";
import {GenericComponent} from "../../../utils/proto/general";
import {WithProjects} from "../../mixins/withProjects";
import {getCurrentCompany, getCurrentProject} from "../../../../utils/sessionHelper";
import {Icon, Popup} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";



const navToProject = (props, project) => () => {
    props.history.push(`/companies/${project.company_id}/projects/${project.id}`)
};


const IconWithDescription = (p) => {

    const {name, description, color} = p;

    return <Popup trigger={<Icon name={name} color={color} />} content={description} />

};


const colorForTemplateState = (state) => {

    switch (state){

        case 2:
            return 'green';
        case 1:
            return undefined;

        default:
            return 'blue';
    }
};


const descriptionForTemplateState = (state) =>{
    return [
        `open_template`,
        `closed_template`,
        `marketplace_template`

    ][state]
};


export class SidebarSecondaryProjectsList extends WithProjects(WithList(GenericComponent, '/api/user_project_relation/list')) {





    renderProjects(){
        let currentProject = getCurrentProject(this.props);

        const {projects} = this;

        return (projects || []).map((project, index)=>{


            const {is_setting_up, is_template, template_state} = project;

            const isSame = project.id == (currentProject||{}).id;

            let icon;

            if (is_setting_up) icon =  <IconWithDescription name={'wrench'} description={getLocalString(this.props, 'project_is_setting_up')}/>
            if (is_template) icon = <IconWithDescription
                name={'copy'}
                color={colorForTemplateState(template_state)}
                description={getLocalString(this.props, descriptionForTemplateState(template_state))}
            />;

            if (!icon) icon = <Icon name={'file alternate'} />;

            return <div onClick={navToProject(this.props, project)} key={project.id || index} className={`rh-sb-item ${isSame ? 'active' : ''}`}>
                <div className={'icon'}>
                    {icon}
                </div>

                <div className={'content'}>
                    {project.name}
                </div>
            </div>

        })

    }


    renderWithVerification(){




        let currentCompany = getCurrentCompany(this.props);

        // Don't render dropdown if we are not in the company
        if (! (currentCompany && currentCompany.id)) return null;



        return <div className={'rh-sb-list'}>
            {this.renderProjects()}
        </div>


    }

}
