import {GlobalStore} from "../global";

export class RiskControlRelationReducer extends GlobalStore {



    get mapsMeta() {


        return {
            ...super.mapsMeta,
            itemsByRiskId: {path: ['risk_id', 'control_id']},
            itemsByControlId: {path: ['control_id', 'risk_id']},
        };

    };


}