import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {RiskCreateForm} from "./create";
import {RiskView} from "./list";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {RiskUpdateView} from "./update";

class Router extends WithExtras(WithRouterLayout(GenericComponent), ['risk_category', 'risk_control_relation', 'risk_problem_relation', 'risk']){

    prefix = this.purl('/risks' );

    routesMeta = {
        '/create': {Component: RiskCreateForm},
        '/:id': {Component: RiskUpdateView},
        '*': {Component: RiskView},
    };

}



export default Router;