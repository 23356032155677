import React from 'react';
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {Button, Icon, Table} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {getCurrentProject, getCurrentProjectId, getCurrentUser} from "../../../../utils/sessionHelper";
import {getFromStoreById} from "../../../../utils/getFromStore";
import {ProjectUserDropDown} from "../../../utils/components/form/components/store_dropdown/user_dropdown";
import {TableWithEditableRows} from "../../../utils/components/collections/table/editable/mixin";
import moment from 'moment';
import {KPIList} from "../kpi/list";
import {GenericComponent, purl} from "../../../utils/proto/general";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {FlexRow} from "../../../utils/components/flexRow";





export class TasksList extends TableWithEditableRows(TableWithStoreList('task')){


    get editMeta(){
        return {

            description: {type: 'text'},
            user_id: {type: 'task_user'},
            date: {type: 'date'},

            ...super.editMeta
        }
    }





    renderTableBody(data){

        if (! (data && data.length)){
            return this.renderWithoutItems();
        }

        let otherRows = data.map((i, k)=>this.renderRow(i, k));

        let top = otherRows.splice(0,3);

        return <Table.Body>

            {top}
            {this.state.expanded ? otherRows : null}
        </Table.Body>

    }




    renderTableFooter() {

        if (this.state.expanded) return null;

        return <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan={this.colSpan}>
                    <FlexRow justify={'center'}>
                        <Button
                            color={'blue'}
                            icon={'plus'}
                            content={getLocalString(this.props, 'show_others')}
                            onClick={()=>{this.setState({expanded: true})}}
                            size={'tiny'}
                        />
                    </FlexRow>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>


    }


    getMeta(){
        return {
            'control_acronym': {title: 'control', collapsing: true, navigates: false, type: 'nav_link',
                getText: (item) =>this.getControlName(item),
                getLink: (({control_id})=>this.purl(`/controls/${control_id}`))},
            'description': {title: 'description', type: 'task_description'},
            'user_id':{title: 'user', collapsing: true, type: 'external_value', store: 'user', targetField: 'full_name'},
            'date': {title: 'due_date', type: 'date', collapsing: true},

            'is_complete': {title: 'completion', type: 'mark_is_done', collapsing: true},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},

        }
    }


    getControlName = (item)=>{

        const {control_id, control_acronym} = item;
        if (control_acronym) return control_acronym;
        else if (control_id){
            const control =getFromStoreById(this.props, 'control', control_id);

            if (control) return control.acronym;
        }
        return 'no_control';
    };

    renderDoneButton({item}){

        let {is_complete} = item;

        if (is_complete){
            return <Icon color={'green'} name={'check'} />
        } else {
            return <Button
                        icon={'check'}
                        color={'blue'}
                        basic
                        content={getLocalString(this.props, 'mark_as_done')}
                        onClick={()=>{
                            this.updateItem({...item, is_complete: true});
                        }}
                />
        }

    }

    getData(includeCompleted=false, ignoreProject=false){
        let project_id = getCurrentProjectId(this.props);
        return super.getData().filter(x=>(!x.is_complete || includeCompleted) && (x.project_id == project_id || ignoreProject));
    }


    renderUserSelector(args){

        const {item, key} = args;
        const {editedForm} = this.state;

        const formValue = editedForm[key];
        const itemValue = item[key];
        const noValue = formValue === undefined;


        const value = noValue ? itemValue : formValue;


        return <ProjectUserDropDown
                    {...this.props}
                    value={value}
                    onChange={(e, {value})=>{

                        this.setState({
                            editedForm: {
                                ...editedForm || {},
                                [key]: value,
                            }
                        });
                    }}

                    onlyProject
                />;
    }


    renderEditCell(args){
        const {editMeta} = args;
        if (editMeta.type === 'task_user'){
            return this.renderUserSelector(args);
        }

        return super.renderEditCell ? super.renderEditCell(args) : null;
    }


    renderTaskDescription(args){


        return <TaskDescriptionCell {...this.props} {...args}/>;
    }

    renderStaticCell(args){

        let {meta} = args;
        let {type} = meta;

        switch (type){
            case 'mark_is_done':
                return this.renderDoneButton(args);
            case 'task_description':
                return this.renderTaskDescription(args);
            default:
                return super.renderStaticCell(args);
        }
    }
}


const TimeBadge = (props) => {

    const {item: task} = props;

    if (!task.date) return null;


    const date = moment.isMoment(task.date) ? task.date : moment(task.date);
    const now = moment(new Date());


    if (now.isAfter(date, 'day')) return <div className={'table-badge red'}>{getLocalString(props, 'past_due')}</div>;
    if (date.isSame(now, 'day')) return <div className={'table-badge yellow'}>{getLocalString(props, 'due_today')}</div>;

    const diff = now.diff(date, 'day');
    if (diff < 5) return <div className={'table-badge green'}>{getLocalString(props, 'due_soon')}</div>;

    return null;


};


const TaskDescriptionCell = (props) => {

    const {item} = props;

    return <div style={{
        display: 'flex',
        justifyContent: 'space-between'
    }}>
        <div>{item.description}</div>

        <div>
            <TimeBadge {...props} item={item} />
        </div>
    </div>

};



class KPI extends KPIList {






    getMeta(){

        const meta  = super.getMeta();

        return {
            'problem_description': {type: 'external_value', targetField: 'description', store: 'problem', idField: 'problem_id'},
            ...meta
        };
    }


    getData(){

        let data = Object.values(this.props.state.kpi.list);
        let project = getCurrentProject(this.props);

        if (!project) return [];


        return data.filter(x=>x.project_id==project.id && !x.is_complete);

    }

    getNoDataMessage(){
        return getLocalString(this.props, 'no_future_kpis_message');
    }


    renderWithVerification(){

        return <div>

            <FlexRow justify={'flex-end'}>


                <Button
                    color={'green'}
                    onClick={()=>{this.props.history.push(purl(this.props, '/kpis/create'))}}
                    content={getLocalString(this.props, 'create')}
                    icon={'plus'}

                />

            </FlexRow>

            {super.renderWithVerification()}


        </div>

    }

}



export class ControlsTaskList extends TasksList{

    getMeta(){
        const meta = super.getMeta();

        delete meta.control_acronym;

        return meta;
    }

    getData(){
        let data = super.getData(true);
        let control_id = this.props.match.params.id;

        if (!control_id) return [];

        return data.filter(x=>Number(x.control_id) === Number(control_id));
    }

}


export class ProjectsTaskList extends TasksList{



    getData(includeCompleted = false, ignoreProject = false) {


        if (this.state.showCompleted){
            return super.getData(true, ignoreProject);
        }

        return super.getData(includeCompleted, ignoreProject);
    }

    getNoDataMessage(){
        return getLocalString(this.props, 'no_future_tasks');
    }

    renderWithVerification(){

        const view = super.renderWithVerification();

        const {showCompleted} = this.state;

        return <div>
            <FlexRow justify={'flex-end'}>
                <Button
                    color={showCompleted ? 'blue' : undefined}
                    content={getLocalString(this.props, showCompleted ? 'hide_completed_tasks' : 'show_completed_tasks')}
                    onClick={()=>{this.setState({showCompleted: !showCompleted})}}
                    icon={showCompleted ? 'minus' : 'plus'}
                    //style={{ marginBottom: -50}}
                />


            </FlexRow>
            {view}
        </div>
    }
}




export const ProjectsTaskAndKpiView = class extends WithExtras(GenericComponent, ['problem']) {


    renderWithVerification() {

        return <div>

            {/*<KPI {...this.props} />*/}
            <ProjectsTaskList {...this.props} />


        </div>
    }
};

export const ControlsTaskAndKpiView = class extends WithExtras(GenericComponent, ['problem']){


    renderWithVerification(){

        return <div>

            <KPI {...this.props} />
            <ControlsTaskList {...this.props} />


        </div>
    }

};

