import React from 'react';
import {getFromStoreById} from "../../../../../../utils/getFromStore";
import {Grid, Header, Icon, Statistic} from "semantic-ui-react";

import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import {getLocalString} from "../../../../../../utils/localString";

import {kpiResultThresholdsMeta} from "../../../kpi/form/results_threshold_field";
import {CenterAlignedComponent} from "../../../../../utils/components/centered";

import moment from 'moment'

export const Result = (props) => {

};


export const getClosestValue = (values, value) => {
     let closestDelta = Number.MAX_VALUE;
     let closestIndex = 0;

    (values || []).forEach((val, index) => {

         let delta = Math.abs(Number(val) - Number(value));

         if (delta < closestDelta){
            closestDelta = delta;
            closestIndex = index;
         }

     });


     return {
         delta: closestDelta,
         index: closestIndex,
     }

};

export const ReportClosedItemView = (props) => {


    const {item} = props;

    const kpi = getFromStoreById(props, 'kpi', item.kpi_id);
    const {index} = getClosestValue(kpi.result_thresholds, item.result);

    const kpiResultMeta = kpiResultThresholdsMeta[index];

    return <Grid>

        <Grid.Row>

            <Grid.Column mobile={16} tablet={8} computer={6}>
                <Header size={'small'}>{getLocalString(props, 'result')}

                <Header.Subheader>
                    {getLocalString(props, 'on_date')} {moment(item.date).format('YYYY-MM-DD')}
                </Header.Subheader>

                </Header>
                <CenterAlignedComponent>
                    <Statistic>
                        <Statistic.Value>
                            <span style={{margin: 10, fontSize: 45}}>
                                {kpiResultMeta.emoji}
                            </span>
                        </Statistic.Value>
                        <Statistic.Label>
                            {getLocalString(props, kpiResultMeta.title)} ({item.result})
                        </Statistic.Label>
                    </Statistic>
                </CenterAlignedComponent>


            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={10}>

                <h4>{getLocalString(props, 'motivation')}</h4>
                <FroalaEditorView
                    model={item.motivation}
                />
            </Grid.Column>

        </Grid.Row>

    </Grid>
};
