import {getCurrentCompanyId, getCurrentUser} from "../../../utils/sessionHelper";


export const WithProjects =  (sc) => class extends sc {


    get project_relations() {
        let relations = Object.values(this.props.state.user_project_relation.items);
        let user = getCurrentUser(this.props);
        return relations.filter(x=>x.user_id == user.id);
    };

    get allProjects(){
        let projects = this.project_relations.map(relation=>this.props.state.project.items[relation.project_id]);

        // Filter DNE projects
        projects = projects.filter(x=>!!x);

        // Reduce duplicates
        let projectSet = {};
        projects.forEach(p => {
            projectSet[p.id] = p;
        });

        return Object.values(projectSet);
    }

    get projects(){

        let projects = this.allProjects;
        let company_id = getCurrentCompanyId(this.props);


        // Get projects
        if (company_id) {
            projects = projects.filter(x => x.company_id == company_id);
        }

        return projects;
    }




};