import React from 'react';
import {Responsive} from "semantic-ui-react";
export const SimpleResponsive = (props) => {

    const {children} = props;

    return   <div {...props}>
            <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                {children[0]}
            </Responsive>

            <Responsive minWidth={Responsive.onlyComputer.minWidth}>
                {children[1]}
            </Responsive>
    </div>



};