import React from 'react';
import {getFromStoreById} from "../../../../../../utils/getFromStore";
import {Message} from "semantic-ui-react";


export const MonitoringReportProblemPreview = (props) => {

    const {item} = props;

    const problem = getFromStoreById(props, 'problem', item.problem_id);

    if (!problem) return 'no problemo';

    return null;
    // return <Message
    //     icon={'pencil'}
    //     header={problem.acronym}
    //     content={problem.description}
    // />

};