import React from 'react';
import {WithStoreList} from "../../../../utils/proto/mixins/withList";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";
import {getFromStoreById} from "../../../../../utils/getFromStore";
import {GenericComponent} from "../../../../utils/proto/general";
import moment from 'moment';
import {getLocalString} from "../../../../../utils/localString";

const getControlAcronym = (props, item) => {

    const {control_id, control_acronym} = item;

    if (control_acronym) return control_acronym;
    else if (control_id) {
        const control = getFromStoreById(props, 'control', control_id);

        if (control) return control.acronym;
    }
    return '';

};



const TimeBadge = (props) => {

    const {task} = props;

    if (!task.date) return null;


    const date = moment.isMoment(task.date) ? task.date : moment(task.date);
    const now = moment(new Date());


    if (now.isAfter(date, 'day')) return <div className={'badge red'}>{getLocalString(props, 'past_due')}</div>;
    if (date.isSame(now, 'day')) return <div className={'badge orange'}>{getLocalString(props, 'due_today')}</div>;

    const diff = now.diff(date, 'day');
    if (diff < 5) return <div className={'badge yellow'}>{getLocalString(props, 'due_soon')}</div>;

    return null;
};

const Task = (props) => {

    const {task} = props;

    const acronym = getControlAcronym(props, task);

    return <div className={'rh-sb-task'}>
        
        <div className={'content'}>
            <div className={'description'}>{task.description}</div>
        </div>
        <div className={'content'}>
            <div className={'badge'}>{acronym}</div>
            <TimeBadge {...props} />
        </div>

    </div>
};


export class MenuProjectTaskList extends WithStoreList(GenericComponent, 'task') {


    getControlName = (item) => {

        const {control_id, control_acronym} = item;
        if (control_acronym) return control_acronym;
        else if (control_id) {
            const control = getFromStoreById(this.props, 'control', control_id);

            if (control) return control.acronym;
        }
        return '';
    };

    getData(includeCompleted = false, ignoreProject = false) {
        let project_id = getCurrentProjectId(this.props);
        return this.items.filter(x => (!x.is_complete || includeCompleted) && (x.project_id == project_id || ignoreProject));
    }



    renderWithVerification(){

        const data = this.getData();

        if (! (data && data.length)) return <div>{getLocalString(this.props, 'no_highlights')}</div>;

        return <div className={'rh-sb-tasks'}>
            {(data || []).map((item, position)=><Task {...this.props} key={position} task={item}/>)}
        </div>
    }

}