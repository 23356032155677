import React from 'react';
import {getUrlPart} from "../../../../utils/path";
import {getFromStoreById} from "../../../../utils/getFromStore";
import {problemSeverityOptions} from "./utils/severity";
import {Button, Icon, Segment, Tab} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {purl} from "../../../utils/proto/general";
// import {ProblemsCompletedKPIList, ProblemsKPIList} from "./kpi/kpi";
import {ProblemRiskList} from "./risks/list";
import {ProblemRiskRelationCreateForm, ProblemRiskRelationCreateView} from "./risks/create";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";

export const getProblemIdFromURL = () => {

    return getUrlPart(6);

};


const TitleRow = (props) => {

    const {problem, category} = props;


    const severityOption = problemSeverityOptions[problem.severity];
    let icon = null;

    if (severityOption){
        const {icon: iconName='arrow up', color} = severityOption;

        icon=<Icon name={iconName} color={color} size={'big'} style={{lineHeight: 1.2}} />
    }

    return <div style={{
        display: 'flex',
        justifyContent: 'space-between',
    }}>

        <div style={{
            display: 'flex'
        }}>
            <h1>{problem.acronym} / {category.name}</h1>
            <div style={{marginLeft: 20}}>{icon}</div>
        </div>


        <div>
            <Button
                icon={'print'}
                content={getLocalString(props, 'print')}
                onClick={()=>{window.print()}}
                color={'blue'}
            />


            <Button
                icon={'edit'}
                content={getLocalString(props, 'edit')}
                onClick={()=>{props.history.push(purl(props, `/problems/${problem.id}/update`))}}
            />
        </div>
    </div>

};

export const RiskOpportunitiesTabView = (props) => {

    const {problem_id} = props;



    const panes = [
        {menuItem: getLocalString(props, 'risk_goal_title'), render: ()=> <Tab.Pane>

                <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <div>
                        <Button
                            content={getLocalString(props, 'create_risk')}
                            icon={'tags'}
                            color={'green'}
                            onClick={()=>{
                                props.history.push(purl(props, `/risks/create?is_opportunity=0&ret_problem=${problem_id}&problem_id=${problem_id}`));
                            }}
                        />
                    </div>
                </div>
                <ProblemRiskList {...props} problem_id={problem_id} showOnlyOpportunities={false} />

                <ProblemRiskRelationCreateView {...props} problem_id={problem_id} showOnlyOpportunities={false} />


            </Tab.Pane>},
        // {menuItem: getLocalString(props, 'opportunities_goal_title'), render: ()=> <Tab.Pane>
        //
        //         <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
        //             <div>
        //                 <Button
        //                     content={getLocalString(props, 'create_opportunity')}
        //                     icon={'tags'}
        //                     color={'green'}
        //                     onClick={()=>{
        //                         props.history.push(purl(props, `/risks/create?is_opportunity=1&ret_problem=${problem_id}&problem_id=${problem_id}`));
        //                     }}
        //                 />
        //             </div>
        //         </div>
        //         <ProblemRiskList {...props} problem_id={problem_id} showOnlyOpportunities={true} />
        //
        //         <ProblemRiskRelationCreateView {...props} problem_id={problem_id} showOnlyOpportunities={true} />
        //
        //
        //     </Tab.Pane>},
    ];


    return <div>
        <Tab className={'hide-on-print'} panes={panes} />

        <div className={'print-only'}>

            <h3>{getLocalString(props, 'opportunities')}</h3>
            <ProblemRiskList {...props} problem_id={problem_id} showOnlyOpportunities={true} />


            <h3>{getLocalString(props, 'risks')}</h3>
            <ProblemRiskList {...props} problem_id={problem_id} showOnlyOpportunities={false} />
        </div>
    </div>

};


export const ProblemItemView = (props) => {

    const problem_id = getProblemIdFromURL();
    const problem = getFromStoreById(props, 'problem', problem_id);
    if (!problem) return `Error: No problem in the store with pid ${problem_id}`;

    const category = getFromStoreById(props, 'problem_category', problem.problem_category_id);

    if (!category) return `Error: No category in store`;

    return <Segment basic>

        <NavBackTopLeftButton />
        <TitleRow {...props} {...{category, problem}}/>
        <Segment>
            {problem.description}
        </Segment>


        {/*<div>*/}
        {/*    <ProblemsCompletedKPIList {...props} problem_id={problem_id} />*/}
        {/*</div>*/}

        <br/>

        <RiskOpportunitiesTabView {...props} problem_id={problem_id} />


    </Segment>
};
