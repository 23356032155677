import React from 'react';
import {SidebarPrimaryMenu as Menu} from "../../../utils/proto/layouts/sidebar/primary";
import {getLocalString} from "../../../../utils/localString";
import {getCurrentUser} from "../../../../utils/sessionHelper";



export const TasksPrimaryMenuItem = (props) => {


    return   <Menu.Item
        key={'tasks'}
        icon={'calendar'}
        hint={getLocalString(props, 'my_tasks')}
        onClick={()=>{
            props.history.push('/tasks');
        }}
    />
};



export const UserSettingsMenuItem = (props) => {

    const user = getCurrentUser(props);

    return <Menu.Item
        key={'user-settings'}
        hint={getLocalString(props, 'user_settings')}
        icon={'user'}
        onClick={()=>{
            props.history.push(`/user/${user.id}`);
        }}
    />
};


