import React from 'react';
import {AppComponent} from "../../proto/general";
import {getLocalString} from "../../../../utils/localString";
import {Button, Icon, Form, Header, Segment, TextArea} from 'semantic-ui-react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {SearchDropdown} from "./components/search_dropdown";
import {withFroala} from "./modifiers/withFroala";
import {withCheckboxButton} from "./modifiers/withCheckboxButton";
import {Expandable, ExpandableV2} from "../expandable";


export class FormPrototype extends AppComponent {

  state = {
	changed: false
  };

  get _title() {
	return (this.title && this.title()) || (this.props && this.props.title) || '';
  };

  get _isSubmitting() {
	return (this.isSubmitting && this.isSubmitting()) || (this.props && this.props.isSubmitting) || false
  };

  get _isLoading() {
	return (this.isLoading && this.isLoading()) || (this.props && this.props.isLoading) || false
  };

  get _isEnabled() {
	let v = (this.isEnabled && this.isEnabled());

	if (v === undefined) v = (this.props && this.props.isEnabled);
	if (v === undefined || v === null) v = true;

	return v;

  };


  /**
   * Providing internal methodology to run a constructor
   * @param props
   * @returns {*}
   */
  constructor(props) {
	super(props);
	return this.onConstructWithProps(props);
  }

  /**
   * Returns default value for fields
   * @param meta
   * @returns {*}
   */
  getDefaultValueForField(meta) {

	let {type} = meta;
	switch (type) {

	  case 'text':
	  case 'textarea':
		return '';
	  case 'number':
		return 0;
	  case 'date':
		return moment();
	  case 'file':
	  case 'dropdown':
		return undefined;
	  default:
		return null;
	}
  }

  /**
   * Returns props for the input field (Used by Form.Input)
   * @param meta form meta
   * @returns {{key: *, label: string|*, value: *, onChange: any, name: *, error: undefined}}
   */
  getFieldProps(meta) {
	let {name, title, localized = true, step, hidden} = meta;

	let label = title;

	if (localized && title) {
	  label = getLocalString(this.props, title);
	}

	let value = this.getValueForField(meta);
	if (value === null || value === undefined) value = "";


	if (!value && isNaN(value)) value = 0;

	//console.log(`${name}: '${value}'`);
	let error = undefined;


	return {
	  key: `${name}-${title}`,
	  ...(!hidden && label && {label}),
	  value,
	  step,
	  onChange: this.onChange.bind(this),
	  name,
	  error,
	};
  }

  /**
   * Returns meta-info to construct the fields
   * @returns {*[]}
   */
  getFormMeta() {
	return [
	  {name: 'test', title: 'test', type: 'text', localized: false},
	  {name: 'test_date', title: 'Test Date', type: 'date', localized: false},
	  {name: 'test_file', title: 'Test File', type: 'file', localized: false},
	  {name: 'test_number', title: 'Test Number', type: 'number', localized: false},
	]
  }

  mapStoreItemToOption({item, meta}) {
	let {textFieldName = 'value', localized = true} = meta;
	let text = item[textFieldName];

	if (localized) text = getLocalString(this.props, text);
	return {value: item.id, text};
  }

  getOptionsFromStore(meta) {

	if (!this.props) throw `No props in getOptionsFromStore`;
	if (!this.props.state) throw `Component is not connected in ${this.constructor.name}`;

	let store = this.props.state[meta.storeName];
	let {items: map} = store;
	let items = store.list;
	if (!items) items = [];
	let mappedItems = items.map((i, n, a) => {
	  return this.mapStoreItemToOption({item: i, meta, index: n, items: a})
	});

	return mappedItems.filter(x => !!x);
  }

  getOptionsForMeta(meta) {


	let {options, storeName, allowNull = false} = meta;

	if (typeof options === 'function') options = options(meta);

	else if (options) {
	} else {
	  options = [];
	  if (storeName) {
		options = this.getOptionsFromStore(meta);
	  } else {
		console.dir({
		  message: 'No function returns options for the meta dropdown',
		  meta
		});
		options = [];
	  }
	}

	if (allowNull) {
	  options.push({value: null, text: getLocalString(this.props, 'none')});
	}


	return options;

  }

  getRenderedFields() {
	let formMeta = this.getFormMeta();
	let fieldsMap = {};
	let fieldsList = [];

	for (let meta of formMeta) {
	  let {name} = meta;
	  let field = this.renderField(meta);

	  fieldsMap[name] = field;
	  fieldsList.push(field);
	}
	return {fieldsMap, fieldsList};

  }

  /**
   * A method to retrieve a value for a field. Mostly does conversion to a proper type from state
   * @param meta
   * @returns {*}
   */
  getValueForField(meta) {
	let {name, type, defaultValue = this.getDefaultValueForField(meta)} = meta;
	if (!this.state) return defaultValue;
	if (this.state[name] === undefined || this.state[name] === null) return defaultValue;

	let stateVal = this.state[name];
	switch (type) {
	  case 'number':
		return Number(stateVal);
	  case 'date':
		return moment.isMoment(stateVal) ? stateVal : moment(stateVal);

	  // case 'dropdown':
	  //     let options = this.getOptionsForMeta(meta);
	  //     if (!meta.allowNull && options.length) {
	  //         return options[0].value;
	  //     }
	  //     return undefined;

	  // Filed don't have "value"
	  case 'file':
		return undefined;

	  default:
		return stateVal;
	}


  }

  /**
   * Handles a change of a single field
   * @param e - event
   * @param name - name of the field
   * @param value - updated value
   */
  onChange = (e, {name, value}) => {
	// console.log({e, name, value});
	this.setState({[name]: value, changed: true});
  };


  /**
   * Called inside constructor.
   * decorator methods
   * @param props
   */
  onConstructWithProps(props) {
	let state = {};

	for (let meta of this.getFormMeta()) {
	  let {name, defaultValue, type, storeName, allowNull} = meta;
	  if (defaultValue) state[name] = defaultValue;


	  if (type === 'dropdown' && !allowNull && storeName) {
		let items = this.props.state[storeName].items;
		if (!(items && items.length)) {
		  console.log(`${name} is not allowed to have nulls, but store doesn't have any items`);
		} else {
		  state[name] = items[0].id;
		}

	  }
	}

	this.state = {...this.state, ...state};
  }

  /**
   * Handler of the file field change
   * @param e
   */
  onFileChange(e) {

	let {files: formFiles, name} = e.target;
	let {files = {}} = this.state || {};
	files[name] = formFiles[0];
	this.setState({files});


  }

  /**
   * Handler of the submitted field. Prepares the item
   * and sends it to onSubmitPrepared() handelr, which is the method that should be inherited
   */
  onSubmit() {
	let item = {...this.state};
	this.prepareItemForSubmission(item);
	this.onSubmitPrepared(item);
  }

  onSubmitPrepared(item) {
	console.dir({
	  message: 'onSubmitPrepared is not handled',
	  item
	});
  };

  prepareItemField(meta, item) {
	let {name, type} = meta;

	let val = this.getValueForField(meta);
	if (item[name] === undefined && val !== undefined) item[name] = val;
	switch (type) {
	  case 'number':
		if (typeof (val) !== 'number')
		  item[name] = Number(val);
		break;
	  case 'date':
		let momentDate = moment.isMoment(val) ? val : moment(val);
		item[name] = momentDate.format('YYYY-MM-DD');
		break;
	  default:
		break;
	}
  }

  /**
   * Base function to prepare items for submission.
   * Converts dates to YYYY-MM-DD format
   * @param item
   */
  prepareItemForSubmission(item) {


	let meta = this.getFormMeta();

	for (let metaItem of meta) {
	  this.prepareItemField(metaItem, item);
	}

  }

  renderBasicField(meta) {
	let {type, size, value, hidden} = meta;

	let input = (
	  <Form.Input
		fluid={size ? true : undefined}
		{...this.getFieldProps(meta)}
		{...value && {value}}
		{...hidden && {style: {display: 'none'}}}
		type={type}
	  />
	);


	if (!size) return input;
	switch (size) {

	  case 'fluid':
		return <div style={{width: '100%'}}>{input}</div>;

	  case 'small':
		return <div style={{width: '50px'}}>{input}</div>;
	  case 'medium':
		return <div style={{width: '100px'}}>{input}</div>;

	  default:
		return <div style={{width: size}}>{input}</div>;
	}


  }

  renderCheckbox(meta) {
	let props = this.getFieldProps(meta);

	let onChange = (e, {checked}) => {
	  props.onChange(e, {name: meta.name, value: !!checked});
	};

	let checked = !!props.value;

	return <div key={props.key} style={{margin: '10px 0'}}>

	  <Icon

		name={checked ? 'check square' : 'square outline'}
		color={checked ? 'green' : undefined}
		size={'large'}
		link
		onClick={(e) => {
		  e.preventDefault();
		  onChange(e, {checked: !checked})
		}}

	  />
	  &nbsp;
	  {props.label}

	</div>
  }

  renderCustomField(meta) {
	return <div key={meta.name} style={{color: 'red'}}>{JSON.stringify(meta)}</div>;
  }

  renderDateField(meta) {

	const {value: selectedValue, hidden} = meta;

	const normalizedSelectedValue =
	  moment.isMoment(selectedValue)
		? selectedValue
		: moment(selectedValue);

	let value = this.getValueForField(meta);
	let props = this.getFieldProps(meta);
	return (
	  <Form.Field
		key={props.key}
		style={hidden ? {display: 'none'} : undefined}>
		<label>{props.label}</label>
		<DatePicker
		  selected={selectedValue ? normalizedSelectedValue : value}
		  onSelect={(updatedDate) => {
			this.setState({[meta.name]: updatedDate});
		  }}
		  dateFormat={'DD-MM-YYYY'}

		/>
	  </Form.Field>
	)
  }

  renderDropdownField(meta) {

	let options = this.getOptionsForMeta(meta);
	let props = this.getFieldProps(meta);
	return <Form.Select {...props} options={options} placeholder={getLocalString(this.props, 'none')}/>
  }

  renderErrorsView() {

	let {errorBody} = this.state || {};

	if (!errorBody) return null;

	let {message = "", payload} = errorBody;

	if (message === "UNKNOWN_SERVER_ERROR") {
	  if (typeof (payload) === 'string') {
		message = payload;
	  }
	}

	return <div style={{color: 'red', margin: '10px 0'}}>
	  {getLocalString(this.props, message.toLowerCase())}
	</div>;
  }

  renderLabel(meta) {

	let props = this.getFieldProps(meta);
	let {value} = props;

	return <h4>{value}</h4>;
  }

  renderControl(meta) {
	let {type} = meta;

	switch (type) {

	  case 'checkbox':
		return this.renderCheckbox(meta);

	  case 'dropdown':
		return this.renderDropdownField(meta);
	  case 'search_dropdown':
		return <SearchDropdown {...this.props} {...this.getFieldProps(meta)} options={this.getOptionsForMeta(meta)}
							   meta={meta}/>;

	  case 'textarea':
		return this.renderTextArea(meta);

	  case 'text':
	  case 'number':
	  case 'password':
	  case 'email':
	  case 'phone':
		return this.renderBasicField(meta);

	  case 'date':
		return this.renderDateField(meta);

	  case 'file':
		return this.renderFileField(meta);
	  case 'label':
		return this.renderLabel(meta);
	  default:
		return this.renderCustomField(meta);
	}
  }

  renderField(meta) {


	let {errorBody} = this.state;

	let valError = null;
	if (errorBody && errorBody.validationMap) {

	  let vMeta = errorBody.validationMap[meta.name];

	  if (vMeta) {

		valError = <div style={{
		  color: 'red',
		  fontSize: 10,
		  margin: '-15px 0px 10px 2px'
		}}>{vMeta.message}</div>;

	  }


	}

	const renderedField = <div key={`${meta.name}-${meta.title}`} style={{margin: '10px 0'}}>
	  {this.renderControl(meta)}
	  {valError}
	</div>;

	return meta.expandable ?
	  <ExpandableV2 titleRow={getLocalString(this.props, meta.title)}>
		{renderedField}
	  </ExpandableV2> : renderedField;

  }

  renderFields() {
	let {fieldsList} = this.getRenderedFields();
	return fieldsList;
  }

  renderFileField(meta) {

	let props = this.getFieldProps(meta);

	return <Form.Input {...props} onChange={this.onFileChange.bind(this)} type={'file'}/>
  }


  getSubmitButtonProps() {
	return {
	  color: 'teal',
	  content: getLocalString(this.props, 'submit'),
	  icon: 'save',
	  loading: this._isSubmitting,
	  disabled: !this._isEnabled
	}
  }

  getFormProps() {
	return {
	  onSubmit: this.onSubmit.bind(this),
	  loading: this._isLoading
	}
  }

  renderFormFooter() {
	return <Button
	  {...this.getSubmitButtonProps()}
	/>
  }

  /**
   * Renders renderHeader, uses getTitle() to get a value for the renderHeader
   * @returns {*}
   */
  renderFormHeader() {
	return null;
  };

  renderPreForm() {
	let {_title} = this;
	return <Header>{_title}</Header>
  }

  renderPostForm() {
	return null;
  }

  renderTextArea(meta) {

	let props = this.getFieldProps(meta);
	let {label} = props;
	return <Form.Field key={meta.name}>
	  <label>{label}</label>
	  <TextArea {...props} />
	</Form.Field>;
  }

  renderForm() {
	return <Segment basic padded>
	  {this.renderPreForm()}
	  {this.renderFormBody()}
	  {this.renderPostForm()}
	</Segment>
  }


  renderFormBody() {
	return <Form
	  {...this.getFormProps()}
	>
	  {this.renderFormHeader()}
	  {this.renderErrorsView()}
	  {this.renderFields()}
	  {this.renderFormFooter()}
	</Form>
  }


  /**
   * Since it's a top-level UI component, it will override
   * AppComponent's renderWithVerification
   * @returns {*}
   */
  renderWithVerification() {
	return this.renderForm();
  }

}


export const GenericFormPrototype = withCheckboxButton(withFroala(FormPrototype));
