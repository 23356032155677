import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import {FROALA_KEY} from "../../../form/components/froala_editor/proto";

export const withFroalaView = (sc) => class extends sc {


    getCellContent(p){
        let {meta, item, key} = p;
        switch (meta.type){
            case 'froala_view':
                return <FroalaEditorView model={item[key]} config={{key: FROALA_KEY}}/>
            default:
                return super.getCellContent(p)
        }
    }

};