import React from 'react';
import {WithRouterLayout} from "../../utils/proto/layouts/router";
import {GenericComponent} from "../../utils/proto/general";
import {AdminProjectTypeCreateForm} from "./create";
import {AdminProjectTypeList} from "./list";
import {AdminProjectTypeEditForm} from "./edit";


export default class AdminProjectTypeView extends WithRouterLayout(GenericComponent){



    prefix = '/admin/project_types';
    routesMeta = {
        '/create': {Component: AdminProjectTypeCreateForm},
        '/:id' : {Component: AdminProjectTypeEditForm},
        '*': {Component: AdminProjectTypeList}
    }

}