import React from 'react'
import {MenuListPrototype} from "../../utils/components/collections/list/menu";
import {CollectionWithStore} from "../../utils/components/collections/mixins";
import {WithExtras} from "../../utils/proto/mixins/withExtras";
import {WithApi} from "../../utils/proto/mixins/api";

class CompanyMenu extends WithExtras(WithApi(MenuListPrototype), ['company']) {

    getRouteToCreate = () => `/admin/companies/create`;
    getRouteToItem = ({item}) => `/admin/companies/${item.id}`;

}

export const AdminCompanyMenu = CollectionWithStore(CompanyMenu, 'company');