import React from 'react';
import {ControlForm} from "../form";

import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {GenericComponent} from "../../../../utils/proto/general";

import {Grid, Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {AuditsAndTasksView} from "./audits_and_tasks";
import {NavBackTopLeftButton} from "../../../../utils/components/button/nav";
import {ExpandableV2} from "../../../../utils/components/expandable";

class ControlUpdateForm extends ControlForm(BasicUpdateForm('control')){


    getFormMeta() {
        const superMeta =  super.getFormMeta();

        //superMeta[4].expandable = true;


        return superMeta;
    }



    renderFields(){

        const {fieldsMap} = this.getRenderedFields();



        return <div>

            <NavBackTopLeftButton rightContent={fieldsMap.is_starred}/>
            <h1>{getLocalString(this.props, this.state.is_opportunity ? 'edit_opportunity' : 'edit_control')} ({this.state.acronym})</h1>

            <Grid>

                <Grid.Column mobile={16} tablet={8} computer={10}>
                    {fieldsMap.control_area_id}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={8} computer={6}>
                    {fieldsMap.is_present}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16}>
                    {fieldsMap.description}
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16}>
                    <ExpandableV2 titleRow={getLocalString(this.props, 'more')}>
                        {fieldsMap.motivation}
                    </ExpandableV2>

                </Grid.Column>

            </Grid>
        </div>


    }


}


export class ControlUpdateView extends GenericComponent{



    renderWithVerification(){

        return <Segment>

            <ControlUpdateForm {...this.props} />

            <AuditsAndTasksView {...this.props} />

        </Segment>
    }

}


