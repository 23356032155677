import React from 'react';

import {auditTypesMeta} from "../list";
import {Icon} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";

export const AuditTypeSelector = (props) => {

    let {value, onChange, name, label} = props;


    let buttons = auditTypesMeta.map((meta, index)=>{

        let {title, abbreviation, icon, color} = meta;

        let isSelected = Number(index) === Number(value);

        return <div key={index} style={{
            padding: '10px 20px',
            border: 'solid 1px',
            borderColor: isSelected ? 'rgb(159, 198, 220)' : '#ccc',
            margin: 1,
            textAlign: 'center',
            fontSize: 20,
            borderRadius: 5,
            cursor: 'pointer',
            backgroundColor: isSelected ? 'rgb(219, 240, 255)' : '#f7f7f7',
            transitionDuration: '300ms'
        }}

            onClick={(e)=>{e.preventDefault();onChange(e, {name, value: index})}}

        >

            <Icon size={'large'} name={icon} color={color}/>
            <div style={{textTransform: 'uppercase'}}>{abbreviation}</div>
            <div style={{fontSize: 13, color: 'grey', marginTop: 5}}>{getLocalString(props, title)}</div>
        </div>



    });

    return <div>
        <h4>{label}</h4>
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'row'
        }}>
            {buttons}
        </div>
    </div>


};