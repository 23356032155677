import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicCreateForm} from "../../utils/proto/mixins/form/create";

class FormProto extends BasicCreateForm('project_type') {


    title = () => getLocalString(this.props, 'edit_user_form');

    getFormMeta() {
        return [
            {name: 'name', title: 'name', type: 'text'},
            {name: 'business_menu', title: 'is_a_business_project', type: 'checkbox'},
            {name: 'consulting_menu', title: 'is_a_consulting_project', type: 'checkbox'},
            {name: 'acronym', title: 'acronym', type: 'text'}
        ]
    }


}


export const AdminProjectTypeCreateForm = (props) => <FormProto {...props} storeName={'project_type'}/>;