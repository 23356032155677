import React from 'react';
import {Divider, Grid, Header, Icon, Statistic} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {purl} from "../../../utils/proto/general";
import {FlexRow} from "../../../utils/components/flexRow";
import {NavLink} from "react-router-dom";

// {label: 'high_risks', value: riskBuckets[2], isRed: riskBuckets[2] === 0, to: purl(this.props, '/risks')},
export const Stat = ({label,icon,  value, isRed, to, ...props}) => {

    return <FlexRow justify={'center'}><Statistic>
            <Statistic.Value style={{color: isRed ? 'red' : undefined}}>{value}</Statistic.Value>
            <Statistic.Label>
                {icon ? <Icon {...icon} />: null}
                <NavLink to={to}>{getLocalString(props, label)}</NavLink>
            </Statistic.Label>
    </Statistic></FlexRow>

};

export const StatsGroup = ({ title, icon, stats, ...props}) =>{


    const statsView = stats.map( (meta) => <Stat {...props} {...meta} />);
    const gridItems = statsView.map((item, key) => <Grid.Column key={key} mobile={16} tablet={8} computer={4}>{item}</Grid.Column>);


    return <div>


        <Divider horizontal={!!title}>

            {!!title && (<Header as='h4'>
                <Icon name='bar chart'/>
                {getLocalString(props, title)}
            </Header>)}

        </Divider>

        <Grid>
            {gridItems}
        </Grid>



    </div>
} ;

export const DashboardStats = ({
    dashboard,
    ...props
})=>{

    if (!dashboard) return null;

    const {   presentControls,
        riskBuckets,
        opportunitiesBuckets,
        futureKpis,
        ncAudits,
        incompleteTasks,

        //
        reports = [],
        futureAudits = []
    } = dashboard;

    // Tasks to do - Back
    // Future KPIs (incomplete ones) - Back
    // Present Controls - Back
    // NC Audits - Back
    // High Risks - Red Arrow up
    // Low Opportunities - Red Arrow Down

    const statsMeta = [
      	{label: 'high_risks', value: riskBuckets[2], isRed: riskBuckets[2] > 0, to: purl(props, '/risks')},
      	{label: 'present_controls', value: presentControls, isRed: presentControls === 0, to: purl(props, '/controls')},
        {label: 'future_tasks', value: incompleteTasks.length, isRed: incompleteTasks.length === 0, to: purl(props, '/tasks')},
        {label: 'nc_audits', value: ncAudits.length, isRed: ncAudits.length > 0, to: purl(props, '/audits')},
        {label: 'future_audits', value: futureAudits.length, isRed: futureAudits.length === 0, to: purl(props, '/audits/future')}
    ];

    return <div style={{marginTop: 50}}>
        <StatsGroup {...props} stats={statsMeta} />
    </div>
};