import React from 'react';
import {FroalaBasicUpdateForm} from "./basic";
import {Message, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";


export const FroalaEditView = (props) => {

    let id = props.match.params.id;
    if (!id) return <div>No id</div>;

    let page = props.state.project_froala_page.items[id];
    if (!page) return <div>No page</div>;





    return <Segment basic>
        <FroalaBasicUpdateForm {...props} />
    </Segment>


};