import React from 'react';
import {AuditListWithinType} from "./list";
import {FlexRow} from "../../../utils/components/flexRow";
import {Button, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {routeOnClick} from "../../../../utils/routeTo";
import {purl} from "../../../utils/proto/general";

export const AuditsFutureList = (props) => {


    return <div>

        <FlexRow justify={'space-between'}>

            <div>

                <Button
                    onClick={routeOnClick(props.history, purl(props, '/audits'))}
                    color={'blue'}
                    content={getLocalString(props, 'go_back')}
                    icon={'arrow left'}
                />

                <Button
                    onClick={()=>{props.history.push(purl(props, '/audits/create?type=3'))}}
                    color={'green'}
                    content={getLocalString(props, 'plan_an_audit')}
                    icon={'plus'}
                />
            </div>




        </FlexRow>

        <AuditListWithinType type={3} {...props} />

    </div>
};