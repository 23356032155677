import React from 'react';

import {Button} from 'semantic-ui-react';
import {getLocalString} from "../../../../../../utils/localString";
import {FlexRow} from "../../../flexRow";
/**
 * @mixin
 * @param sc
 * @returns {{new(): {}, prototype: {}}}
 */
export const WithStarFilter = (sc) => class extends sc {


    state = {
        ...super.state || {},
        only_starred: false
    };



    onStarClicked(e){
        let {only_starred} = this.state;
        e.preventDefault();

        this.setState({only_starred: !only_starred});
    }



    getStarredData(data){
        let {only_starred} = this.state;
        if (!only_starred) return data;


        return data.filter(x=>x.is_starred);
    }

    getData(){

        let data = super.getData();

        return this.getStarredData(data);


    }


    renderStarButton(){
        let {only_starred} = this.state;
        return <FlexRow justify={'space-between'}>

            <div></div>

            <div>
                <Button
                    icon={'star'}
                    content={getLocalString(this.props, 'show_starred_only')}
                    color={only_starred ? 'blue' : undefined}
                    className={'hide-on-print'}
                    onClick={this.onStarClicked.bind(this)}
                />
            </div>


        </FlexRow>
    }

    renderWithVerification(){

        return <div>
            {this.renderStarButton()}

            {super.renderWithVerification()}


        </div>

    };
};