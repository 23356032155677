import {getCurrentUser} from "../../../utils/sessionHelper";

export const getCompaniesForUserFromRelations = (props, relations) => {



    const userRelations = getUserCompanyRelationsForUser(props, relations);


    const companiesMap = {};

    userRelations.forEach(rel =>{
        let {company_id} = rel;
        let company = props.state.company.items[company_id];

        if (company) companiesMap[company.id] = company;
    });

    return Object.values(companiesMap);



};


const getUserCompanyRelationsForUser = (props, relations)=>{

    if (!relations) relations = Object.values(props.state.user_company_relation.items);

    const user = getCurrentUser(props);
    const userRelations = relations.filter(rel => rel.user_id == user.id);

    return userRelations;
};


/**
 * Returns a frist company if none is selected
 * @param props
 */
export const getDefaultCompany = (props) => {

    const companies = getCompaniesForUserFromRelations(props);
    const nonNullCompanies = companies.filter(x=>!!x);

    return nonNullCompanies.length && nonNullCompanies[0] || undefined;

};


export const getDefaultCompanyId = (props) => {

    const relations = getUserCompanyRelationsForUser(props);


    return relations.length && relations[0].company_id || undefined;
};
