import React from 'react';
import {Button, List, Segment} from 'semantic-ui-react';
import {AppComponent, GenericComponent} from "../../utils/proto/general";
import {getFromStoreById} from "../../../utils/getFromStore";
import {getLocalString} from "../../../utils/localString";

import {getCurrentCompanyId} from "../../../utils/sessionHelper";
import {BasicCreateForm} from "../../utils/proto/mixins/form/create";
import {WithStoreList} from "../../utils/proto/mixins/withList";


class UserRelationList extends WithStoreList(GenericComponent, 'user_company_relation'){


    get company_id(){
        return this.props.match.params.id || getCurrentCompanyId(this.props) || this.props.company_id;
    }


    get items(){

        // Return items for this company
        let {list} = this.props.state.user_company_relation;

        if (!list) list = [];

        list = list.filter(x=>Number(x.company_id) === Number(this.company_id));

        return list;
    }





    renderItem(map){
        let {user, rel, index} = map;
        return <List.Item key={rel.id}>
            <List.Content floated={'right'}>
                <Button
                    content={getLocalString(this.props, 'delete')}
                    color={'red'}
                    size={'tiny'}
                    loading={this.isRemovingItem(rel)}
                    onClick={()=>{this.removeItem(rel, index)}}
                />
            </List.Content>
            <List.Content verticalAlign='middle'>
                <List.Header>{user.first_name} {user.last_name}</List.Header>
                {user.email}
                </List.Content>
        </List.Item>


    }

    renderItems(){


        let {items, company_id} = this;
        let {userType} = this.props;

        let maps = [];

        items.forEach((rel, index)=>{
            let user = getFromStoreById(this.props, 'user', rel.user_id);

            let includeRelation = rel.access_level === userType;
            if (company_id) includeRelation = includeRelation && (rel.company_id == company_id)

            if (includeRelation) maps.push({rel, user, index});
        });

        return maps.map(map=>this.renderItem(map));
    }


    renderWithVerification(){
        return <List>
            {this.renderItems()}
        </List>;
    }



}

class CreateRelation extends BasicCreateForm('user_company_relation'){

    getFormMeta(){
        return [
            {name: 'email', title:'invite_by_email', type: 'email'}
        ]
    }




    prepareItemForSubmission(item) {

        let {company_id, id} = this.props.match.params;
        let propsId = this.props.company_id;

        super.prepareItemForSubmission(item);
        item.access_level = this.props.userType;
        item.company_url_id = company_id || id || propsId;
    }


    renderComplete(){

        let {onGoBack} = this.props;

        return <Segment textAlign={'center'}>
            <Button
                color={'green'}
                icon={'check'}
                content={getLocalString(this.props, 'invite_sent')}
                onClick={()=>{
                    this.setIncomplete();
                    onGoBack();}}
            />
        </Segment>
    }

}

export class CompanyAccessList extends AppComponent {


    state = {
        creating: false,
    };


    renderWithList(){
        return <Segment>
            <UserRelationList {...this.props}/>
            <Button fluid
                color={'blue'}
                icon={'plus'}
                onClick={()=>{this.setState({creating: true})}}
                content={getLocalString(this.props, 'invite_new_person')}
            />
        </Segment>
    }

    renderWithCreate(){
        return <Segment>
            <CreateRelation {...this.props} onGoBack={()=>{this.setState({creating: false})}}/>
        </Segment>
    }




    renderWithVerification(){

        let {creating} = this.state;

        if (creating) return this.renderWithCreate();
        else return this.renderWithList();
    }

}