


export const getRisksForProblemId = (props, problem_id) => {

    const {risk_problem_relation} = props.state;

    const getRiskForRelation = relation => props.state.risk.items[relation.risk_id];

    const risks = Object.values(risk_problem_relation.items)
        .filter(x=>x.problem_id == problem_id)
        .map(getRiskForRelation).filter(x=>!!x);


    return risks || [];
};