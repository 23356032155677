import React from 'react';
import {Loader} from 'semantic-ui-react';
import {fetchApi} from "../../../../mvvm/api/fetch";
import {getLocalString} from "../../../../utils/localString";
import {action_update_global} from "../../../../mvvm/reducers/global";
import {getCurrentCompanyId, getCurrentProjectId} from "../../../../utils/sessionHelper";


export const WithApi = (sc) => class extends sc{

    get loaderContent(){
        return getLocalString(this.props, 'loading');
    }

    state = {
        ...(super.state || {}),
        loading: false,
        error: null,

        payload: null,
    };

    performGlobalUpdate = (body) => {
        this.props.dispatch({type: action_update_global, data: body.data});
    };


    beforeRequest = (state)=>{
        this.setState({...state || {}, loading: true});
    };

    onRequestComplete = (body, options) => {


        // console.log('reqComplete');
        // console.log(body);
        let {success} = body;

        let {onErrorResponse=this.onErrorResponse,
             onSuccessResponse=this.onSuccessResponse,

        } = options;

        if (success){
            return onSuccessResponse(body, options);
        } else {
            return onErrorResponse(body, options);
        }


    };


    onSuccessResponse({payload}){
        // console.log('proto onSuccessResponse');
        return {payload};
    }

    onErrorResponse(body){
        console.error(`Request Error`);
        console.error(body);
        return {error: true, errorBody: body}
    }



    onRequestSuccess = (resp, options={}) => {


        let {
            onRequestComplete=this.onRequestComplete,
            performGlobalUpdate=this.performGlobalUpdate,
        } = options;


        //console.log(resp);
        if (resp.statusCode === 200){
            let {body} = resp;
            let stateUpdates = onRequestComplete(body, options);

            if (body.success) performGlobalUpdate(body, options);

            if (stateUpdates) return {loading: false, error: null, ...stateUpdates};
            else return null;
        } else {
            return {error: `${getLocalString(this.props, `request_error_status_code`)} ${resp.statusCode}`};
            //console.error(resp);
        }
    };

    onRequestFailed = (error) => {
        return {error: getLocalString(this.props, 'server_error')};
        //console.error(error);
    };


    afterRequest = (state) => {
        if (state){
            this.setState({...state || {}, loading: false});
        }
    };

    api(url, data, options = {}){

        let {
            beforeRequest=this.beforeRequest,
            onRequestSuccess=this.onRequestSuccess,
            onRequestFailed=this.onRequestFailed,
            afterRequest=this.afterRequest
        } = options;

        beforeRequest();


        if (!data) data = {};

        const project_id = getCurrentProjectId(this.props);
        const company_id = getCurrentCompanyId(this.props);

        if (data.project_id === undefined && project_id) data.project_id = project_id;
        if (data.company_id === undefined && company_id) data.company_id = company_id;



        let request = fetchApi(url, data, options);

        const handlerPromise = new Promise((resolve)=>{

                return resolve(request
                    .then((resp)=>onRequestSuccess(resp, options))
                    .catch((resp)=>onRequestFailed(resp, options)))
        });


        handlerPromise.then(state=>{afterRequest(state, options)});

    };



    renderWithLoading(){
        return <Loader active={true} content={this.loaderContent} />
    };

    renderAppComponent(){
        let {loading} = this.state;
        return loading ? this.renderWithLoading() : super.renderAppComponent();
    }

};

