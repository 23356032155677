import React from 'react';
import {goToKpiReportItem} from "../preview";
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";

export const MonitoringReportNavSlider = (props) => {

    const {next_item, previous_item, next_incomplete, item, onEndReport, onDeleteReport, report} = props;

    return <div style={{
        display: 'flex',
        justifyContent: 'space-between'
    }}>

        <div>

            {/*report.is_complete && <Button
                content={getLocalString(props, 'done')}
                color={'teal'}
                onClick={onEndReport}
                icon={'check'}
            />*/}

            <Button
                content={getLocalString(props, 'cancel_report')}
                color={'red'}
                onClick={onDeleteReport}
                icon={'cancel'}
            />


        </div>


    </div>
};