import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {ControlCreateFormWithRelations} from "./create";
import {ControlUpdateView} from "./update/index";
import {ControlList} from "./list";


import {WithExtras} from "../../../utils/proto/mixins/withExtras";

class Router extends WithExtras(WithRouterLayout(GenericComponent), ['control', 'risk_category', 'control_area', 'risk_control_relation']){


    prefix = this.purl('/controls');

    routesMeta = {
        '/create': {Component: ControlCreateFormWithRelations},
        '/:id': {Component: ControlUpdateView},
        '*': {Component: ControlList},
    };

}



export default Router;