import React from 'react';
import {BasicUpdateForm} from "../../../../../utils/proto/mixins/form/update";
import {Grid} from "semantic-ui-react";

export class UpdateItemForm extends BasicUpdateForm(
    'kpi_report_item',
    undefined,
    p=>p.match.params.kpi_report_id){


    getFormMeta(){

        return [
            {name: 'result', title: 'result', type: 'number'},
            {name: 'date', title: 'on_date', type: 'date'},
            {name: 'motivation', title: 'motivation', type: 'froala_field'}

        ]

    }


    onSuccessResponse = (body, options) => {
       if (this.props.onKpiSubmitted) this.props.onKpiSubmitted();
    };



    prepareItemForSubmission(item){

        super.prepareItemForSubmission && super.prepareItemForSubmission(item);
        item.is_complete = true;
    }

    renderFields(){
        const {fieldsList} = this.getRenderedFields();


        return <Grid>

            <Grid.Row>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    {fieldsList[0]}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={8} computer={4}>
                    {fieldsList[1]}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    {fieldsList[2]}
                </Grid.Column>
            </Grid.Row>

        </Grid>


    }

}
