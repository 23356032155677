import React from 'react';
import {isProjectOwner, isSuperUser} from "../secondary";
import {getCurrentProject} from "../../../../../utils/sessionHelper";
import {SidebarPrimaryMenu as Menu} from "../../../../utils/proto/layouts/sidebar/primary";
import {GenericComponent} from "../../../../utils/proto/general";
import {getLocalString} from "../../../../../utils/localString";



class PublishButton extends GenericComponent {


    onClick = e => {
        e && e.preventDefault && e.preventDefault();

        if (!window.confirm(getLocalString(this.props, 'confirm_template_status_change'))){
            return;
        }

        const {project, template_state} = this.props;

        this.api('/api/project/update', {id: project.id, template_state})
    };


    renderWithVerification(){

        const {hint, icon} = this.props;
        return <Menu.Item
            icon={icon}
            hint={hint}
            onClick={this.onClick}
        />
    }
}

export const TemplateStateToggle = (props) => {

    const project = getCurrentProject(props);
    const isOwner = isProjectOwner(props,project);
    const isSU = isSuperUser(props);


    if (! (isOwner && project && project.is_template)) return null;


    const open = <PublishButton
        {...props}
        project={project}
        template_state={0}
        hint={getLocalString(props, 'open_template')}
        className={project.template_state == 0 ? ' active' : ''}
        icon={'arrow left'}
        key={'open'}
    />;

    const close = <PublishButton
        {...props}
        project={project}
        template_state={1}
        className={project.template_state == 1 ? ' active' : ''}
        hint={getLocalString(props, 'close_template')}
        icon={'arrow right'}
        key={'close'}
    />;

    const publish = <PublishButton
        {...props}
        project={project}
        template_state={2}
        className={project.template_state == 2 ? ' active' : ''}
        hint={getLocalString(props, 'publish_template_to_marketplace')}
        icon={'world'}
        key={'shopping bag'}
    />;



    /* SU can */
    if (isSU){
        return [open, close, publish];
    }


    /* Only Project owner can switch Open/Close */


        const {template_state} = this.props;


        switch(template_state){
            case 0:
                return close;
            case 1:
                return publish;
            case 2:
                return open;
        }



    return null;

};