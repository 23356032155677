import React from "react";
import {Button} from "semantic-ui-react";

export const withCheckboxButton = (sc) => class extends sc {



    renderCheckboxButton(meta, props){

        let {icon, name} = meta;
        let {value, label} = props;


        return <Button
            content={label}
            icon={icon}
            basic={!value}
            color={value && 'blue' || undefined}
            onClick={(e)=>{e.preventDefault();props.onChange(e, {name, value: !value})}}

        />


    }

    renderCustomField(meta) {
        let {type} = meta;

        let fieldProps = this.getFieldProps(meta);



        switch (type){
            case 'checkbox_button':
                return this.renderCheckboxButton(meta, fieldProps);

            default:
                return super.renderCustomField(meta);
        }

    }

};
