import React from 'react';
import {Segment} from "semantic-ui-react";
import {CometChat} from "./chat";

export default class WhiteBoardView extends React.Component{



    render(){

        let {props} = this;

        return <Segment basic>
                <CometChat {...props} />
        </Segment>
    }


}