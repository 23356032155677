

import React from 'react';
import {Link} from "react-router-dom";

export const withNavLink = (sc) => class extends sc {


    getCellContent(p){
        let {meta, item, key} = p;




        switch (meta.type){
            case 'nav_link':
                const {getLink, getText} = meta;
                const val = getText ? getText(item) : item[key];
                if (getLink){
                    return <Link to={getLink(item)}>{val}</Link>
                } else {
                    return {val};
                }


            default:
                return super.getCellContent(p)
        }
    }

};