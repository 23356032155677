import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {KPICreateForm} from "./create";
import {KPIUpdateForm} from "./update";


class Router extends WithExtras(WithRouterLayout(GenericComponent), ['kpi', 'problem', 'user_project_relation']){


    prefix=this.purl('/kpis');


    routesMeta = {
        '/create': {Component: KPICreateForm},
        '/:id': {Component: KPIUpdateForm},
        '*': {Component: ()=>'List'},
    }

}

export default Router;