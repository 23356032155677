import React from 'react';
import {AuditForm} from "./form";
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {getDefaultFromQuery} from "../../../../utils/path";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";



const searchParams = () => {

    // Remove question mark
    const {search} = window.location;
    const sanSearch = search.substr(1, search.length-1);
    const pairs = sanSearch.split(`&`);

    //
    const map = {};

    pairs.forEach(x=>{
        const [key, val] = x.split('=');
        map[key] = val;
    });


    return map;

};




export const controlIdFromQuery = ()=>{

    const {control_id, multiple, controls} = searchParams();

    if (multiple && multiple==='true'){

        const controlIds = controls.split(',');
        if (controlIds.length){
            return Number(controlIds[0]);
        }

    }

    return  control_id && Number(control_id) || undefined;
};

export class AuditCreateForm extends AuditForm(BasicCreateForm('audit')){



    state = {

        ...super.state,
        control_id: controlIdFromQuery(),
        type: getDefaultFromQuery('type', 'number')
    };



    renderComplete(){

        const {multiple, controls, control_id} = searchParams();


        if (multiple && multiple==='true'){

            const controlIds = controls.split(',');


            if (controlIds.length){


                const firstId = Number(controlIds[0]);
                const payloadControlId = Number(this.state.payload.control_id);
                if (payloadControlId === firstId){

                    controlIds.splice(0,1);
                    if (controlIds.length){

                        this.setState({control_id: Number(controlIds[0])});

                        this.props.history.push(this.purl(`/audits/create?multiple=true&controls=${controlIds.join(',')}`));
                    } else {
                        control_id ?
                            this.props.history.push(this.purl(`/controls/${control_id}`)) :
                            this.props.history.push(this.purl(`/audits/`))
                    }
                }
            }

        }

        return super.renderComplete();
    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);

        if (item.type === undefined || item.type === null) item.type = 0;

    }


    renderWithVerification(){

        return <div>
            <NavBackTopLeftButton />

            {super.renderWithVerification()}
        </div>
    }

}
