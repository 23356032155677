import React from 'react';


/**
 * Because it's not a form, there is no static getStoreName
 * @param sc Superclass
 * @param storeName Name of the store for the collection
 */
export const CollectionWithStore = (sc, storeName) => class extends sc {

    getItems() {
        // Get the store form props
        if (!this.props.state) throw `CollectionWithStore (${this.constructor.name}) has no state`;

        let store = this.props.state[storeName];
        if (!store) throw `No such store ${storeName}`;

        return store.list;
    }

    isActiveItem({item}) {

        let {id} = this.props.match.params;
        return Number(id) === Number(item.id);

    }

    renderItemContent({item}) {
        //console.log(item);
        return item.value || item.name || item.title || item.label || (item.first_name && `${item.first_name} ${item.last_name}`)
    }
};