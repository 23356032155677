import {getCurrentUser} from "./sessionHelper";

// Two maps that will be used for 'cached' access
let localizations = {};
let currentStateIds = {};


global.loc_history = {};

export function getCurrentMap(props) {

    let currentUser = getCurrentUser(props);
    let {items: localesMap} = props.state.locale;
    let locales = Object.values(localesMap);

    let {entriesByLocale: maps} = props.state.locale_entry;

    // If user is linked to a locale, get that
    let currentLocale;

    // If we have a user with locale, try to use that locale
    if (currentUser && currentUser.locale_id) {
        currentLocale = localesMap[currentUser.locale_id];

    // If we don't have user, select default locale
    } else if (locales && locales.length) {
        currentLocale = locales[0];

    // If we don't have locales at all, return nothing
    } else {
        currentLocale = null;
    }


    return maps ? currentLocale ? maps[currentLocale.id] : null : null;
}


function rasterizeKey(key){
    let spaced = (key && key.replace(new RegExp('_', 'g'), ' ')) || 'No key';

    return spaced[0].toUpperCase() + spaced.slice(1);
}

export function getLocalString(props, key) {

    let rastKey = rasterizeKey(key)

    // Silent fails if there is no props or state
    if (!props) return rastKey;
    if (!props.state) return rastKey;

    let localizations = getCurrentMap(props) || {};

    let local = localizations[key];

    return local && local.value || rastKey;
}
