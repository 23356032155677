import React from 'react';
import {ProblemForm} from "./form";
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {purl} from "../../../utils/proto/general";
import {ProblemsKPIList} from "./kpi/kpi";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";


export class ProblemUpdateForm extends ProblemForm(BasicUpdateForm('problem')){



    renderWithVerification(){

        const {id} = this.state;

        return <div>
            <NavBackTopLeftButton />
            {super.renderWithVerification()}

            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <div>
                    <Button
                        content={getLocalString(this.props, 'create_kpi')}
                        icon={'tags'}
                        color={'green'}
                        onClick={()=>{

                            const path = id ? `/kpis/create?problem_id=${id}` : `/kpi/create`;
                            this.props.history.push(purl(this.props, path));
                        }}
                    />
                </div>
            </div>
            <div>

            </div>

        </div>
    };
}
