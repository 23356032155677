import {TableWithStoreList} from "../../utils/components/collections/table/store";

export class AdminProjectTypeList extends TableWithStoreList('project_type'){

    getMeta(){
        return {
            id: {type: 'text', title: 'ID'},
            name: {type: 'text'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }

    getLinkToItem = (item)=> `/admin/project_types/${item.id}`;
    getLinkToCreate = ()=> `/admin/project_types/create`;
}