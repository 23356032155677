import React from 'react';
import {ControlList} from "../control/list";
import {Button, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";


export class ImprovementsList extends ControlList {

    showPresent = false;



    renderReportButton(){
        return <Button
            color={'green'}
            content={getLocalString(this.props, 'report')}
            icon={'table'}

            onClick={()=>{
                this.props.history.push(this.purl(`/reports/improvements`));
            }}
        />
    }


    renderTitle(){
        return <h1>{getLocalString(this.props, 'improvements')}</h1>
    }

    renderReportButton(){
        return <Button
            color={'green'}
            content={getLocalString(this.props, 'report')}
            icon={'table'}

            onClick={()=>{
                this.props.history.push(this.purl(`/reports/improvements`));
            }}
        />
    }


}