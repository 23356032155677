import {WithSubmit} from "./submit";
import {WithApi} from "../api";
import {WithState} from "../redux";
import {GenericFormPrototype} from "../../../components/form/prototype";
import {action_add_to_global_list} from "../../../../../mvvm/reducers/global";


export const BasicCreateForm = (storeName, updateUrl=`/api/${storeName}/create`, protoClass=GenericFormPrototype) => class extends WithSubmit(WithApi(WithState(protoClass)), updateUrl){



    clearForm(){

        const emptyForm = {};

        Object.entries(this.getFormMeta()).forEach(([key, meta])=>{
            const {name=key} = meta;
            emptyForm[name] = this.getDefaultValueForField(meta);

        });


        this.setState(emptyForm);
    }


    onSuccessResponse = (body, options)=>{
        //this.props.dispatch({type: action_update_global, data});
        //this.props.dispatch({type: action_update_session, payload});
        let initState = {};
        if (super.onSuccessResponse) initState=super.onSuccessResponse(body, options);

        this.clearForm();

        let {payload} = body;

        this.props.dispatch({
            storeName,
            type: action_add_to_global_list,
            data: payload
        });


        return {...initState, errorBody: false, complete: true};
    };

};