import React from 'react';
import {getLocalString} from "../../utils/localString";
import {Link} from 'react-router-dom';
import {Button, Card, Image, Segment} from 'semantic-ui-react';

import {ReCaptcha} from 'react-recaptcha-v3';
import {SubmitForm} from "../utils/proto/mixins/form/submit";

const site_key = `6LeaMYsUAAAAAPvyjsJYspA3wBnuHDsqzlC_8DM7`;



const CaptchaV3 = (props) => {

    let {onChange, name} = props;

    const onCaptchaCallBack = (token) =>{
        //console.log(`ReCaptcha Token:  ${token}`);
        onChange(null, {name, value: token});
    };

    return <ReCaptcha
        sitekey={site_key}
        action='register'
        verifyCallback={onCaptchaCallBack}
    />
};

export class RegistrationForm extends SubmitForm(`/api/user/create`) {

    title = () => getLocalString(this.props, 'registration');


    state = {

        ...super.state,
        createCompany: true,
    };

    get defaultLocaleId() {

        let {items: map} = this.props.state.locale;
        let items =Object.values(map);

        return items.length && items[0].id || undefined;
    }

    get privacyTitle(){
        return <span>
            {getLocalString(this.props, 'accept')}&nbsp;
            <a href={'https://www.iubenda.com/privacy-policy/45847474'}>{getLocalString(this.props, 'privacy_terms')}</a>
        </span>
    }

    get termsTitle(){
        return <span>
            {getLocalString(this.props, 'accept')}&nbsp;
            <a href={'https://www.riskhub.it/condizioni'}> {getLocalString(this.props, 'terms_and_conditions')}</a>
        </span>
    }

    isEnabled(){
        let {accept_privacy, accept_terms} = this.state || {};

        return (accept_privacy && accept_terms) || false;
    }

    getFormMeta() {
        let defaultMeta = [
            {name: 'email', title: 'email', type: 'email'},
            {name: 'password', title: 'password', type: 'password'},
            {name: 'first_name', title: 'first_name', type: 'text'},
            {name: 'last_name', title: 'last_name', type: 'text'},
            // {
            //     name: 'locale_id',
            //     title: 'locale',
            //     type: 'dropdown',
            //     storeName: 'locale',
            //     textFieldName: 'display_name',
            //     allowNull: true,
            //     defaultValue: this.defaultLocaleId,
            // },
            {name: 'phone', title: 'phone', type: 'phone'},
            {name: 'captcha', title: 'captcha', type: 'captchav3'},

            {name: 'accept_privacy', title: this.privacyTitle, type: 'checkbox', localized: false},
            {name: 'accept_terms', title: this.termsTitle, type: 'checkbox', localized: false},
            {name: 'createCompany', title: 'do_you_want_to_create_a_company', type:'checkbox'},


        ];

        //
        if (this.state && this.state.createCompany) {
            defaultMeta = [...defaultMeta,
                {name: 'company_name', title: 'company_name', type: 'text'},
                {name: 'tin', title: 'company_reg_number', type: 'text'},
            ]
        }



        return defaultMeta;


    }





    prepareItemForSubmission(item){

        let {createCompany, company_name, tin} = item;
        if (item.createCompany){
            item.extra = {createCompany, company_name, tin};
        }

        if (this.defaultLocaleId){
            item.locale_id = this.defaultLocaleId;
        }

        super.prepareItemForSubmission(item);
    }

    renderForm() {

        let form = super.renderForm();

        return <Segment basic padded>

            <Image centered={true} size={'large'} src={'/api/logo.png'}/>
            <br/>
            <br/>
            <br/>
            <Card centered>
                <Card.Content>
                    {form}
                </Card.Content>
                <Card.Content>
                    <Segment basic textAlign={'center'}>
                        <div style={{fontSize: '10px'}}>
                        This site is protected by reCAPTCHA and the Google &nbsp;
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                        </div>
                    </Segment>
                </Card.Content>
            </Card>
        </Segment>;
    }

    renderFormFooter() {
        //
        // console.log(this._isEnabled);
        return <Button
            color={'green'}
            content={getLocalString(this.props, 'submit')}
            icon={'save'}
            fluid
            loading={this._isSubmitting}
            disabled={!this._isEnabled}
        />
    }

    renderPostForm() {
        return <div><br/><Link to={'/'}><Button
            color={'red'}
            icon={'cancel'}
            content={getLocalString(this.props, 'cancel')}
            fluid
        /></Link></div>;
    }

    renderComplete(){

        window.location = 'https://www.riskhub.it/grazie';
        return null;
    }

    renderCustomField(meta){

        let {type} = meta;

        let fieldProps = this.getFieldProps(meta);
        switch(type){
            case 'captchav3':
                return <CaptchaV3 {...this.props} {...fieldProps} />;
            default:
                return super.renderCustomField(meta);
        }
    }

}


