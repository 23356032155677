import {action_update_multiple_global_lists} from "../../../../mvvm/reducers/global";

export const WithExtras = (sc, extras=[]) => class extends sc{



    state = {
        ...super.state || {},
        ...this.getDefaultExtras(),

        loadingExtras: false
    };


    getDefaultExtras(){
        let map = {};

        extras.forEach(x=>{
           map[x] = {};
        });
    }


    listExtra = (name) => {

        return new Promise((resolve) => {
            this.api(`/api/${name}/list`, {}, {
                onSuccessResponse: (body) => { resolve(body.payload);return {};}
            });
        });
    };


    mapResult = (result, index, maps) => {
        let storeName = extras[index];

        maps[storeName] = result;
    };


    mapResults = (results) => {

        let maps = {};
        results.forEach((result, index)=>{this.mapResult(result, index, maps)});


        this.props.dispatch({type: action_update_multiple_global_lists, data: maps});


        this.setState({...maps, loading: false, loadingExtras: false});
    };

    loadExtras = ()=>{

        this.setState({loadingExtras: true});
        return Promise.all(extras.map(this.listExtra)).then(results=>{


            return this.mapResults(results)
        })
    }

    componentDidMount(){
        if (super.componentDidMount) super.componentDidMount();

        this.loadExtras();

    }


    renderAppComponent(){
        if (this.state.loadingExtras) return this.renderWithLoading();

        return super.renderAppComponent();
    }

};