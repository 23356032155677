import React from 'react';
import {Button, Icon} from "semantic-ui-react";


export class Expandable extends React.Component {

    state = {
        ...super.state || {},
        expanded: false,
    };


    toggleExpanded = (expanded)=>{
        if (expanded === undefined) expanded = !this.state.expanded;

        this.setState({expanded});
    };


    get collapseTitle(){
        return this.props.collapseTitle || 'Hide'
    }


    get expandTitle(){
        return this.props.expandTitle || `Show`;
    }



    componentDidMount() {
        if (this.props.expanded !== undefined){
            this.setState({expanded: this.props.expanded});
        }
    }


    renderExpanded(){
        return <div>

            <Button
                content={this.collapseTitle}
                fluid
                icon={'minus'}
                style={{margin: '20px 0'}}
                onClick={(e)=>{e && e.preventDefault && e.preventDefault();this.toggleExpanded()}}
            />
            {this.props.children}
        </div>
    }

    renderCollapsed(){

        return <div style={{margin: '20px 0'}}>
            <Button
                content={this.expandTitle}
                icon={'plus'}
                fluid
                onClick={(e)=>{e && e.preventDefault && e.preventDefault();this.toggleExpanded()}} />
        </div>

    }

    render(){


        let {expanded} = this.state;
        return expanded ? this.renderExpanded() : this.renderCollapsed()
    }
}



export class ExpandableV2 extends Expandable {



    get titleRow(){
        return this.props.titleRow || 'Title'
    }


    renderExpanded() {
        return <div className={'expandable-v2-container'}>

            <div
                className={'title-row'}
                onClick={(e)=>{e && e.preventDefault && e.preventDefault();this.toggleExpanded()}}
            >

                <Icon
                    name={'angle up'}
                />&nbsp;

                {this.titleRow}

            </div>

            {this.props.children}

        </div>
    }



    renderCollapsed(){

        return <div className={'expandable-v2-container collapsed'}>
            <div className={'title-row'}
                 onClick={(e)=>{e && e.preventDefault && e.preventDefault();this.toggleExpanded()}}>
                <Icon
                    name={'angle down'}
                    size={'small'}
                />&nbsp;

                {this.titleRow}

            </div>

        </div>

    }





}
