import React from 'react';


import {Button, Table} from 'semantic-ui-react';
import {getLocalString} from "../../../../../../utils/localString";
import {BasicCreateForm} from "../../../../../utils/proto/mixins/form/create";

class CreateActivityInlineForm extends BasicCreateForm('company_activity') {

    getFormMeta(){
        return [
            {type: 'text', name: 'name', title: 'name'}
        ]
    }
}


export const WithActivitiesMatrix = (sc) => class extends sc {

    horizontalStore = 'risk_category';
    relationStore = 'risk_activity_setup_config';





    renderHeaderColumnCellContent(args){

        let {item} = args;
        return item.name;
    }


    renderHeaderRowCellContent(args){
        let {item} = args;

        return item.name;
    }

    getHorizontalItems(){
        return this.props.state[this.horizontalStore].list;
    }

    getVerticalItems(){
        return this.props.state.company_activity.list;
    }



    renderCellContent = (args)=>{

        let {horizontalItem: hI, verticalItem: vI} = args;

        let store = this.props.state[this.relationStore];

        let {itemsByCompositeId} = store;
        if (!itemsByCompositeId) itemsByCompositeId = {};
        let relation = itemsByCompositeId[`${vI.id}_${hI.id}`];


        let buttonProps = {
          basic: true,
        };


        if (relation){
            buttonProps = {...buttonProps,

                color: 'red',
                onClick: (e) => {
                    e.preventDefault();

                    this.api(`/api/${this.relationStore}/remove`, relation);

                },
                content: getLocalString(this.props, 'disable')

            };
        } else {

            buttonProps = {...buttonProps,

                color: 'teal',
                onClick: (e)=>{
                    e.preventDefault();

                    let newRelation = {
                        company_activity_id: vI.id,
                        [`${this.horizontalStore}_id`]: hI.id
                    };

                    this.api(`/api/${this.relationStore}/create`, newRelation);
                },
                content: getLocalString(this.props, 'enable')

            }

        }

        return <Button {...buttonProps} />
    }


    renderFooterRow(){

        // Get horizontal items to estimate width of the row
        let hItems = this.getHorizontalItems();
        let size = hItems.length+1;


        return <Table.Row>

            <Table.Cell colSpan={size}>
                <CreateActivityInlineForm {...this.props} title={getLocalString(this.props, `create_company_activity`)}/>
            </Table.Cell>
        </Table.Row>

    }

};