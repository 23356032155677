import React from 'react';
import {SidebarPrimaryMenu as Menu} from "../../../utils/proto/layouts/sidebar/primary";
import {Image, Popup} from "semantic-ui-react";
import {curl, purl} from "../../../utils/proto/general";
import {getCurrentCompanyId, getCurrentProjectId} from "../../../../utils/sessionHelper";
import {getDefaultCompanyId} from "../../../../mvvm/reducers/utils/company";
import {getLocalString} from "../../../../utils/localString";


const logo = {
    src: '/api/logo_white_bgtransp.png',
    width: 1024,
    height: 464
};

const logo_divisor = 20;


const goto = (props, url) => () => {


    props.history.push(url);
};


export const SidebarPrimaryBasicItems = (props) => {

    const {width: lWidth, height: lHeight, src: lSrc} = logo;
    const {expanded, toggleSidebar} = props;

    const companyId = getCurrentCompanyId(props) || getDefaultCompanyId(props);
    const projectId = getCurrentProjectId(props);

    const goToCompany = goto(props, companyId ? `/companies/${companyId}/` : '/');


    const onHomeClick = projectId ?
            goto(props, purl(props, '/dashboard')):
            goToCompany;


    return [

        <Menu.Item
            key={'logo'}
            square

            onClick={goToCompany}
        >
        <Popup
            key={'logo'}
            content={getLocalString(props, 'company_home')}
            trigger={
                <Image
                    src={lSrc}
                    width={lWidth/logo_divisor}
                    height={lHeight/logo_divisor}
                />
           }
            size={'tiny'}
            position={'right center'}
        />
        </Menu.Item>
        ,

        <Menu.Item
            icon={'bars'}
            className={`${expanded ? 'active' : 'notactive'}`}
            onClick={toggleSidebar}
        />,

        projectId ? <Menu.Item
            key={'home'}
            icon={'home'}
            hint={getLocalString(props, 'dashboard')}
            onClick={onHomeClick}
        /> : null


    ]
};
