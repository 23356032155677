import React from 'react';
import {SidebarSecondaryMenu as Menu} from "../../../../utils/proto/layouts/sidebar/secondary";
import {getLocalString} from "../../../../../utils/localString";
import {ProjectsDropdown} from "../../../../shell/bar/projects";
import {getCurrentProject, getCurrentUser} from "../../../../../utils/sessionHelper";
import {SecondaryMenuFroalaList} from "./froala";
import {MenuProjectTaskList} from "./tasks";
import {purl} from "../../../../utils/proto/general";



export const isProjectOwner = (props, project, user) => {

    if (!project) project = getCurrentProject(props) || {id: 0};
    if (!user) user = getCurrentUser(props) || {id: 0};


    // Find the relation
    let relations = Object.values(props.state.user_project_relation.items).filter(rel =>{
        return (rel.project_id == project.id) && (rel.user_id == user.id);
    });

    return !!relations.length;

};


export const isSuperUser = (props, user) => {
    return true;
};




export const ProjectSidebarSecondaryMenu = (props) => {



    return <Menu expanded={props.expanded}>





        <Menu.Menu>

            <h4>{getLocalString(props, 'my_projects')}</h4>
            <ProjectsDropdown {...props} />

            <br/>
            <h4>{getLocalString(props, 'highlights')}</h4>


            <div className={'rh-sb-list'}>
                <div
                    className={'rh-sb-item'}
                    onClick={()=>{props.history.push(purl(props, '/audits'))}}

                >{getLocalString(props, 'audits')}</div>

                {/*<div*/}
                {/*    className={'rh-sb-item'}*/}
                {/*    onClick={()=>{props.history.push(purl(props, '/monitoring_reports'))}}*/}

                {/*>{getLocalString(props, 'monitoring')}</div>*/}

                <div
                    className={'rh-sb-item'}
                    onClick={()=>{props.history.push(purl(props, '/improvements'))}}

                >{getLocalString(props, 'improvements')}</div>


            </div>





            <h4>{getLocalString(props, 'pages')}</h4>
            <SecondaryMenuFroalaList {...props} />

        </Menu.Menu>


        <Menu.Menu>
        </Menu.Menu>

    </Menu>;

};
