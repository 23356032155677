import {GlobalStore} from "../reducers/global";
import {applyMiddleware} from 'redux';
import {transactionLogService} from "../middlewares/transactionLog";


import {LocaleReducer} from "../reducers/globalStores/locale_entry";
import {RiskControlRelationReducer} from "../reducers/globalStores/risk_control_reducer";
import {RiskProblemRelationReducer} from "../reducers/globalStores/risk_problem_relation";
import {ActivityRiskReducer} from "../reducers/globalStores/activity_risk_config";
import {ActivityControlReducer} from "../reducers/globalStores/activity_control_config"
import {SessionStore} from "../reducers/custom/session";
import {SubscriptionsStore} from "../reducers/custom/subscriptions";
import {AuditReducer} from "../reducers/globalStores/audit";

let reducers = {};
global.models = {};


const genericModels = {

    'audit': {reducerClass: AuditReducer},

    'user': {},
    'session': {reducerClass: SessionStore},
    'locale': {},
    'locale_entry': {reducerClass: LocaleReducer, multipleName: 'locale_entries'},
    'project': {},
    'company': {},
    'project_type': {},
    'user_type': {},

    'prc': {},

    'problem': {},
    'risk': {},
    'control': {},

    'problem_category': {multipleName: 'problem_categories'},
    'risk_category': {multipleName: 'risk_categories'},
    'control_area': {},

    'dashboard': {},

    'user_company_relation': {},
    'user_project_relation': {},

    'risk_control_relation' : {reducerClass: RiskControlRelationReducer},
    'risk_problem_relation' : {reducerClass: RiskProblemRelationReducer},


    'company_activity' : {multipleName: 'company_activities'},
    'risk_activity_setup_config' : {reducerClass: ActivityRiskReducer},
    'control_activity_setup_config' : {reducerClass: ActivityControlReducer},

    'template_project': {},

    'review': {},
    'review_item': {},

    'project_froala_page': {},
    'setup': {},
    'subscriptions': {reducerClass: SubscriptionsStore},

    'task': {},
    'kpi': {},
    'monitoring_report' : {},
    'kpi_report_item' : {},
};


function generateGenericModels() {
    for (let [singleName, model] of Object.entries(genericModels)) {

        let {name = singleName, multiple = name + `s`, reducerClass = GlobalStore} = model;

        // Generate generic service and reducer
         let reducer = new reducerClass({name, multiple});

        // Get proper functions
        reducers[name] = reducer.reducer;

        // Add it to global models. You may disable this in prod.
        global.models[name] = {reducer};
    }

}


// Call all the reducers
generateGenericModels();


export {reducers};
export const middlewares = applyMiddleware(transactionLogService);
