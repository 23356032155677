import React from 'react';

import {WithRouterLayout} from "../../utils/proto/layouts/router";

import {GenericComponent, purl} from "../../utils/proto/general";

import {Segment} from 'semantic-ui-react';
import {getCurrentProject} from "../../../utils/sessionHelper";
import {getLocalString} from "../../../utils/localString";

import {DashboardView} from "./dashboard";

import ProblemRouter from './problem';
import RiskRouter from './risk';
import ControlRouter from './control';
import TemplateRouter from './template';
import SetupRouter from './setup';
import AuditRouter from './audit';
import ProjectSettingsRouter from './settings';
import KPIRouter from './kpi';
import KPIReportsRouter from './monitoring'
import OpportunityRouter from './opportunity';


import ReportView from "./report";
import TasksView from './task';
import {FroalaRouter} from "./froala";
import {ImprovementsList} from "./improvements/list";

import {WithSidebarLayout} from "../../utils/proto/layouts/sidebar";
import {ProjectSidebarPrimaryMenu} from "./sidebar/main";
import {ProjectSidebarSecondaryMenu} from "./sidebar/secondary";
import WhiteBoardView from "./whiteboard";
import {FloatingCometChat} from "../../utils/components/cometchat";

class ProjectRouter extends WithRouterLayout(GenericComponent){

    prefix = () => purl(this.props, '');

    routesMeta = {


        '/risks': {Component: RiskRouter},
        '/audits': {Component: AuditRouter},
        '/kpis': {Component: KPIRouter},
        '/monitoring_reports': {Component: KPIReportsRouter},
        '/dashboard': {Component: DashboardView},
        '/problems': {Component: ProblemRouter},
        '/controls': {Component: ControlRouter},
        '/improvements': {Component: ImprovementsList},
        '/settings': {Component: ProjectSettingsRouter},
        '/reports': {Component: ReportView},
        // '/opportunities': {Component: OpportunityRouter},

        //'/prc': {Component: PrcCreateForm},

        //'/reviews': {Component: ReviewView},

        '/froala': {Component: FroalaRouter},
        '/templates': {Component: TemplateRouter},
        '/tasks': {Component: TasksView},
        '/whiteboard': {Component: WhiteBoardView},
        //'/company_activities': {Component:  CompanyActivityView},
        '*': {Component: DashboardView}
    };



    renderWithoutProject(){
        return <Segment textAlign={'center'} basic padded={'very'}>
            {getLocalString(this.props, 'no_project_selected')}
        </Segment>
    }


    renderWithSetup(){

        return <SetupRouter {...this.props} />
    }




    renderProject(){

        let project = getCurrentProject(this.props);




        if (project.is_template && project.template_state > 0){

            return <Segment basic padded>
                <h1>{getLocalString(this.props, 'closed_or_marketplace_item')}</h1>
                <span>{getLocalString(this.props, 'you_cannot_edit_closed_or_marketplace_item')}</span>
            </Segment>;

        }


        //return 'result';
        return <div>
            {super.renderRoute()}
        </div>;

    }


    renderWithVerification() {


        let project = getCurrentProject(this.props);
        if (!(project && project.id)) return this.renderWithoutProject();

        if (project.is_setting_up){
            return this.renderWithSetup();
        }

// <FloatingCometChat {...this.props} />
        return <div>
            {this.renderProject()}
        </div>


    }


}

const BoundProjectView = WithSidebarLayout(ProjectSidebarPrimaryMenu, ProjectSidebarSecondaryMenu,ProjectRouter);

export default BoundProjectView;
