import {GlobalStore} from "../global";

export class RiskProblemRelationReducer extends GlobalStore {

    get mapsMeta(){

        return {
            ...super.mapsMeta,
            itemsByRiskId: {path: ['risk_id', 'problem_id']},
            itemsByProblemId: {path: ['problem_id', 'risk_id']},
        };

    };

}