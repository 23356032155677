import React from 'react';
import {AppComponent} from "../../../utils/proto/general";
import {getLocalString} from "../../../../utils/localString";
import {Dropdown, Icon} from 'semantic-ui-react';


{/*<Dropdown item text={getLocalString(this.props, 'admin_menu')}>*/}
{/*    <Dropdown.Menu>*/}
{/*        <Dropdown.Item as={'a'} href={`/admin/users`}><Icon name={'user'} /> {getLocalString(this.props, 'users')}</Dropdown.Item>*/}
{/*        <Dropdown.Item as={'a'} href={`/admin/companies`}><Icon name={'building'} /> {getLocalString(this.props, 'companies')}</Dropdown.Item>*/}
{/*        <Dropdown.Item as={'a'} href={`/admin/localizations`}><Icon name={'universal access'} /> {getLocalString(this.props, 'localizations')}</Dropdown.Item>*/}
{/*        <Dropdown.Item as={'a'} href={`/admin/user_types`}><Icon name={'group'} /> {getLocalString(this.props, 'user_types')}</Dropdown.Item>*/}
{/*        <Dropdown.Item as={'a'} href={`/admin/project_types`}><Icon name={'copy'} /> {getLocalString(this.props, 'project_types')}</Dropdown.Item>*/}
{/*    </Dropdown.Menu>*/}
{/*</Dropdown>*/}



const options = [
    {href: `/admin/users`, iconName: `user`, key: `users`},
    {href: `/admin/companies`, iconName: `building`, key: `companies`},
    {href: `/admin/localizations`, iconName: `universal access`, key: `localizations`},
    {href: `/admin/user_types`, iconName: `group`, key: `user_types`},
    {href: `/admin/project_types`, iconName: `copy`, key: `project_types`},
];


    const navTo = (props, route) => ()=> {
        props.history.push(route);
    };

export class SidebarSecondaryAdmin extends AppComponent{



    get user(){

        let {session} = this.props.state;

        if (session && session.user){
            return session.user;
        }

        return null;
    }

    renderWithVerification() {

        let {user} = this;
        if (! (user && user.user_type_id === 1)) return null;




        return <div className={'rh-sb-list tiny subtle'}>

            <div className={'title'}>{getLocalString(this.props, 'admin')}</div>
            {
            options.map(({href, iconName, key}, index)=><div className={'rh-sb-item'} key={index} onClick={navTo(this.props, href)}>
                <div className={'icon'}><Icon name={iconName} /></div>
                <div className={'content'}>{getLocalString(this.props, key)}</div>
            </div>)
        }</div>
    }

}