import React from 'react';

import {Grid, Segment} from 'semantic-ui-react';

export const WithGridLayout = (sc) => class extends sc {


    renderLeftPart(){
        return <Segment textAlign={'center'}>Left</Segment>
    }

    renderRightPart(){
        return <Segment textAlign={'center'}>Right</Segment>
    }

    renderWithVerification(){
        return <Grid>
                <Grid.Column computer={3} tablet={16} mobile={16}>
                    {this.renderLeftPart()}
                </Grid.Column>
                <Grid.Column computer={13} tablet={16} mobile={16}>
                    {this.renderRightPart()}
                </Grid.Column>

        </Grid>
    }
};