import {TemplateRiskList} from "../risk/list";

export class TemplateOpportunityList extends TemplateRiskList {

    showOpportunity = true;



    getLinkToCreate(){
        return this.purl(`/templates/risks/create?is_opportunity=1`);
    }
}