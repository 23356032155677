import React from 'react';
import {ProblemListProto} from "../../problem/list";
import {WithFilter} from "../../../../utils/components/collections/list/filter/withFilter";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {GenericComponent} from "../../../../utils/proto/general";
import {getFromStoreById} from "../../../../../utils/getFromStore";

export class ProblemReportList extends WithFilter(ProblemListProto){


    get filterMeta(){

        return {
            //acronym: {type: 'text'},
            description: {type: 'text'},
            problem_category_id: {type: 'storeDropdown', store: 'problem_category', text: item=>item.name}

        }

    }


    getMeta(){
        return {
            description: {type: 'text'},
            problem_category_id: {title: 'problem_category', collapsing: true, type: 'external_value', store: 'problem_category', targetField: 'name'},
            severity: {type: 'severity'},
            last_kpi_result: {type: 'kpi_result', title: 'last_kpi'},
            opportunity_relations_count : {title: 'related_opportunities'},
            risk_relations_count:  {title: 'related_risks'},
            //

        };
    }


    addRelationsToProblems(data){

        const relations = Object.values(this.props.state.risk_problem_relation.items);

        const relationsByProblemId = {};
        relations.forEach(x=>{
            relationsByProblemId[x.problem_id] = [
                ... relationsByProblemId[x.problem_id] || [],
                x
            ]
        });

        data.forEach(problem => {


            const relations = relationsByProblemId[problem.id] || [];

            const risks = relations.map(relation => getFromStoreById(this.props, 'risk', relation.risk_id) || {});

            //console.log({relations, risks});

            const opportunitiesCount = risks.filter(x=>x.is_opportunity).length;
            const riskCount = risks.length - opportunitiesCount;

            problem.risk_relations_count = riskCount;
            problem.opportunity_relations_count = opportunitiesCount;
        })


    }

    getData(){
        const data = super.getData();
       // const shortList = data.filter(x=>x.is_present === this.showPresent);

         this.addRelationsToProblems(data);


        return this.getFilteredData(data);
    }

    onCellClick(){

    }

    renderTableFooter(){
        return null;
    }


    renderCreateButton(){
        return null;
    }


    renderWithVerification(){
        return <div style={{marginTop: 50}}>
            {super.renderWithVerification()}
        </div>
    }

}


export const ProblemReportView =  WithExtras(class extends GenericComponent {

    renderWithVerification() {
        return <ProblemReportList {...this.props} />
    }
}, ['problem_category', 'risk_problem_relation', 'risk']);