import React from 'react';

import FroalaEditor from 'react-froala-wysiwyg';






export class FroalaTestPage extends React.Component {



    render(){
        return <FroalaEditor tag={'textarea'} config={{
            saveInterval: 10000,
            saveURL: '/api/froala/update',

            // Image config
            imageUploadURL: '/api/froala/image',
            imageUploadMethod: 'POST',


            fileUploadURL: '/api/froala/file',
            fileUploadMethod: 'POST',




        }} />
    }
}