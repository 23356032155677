import {GlobalStore} from "../global";

export class ActivityRiskReducer extends GlobalStore {


    secondary_key = 'risk_category_id';



    getCompositeId = (item)=>{

        // console.log('compositeId');
        // console.log({key, item, t:this});

        let secondary_id = item[this.secondary_key];
        let {company_activity_id} = item;
        let id =  `${company_activity_id}_${secondary_id}`;

        //console.log(id);
        return id || `0`;
    };

    get mapsMeta(){
       return {
           ...super.mapsMeta,
           itemsByCompositeId: {path: [this.getCompositeId]},
       }
    };



}