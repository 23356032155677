import React from 'react';
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";
import {KPIForm} from "./form/form";
import {getLocalString} from "../../../../utils/localString";
import {Button, Grid, Icon, Message, Segment} from "semantic-ui-react";
import {getFromStoreById} from "../../../../utils/getFromStore";
import moment from 'moment';
import {purl} from "../../../utils/proto/general";
import {kpiResultThresholdsMeta} from "./form/results_threshold_field";
import {NavBackTopLeftButton} from "../../../utils/components/button/nav";


const ResultPreview = (props) => {

    const {formState: kpi} = props;



    const items = kpiResultThresholdsMeta.map((meta, index) =>{

        const {emoji, title} = meta;
        const value = (kpi.result_thresholds || [0,0,0,0,0])[index];

        return <div key={index} style={{marginTop: 10, fontSize: 15, display: 'flex'}}>

            <div style={{width: 100}}>

                {emoji}

                {getLocalString(props, title)}

            </div>

            <div>
                {value}
            </div>

        </div>
    });


    return <div style={{marginTop: 10}}>
        <h3>{getLocalString(props, 'result_thresholds')}</h3>
        {items}
    </div>

};


const KPIPreview = (props) => {

    const {formState: kpi} = props;

    const user = getFromStoreById(props, 'user', kpi.user_id) || {};
    const problem = getFromStoreById(props, 'problem', kpi.problem_id) || {};



    return <div>

        <h2>{getLocalString(props, 'kpi')}</h2>

        <Message color={'red'}
                 header={getLocalString(props, 'closed_kpi')}
                 content={getLocalString(props, 'closed_kpi_description')}
                 icon={'exclamation'}
        />

        <Message header={problem.acronym} content={problem.description}/>
        <Segment>
            {kpi.description}
        </Segment>


        <div style={{display: 'flex'}}>
            <div>
                <b>{getLocalString(props, 'user')}: </b>
                {user.full_name}
            </div>

            <div style={{marginLeft: 15}}>
                <b>{getLocalString(props, 'date')}: </b>
                {moment(kpi.date).format('YYYY-MM-DD')} <br/>

                <b>{getLocalString(props, 'review_date')}: </b>
                {moment(kpi.last_date).format('YYYY-MM-DD')}

            </div>

        </div>


        <ResultPreview {...props} />

        <Button
            onClick={()=>{
                props.history.push(purl(props, `/problems/${problem.id}`))
            }}
            content={getLocalString(props, 'back_to_problem')}
            icon={'angle left'}
            style={{marginTop: 20}}
        />

    </div>
};


export class KPIUpdateForm extends BasicUpdateForm('kpi', undefined, undefined, KPIForm){



    renderWithVerificationInternal() {
        const form =  super.renderWithVerification();

        if (this.state && this.state.is_complete){

            return <KPIPreview {...this.props} formState={this.state} />
        }

        return form;
    }

    renderWithVerification() {

        return <div>
            <NavBackTopLeftButton />
            {this.renderWithVerificationInternal()}
        </div>

    }

}
