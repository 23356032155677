import React from 'react';
import {WithRouterLayout} from "../../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../../utils/proto/general";
import {TemplateRiskList} from "./list";
import {CreateTemplateRisk} from "./create";
import {UpdateTemplateRisk} from "./edit";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";


class TemplateRiskView extends WithExtras(WithRouterLayout(GenericComponent),['risk', 'risk_category', 'risk_control_relation', 'risk_problem_relation']){


    prefix = this.purl('/templates/risks');

    routesMeta = {
        '/create': {Component: CreateTemplateRisk},
        '/:id': {Component: UpdateTemplateRisk},
        '*': {Component: TemplateRiskList},


    }
}



export default TemplateRiskView;