import React from 'react';
import {WithState} from "./mixins/redux";
import {WithApi} from "./mixins/api";
import {getCurrentCompanyId, getCurrentProjectId} from "../../../utils/sessionHelper";

export const curl = (props, path) => {
    let cid = getCurrentCompanyId(props);
    return `/companies/${cid}${path}`
};

export const purl = (props, path)=>{
    let pid = getCurrentProjectId(props);
    return curl(props, `/projects/${pid}${path}`);
};


export class AppComponent extends React.Component {


    renderWithVerification() {
        if (!super.renderWithVerification){
            return <div>this.renderWithVerification() is not implemented</div>;
        }

        return super.renderWithVerification();
    }

    curl (path){
        return curl(this.props, path);
    }

    purl(path){
        return purl(this.props, path);
    }

    renderAppComponent(){
        return this.renderWithVerification();
    }

    render() {
        try {
            return this.renderAppComponent();
        }catch(e){
            return this.renderWithException({e});
        }

    }


    renderWithException({e}) {
        console.error(e);
        return <span style={{color: 'red'}}>Failed on renderWithState()</span>;
    }


}



export const StateComponent = WithState(AppComponent);
export const GenericComponent = WithApi(StateComponent);


