import React from 'react';
import {GenericComponent} from "../../../utils/proto/general";






export class SubscriptionSelector extends GenericComponent {


        renderWithVerification(){
            return null;
        }
}