import React from 'react';
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";
import {TableWithEditableTextCell} from "./text";
import {TableWithEditableDateCell} from "./date";
const _TableWithEditableRows = (sc) => class extends sc{


    get editMeta(){
        return {
            actions: {type: 'save_and_exit_button'},
        };
    };


    state = {
        ...super.state || {},
        editedItemId: null,
        editedForm: {},
    };

    onCellClick({item}){

        if (this.state.editedItemId == item.id) return;

        if (this.state.editedItemId !== null){
            this.saveItem();
        }

        this.setState({
            editedItemId: item.id,
            editedForm: {},
        });

    };




    /**
     * This function should handle item save and exit
     */
    saveItem(){

        if (Object.keys(this.state.editedForm).length > 0){
            const item = {id: this.state.editedItemId, ...this.state.editedForm};
            if (this.updateItem) this.updateItem(item);
        }

        this.discardEdit();
    };



    onSaveClick(e){
        if (e && e.preventDefault) e.preventDefault();
        this.saveItem();
    }


    discardEdit(){
        this.setState({
            editedItemId: null,
            editedForm: {},
        });
    }

    renderSaveAndExitButton(args){
        return <div>
                <Button.Group size={'tiny'}>
                    <Button
                        color={'blue'}
                        content={getLocalString(this.props, 'save')}
                        icon={'save'}
                        onClick={this.onSaveClick.bind(this)}
                    />
                    <Button
                        icon={'cancel'}
                        onClick={this.discardEdit.bind(this)}
                    />
                </Button.Group>
        </div>
    };

    renderEditCell(args){
        const {editMeta} = args;

        if (editMeta.type === 'save_and_exit_button'){
            return this.renderSaveAndExitButton(args);
        }


        return super.renderEditCell ? super.renderEditCell(args) : null;
    }

    renderStaticCell(args){
        return super.getCellContent(args)
    }

    getCellContent(args){

        const {key, item} = args;
        const {editMeta} = this;
        const {editedItemId} = this.state;
        // -- -- -- --
        const itemEditMeta = editMeta[key];
        if (itemEditMeta && editedItemId === item.id){
            return this.renderEditCell({...args, editMeta: itemEditMeta});
        } else {
            return this.renderStaticCell(args);
        }
    }

};


export const TableWithEditableRows = (sc) => TableWithEditableTextCell(
    TableWithEditableDateCell(
        _TableWithEditableRows(sc)
    )
);