import React from 'react';
import {Progress} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";

export const MonitoringReportItemProgressBar = (props) => {

    const {items, incomplete_items} = props;

    const complete_count = items.length - incomplete_items.length;
    const total_count = items.length;


    return null;

    // return <Progress value={complete_count} total={total_count} precision={0} indicating progress={'percent'}>
    //     {complete_count} / {total_count} {getLocalString(props, 'modified')}
    // </Progress>
};