import React from 'react';
import {RelationTable} from "../../utils/relationTable";
import {GenericComponent} from "../../../../utils/proto/general";


import {Button, Form, Dropdown} from 'semantic-ui-react'
import {getLocalString} from "../../../../../utils/localString";
import {action_add_to_global_list} from "../../../../../mvvm/reducers/global";
import FroalaEditorView from "react-froala-wysiwyg/FroalaEditorView";



export class RiskProblemRelationCreateForm extends GenericComponent {


    get items(){
        return this.props.state.problem.list;
    }

    get options(){

        let {items} = this;




        let {risk_problem_relation} = this.props.state;
        let {itemsByProblemId} = risk_problem_relation;
        items = items.filter(problem=> {

            let problemMap = itemsByProblemId[problem.id];

            let hasRisk = problemMap && problemMap[this.primary_id];

            return !hasRisk
        });


        return items.map( (item) => ({
            text: this.getProblemDescription(item),
            value: item.id,
            label: {color: 'teal', content: item.acronym, size: 'tiny'}
        }))
    }


    get primary_id(){
        return this.props.match.params.id;
    }

    getProblemDescription(item){
        let {description} = item;
        // if (description && description.length > 20) {
        //     description = `${description.substr(0, 20)}...`
        // }

        return description;
    }



    renderOptionsDropdown(){


        return <Dropdown
            fluid
            closeOnBlur
            value={this.state.problem_id}
            placeholder={getLocalString(this.props, 'select_problem')}
            search
            options={this.options}
            onChange={(e, {value})=>{this.setState({problem_id: value})}}
        >
        </Dropdown>
    }



    onSuccessResponse = ({payload})=>{
        this.props.dispatch({type: action_add_to_global_list, storeName: 'risk_problem_relation', data: payload});

        return {};
    };

    onSubmit = () => {

        let {problem_id} = this.state;
        let {primary_id: risk_id} = this;


        this.api('/api/risk_problem_relation/create', {problem_id,  risk_id}, {});

    };


    renderWithVerification(){

        return <Form onSubmit={this.onSubmit}>
            <Form.Group>

                <Form.Field>
                    <label>{getLocalString(this.props, 'problem')}</label>
                    {this.renderOptionsDropdown()}
                </Form.Field>
                <Form.Field style={{marginLeft: '20px', minWidth:'200px'}}>
                    <Button style={{marginTop: '10px'}} onClick={this.onSubmit} icon={'plus'} content={getLocalString(this.props, 'create_relation')} color={'teal'} />
                </Form.Field>
            </Form.Group>

        </Form>
    }

}


export class RiskProblemRelationsTable extends RelationTable('risk_problem_relation', 'risk', 'problem'){


    getNoDataMessage(){
        return getLocalString(this.props, 'no_problem_relations');
    }


    getLinkToItem(item){
        return this.purl(`/problems/${item.problem_id}`);
    }



}
