import React from 'react';
import {RiskList} from "../../risk/list";
import {getLocalString} from "../../../../../utils/localString";

import {Segment} from 'semantic-ui-react';
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";


export class TemplateRiskList extends WithExtras(RiskList, ['risk', 'risk_control_relation', 'risk_problem_relation']) {

    disableCreate = false;

    showOpportunity = false;

    getData(){
        return this.props.state.risk.list.filter(x=>x.is_opportunity === this.showOpportunity);
    }

    getMeta(){
        return {
            is_starred:  {title: ' ', type: 'check', posIcon: 'star', negIcon: ' ', posIconColor: 'yellow', negIconColor: 'grey',  collapsing: true},
            acronym: {title: 'acronym', type: 'text'},
            level: {type: 'number', collapsing: true},
            residual_risk: {title: 'risk', type: 'residual_risk', collapsing: true},
            description: {title: 'description', type: 'text', navigates: false},

            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }


    getLinkToCreate(){
        return this.purl(`/templates/risks/create?is_opportunity=false`);
    }




    getLinkToItem = (item)=>  this.purl(`/templates/risks/${item.id}`);



}