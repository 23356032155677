import React from 'react';
import {GenericComponent} from "../../../proto/general";
import {Dropdown} from "semantic-ui-react";
import {action_add_to_global_list} from "../../../../../mvvm/reducers/global";
import {getLocalString} from "../../../../../utils/localString";

export class SearchDropdown extends GenericComponent {



    get url(){
        let {storeName} = this.props.meta;

        return `/api/${storeName}/create`;
    }


    onItemAdded = (body)=>{

        let {storeName} = this.props.meta;


        this.props.dispatch({
            type: action_add_to_global_list,
            data: body.payload,
            storeName,
        });

        // Set the value to a newly selected item
        this.props.onChange(null, {name: this.props.name, value: body.payload.id});

        return {};
    };

    getItem(value){

        let {textFieldName} = this.props.meta;

        return {[textFieldName]: value};
    }

    handleAddition = (e, { value }) => {

        let item = this.getItem(value);
        this.api(this.url, item, {
            onSuccessResponse: this.onItemAdded
        });
    };


    renderDropdown() {

        let {options, onChange, value, placeholder, label, name, meta} = this.props;
        let {loading} = this.state;
        const {onAddItem = this.handleAddition} = meta;

        return <div>
            <label><b>{label}</b></label>
            <Dropdown
                onChange={onChange}
                options={options}
                placeholder={placeholder}
                search
                selection
                fluid
                loading={loading || undefined}
                allowAdditions
                additionLabel={getLocalString(this.props, 'create')+" "}
                value={value}
                name={name}
                onAddItem={onAddItem}
            />

        </div>
    }


    renderWithLoading(){
        return this.renderDropdown();
    }

    renderWithVerification(){
        return this.renderDropdown()
    }

}