import React from 'react';
import {Icon, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";

import moment from 'moment';
const SubDate = (props) => {


    let {date, title} = props;

    if (!date) return null;

    let momentDate = moment(date);



    return <div style={{margin: '5px 0'}}>
        <p><b>{getLocalString(props, title)}</b></p>
        <p style={{color: '#a1a1a1'}}>{momentDate.format('YYYY-MM-DD')}</p>
    </div>


};


export const ActiveSubscriptionItem = (props) => {


    let {subscription} = props;

    // let {s}

    let {trial_ends_at, current_period_ends_at} = subscription;

    return <Segment basic textAlign={'center'}>

        <h4>{getLocalString(props, subscription.plan.name)}</h4>
        <Icon name={'check'} color={'green'} size={'large'} />

        <SubDate {...props} title={'trial_until'} date={trial_ends_at}/>
        <SubDate {...props} title={'subscription_until'} date={current_period_ends_at}/>


    </Segment>


};