import React from 'react';
import {SidebarSecondaryMenu as Menu} from "../utils/proto/layouts/sidebar/secondary";
import {getLocalString} from "../../utils/localString";
import {SidebarSecondaryAdmin} from "../company/sidebar/secondary/admin";
import {SidebarSecondaryCompanyList} from "./companies";



export const DefaultSecondarySidebar = (props) => {



    return <Menu expanded={props.expanded}>

        <Menu.Menu>
            <h4>{getLocalString(props, 'companies')}</h4>
            <SidebarSecondaryCompanyList {...props} />
        </Menu.Menu>



        <Menu.Menu>
            <SidebarSecondaryAdmin {...props} />
        </Menu.Menu>

    </Menu>;

};