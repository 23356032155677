import {Store} from "./store";


const a_ugs = 'action_update_global_store';
const a_atgl = 'action_add_to_global_list';


const a_uigl = 'action_update_in_global_list';
const a_rfgl = 'action_remove_from_global_list';
const a_ugl = 'action_update_global_list';
const a_umgl = 'action_update_multiple_global_lists';

export const action_update_global = a_ugs;
export const action_add_to_global_list = a_atgl;
export const action_update_in_global_list = a_uigl;
export const action_remove_from_global_list = a_rfgl;
export const action_update_global_list = a_ugl;
export const action_update_multiple_global_lists = a_umgl;



export class GlobalStore extends Store {





    /**
     *
     * @returns {{items: {path: string[]}}}
     */
    get mapsMeta() {
        return {
            items: {path: ['id']}
        }
    }


    /**
     * Converts
     * id -> someOtherId -> *
     * 1 -> 4 -> *
     * @param path
     * @param item
     * @returns {Array}
     */
    getIdPath(path, item){



        let idPath = [];

        for (let entry of path){

            let key;

            switch (typeof(entry)){
                case 'function':
                    key=entry(item);
                    break;
                default:
                    key=item[entry];
                    break;
            }

            idPath.push(key);
        }


        return idPath;

    }

    updateWithItems(state, newMap){

        let {mapsMeta, name: mapName} = this;

        let maps = {};




        for (let [itemId, item] of Object.entries(newMap)){

            let stateItem = (state.items || {}) [itemId];


           // console.log(state);

            for (let [name, meta] of Object.entries(mapsMeta)){

                //console.log(`Map: ${name}`);
                // Map new items
                let {path} = meta;
                let map = maps[name] || state[name] || {};
                maps[name] = map;



                if (item === null){
                    //console.log(`Deleting ${itemId}`);

                    if (stateItem){
                        //console.log(`Item is there ${itemId}`);
                        let idPath = this.getIdPath(path, stateItem);
                        this.updateValueAtPath(map, idPath, null);
                    } else {
                        //console.log(`Item is not there ${itemId}`);
                    }

                } else {
                    //console.log(`Adding/Updating:  ${itemId}`);
                    let idPath = this.getIdPath(path, item);
                    this.updateValueAtPath(map, idPath, item);
                }

            }



        }

        return maps;


    }


    get defaultState(){
        let defMap = {
            list: [],
        };
        for (let key of Object.keys(this.mapsMeta)){
            defMap[key] = {};
        }

        return defMap;
    }


    /**
     * Happens when there is global update
     * @param state
     * @param action
     */
    onUpdate(state, action){

        let {data = {}} = action;
        if (!data) data = {};

        let {name} = this;


        let map = data[name];


        // Only accept our current maps
        if (!map) return state;

        // console.log(`Updating store '${this.name}' with:`);
        // console.log(map);



        //console.log(`onUpdate`);
        // If there is a map, map it
        let newState = this.updateWithItems(state, map);

        // console.log(newState);

        return {...state, ...newState};

    }



    identifyIndex(list, item, hint=0){


        let {id} = item;
        let hintItem = list[hint];

        // Quick way
        if (hintItem && (hintItem.id == id)) return hint;
        //console.debug('Failed to find proper candidate at suggested index');
        // If not, scroll through the list and just compare to ID

        for (let [index, candidate] of Object.entries(list)){
            if (candidate.id == id){
                //console.log(`Found a candidate @ ${index} (ID: ${id})`);
                return index;
            }
        }


        return undefined;

    }

    onListAction(state, action){

        let {type, data, index: indexHint, storeName} = action;
        let {list} = state;

        let index;

        // If we are not updating our store, don't do anything
        if (this.name !== storeName) return state;
        //
         //console.log(`List action for store ${storeName}`);
         //console.log(state);



        let updatedList = [...list];
        switch (type){

            case a_atgl:
                return {...state, list: [...list, data]};

            case a_uigl:

                index = this.identifyIndex(list, data, indexHint);

                if (index === 0 || index){
                    updatedList[index] = data;
                    return {...state, list: updatedList};
                } else {
                    console.warn(`${type} (${storeName}): Couldn't find item in the list`);
                    console.dir({state, action});
                    return state;
                }


            case a_rfgl:

                index = this.identifyIndex(list, data, indexHint);

                if (index === 0 || index){
                    updatedList.splice(index, 1);
                    return {...state, list: updatedList};
                } else {
                    console.warn(`${type} (${storeName}): Couldn't find item in the list`);
                    console.dir({state, action});
                    return state;
                }


            case a_ugl:
                return {...state, list: data};

            default:
                return state;
        }
    }


    onAction(state, action){

        let {type, data} = action;

        // console.log(type);
        // console.log(action);

        switch (type){
            case a_ugs:
            //    console.log(`${this.name}: onUpdate`)
                //console.log(`Global store update: ${this.name} @ ${action.type}`);
                return this.onUpdate(state, action);

            case a_umgl:


                let list = data[this.name];

                if (list){
                    // console.log(`${type} on ${this.name}`);
                    // console.log({state, list});


                    return {...state, list};
                } else {
                    return state;
                }


            default:
                return this.onListAction(state, action);
        }

    }


    updateItemWith(existing, item){
        return item;
    }

    updateValueAtPath(map={}, path, item){

        let latest = map;

        for (let i=0;i<path.length;i++){
            let entry = path[i];

            if (i === path.length-1){
                let existingItem = latest[entry];
                if (item === null) {
                    delete latest[entry];
                } else {
                    let updatedItem = this.updateItemWith(existingItem, item);
                    latest[entry] = updatedItem;
                }

            } else {
                if (latest[entry]) latest = latest[entry];
                else {
                    latest[entry] = {};
                    latest = latest[entry];
                }
            }

        }
    }

}