import React from 'react';
import {Form} from 'semantic-ui-react';
import {ProblemSeverityField} from "./utils/severity";

/*
*  {
                name: 'locale_id',
                title: 'locale',
                type: 'dropdown',
                storeName: 'locale',
                textFieldName: 'display_name',
                allowNull: true
            },
*
* */

export const ProblemForm = (sc) => class extends sc {

    getFormMeta(){
        return [
            {name: 'acronym', type: 'label'},
            {name: 'description', title: 'description', type: 'textarea'},
            {name: 'problem_category_id', title: 'problem_category', type: 'search_dropdown',
                storeName: 'problem_category',
                textFieldName: 'name',
                allowNull: false,
            },
            {name: 'severity', title: 'severity', type: 'severity', defaultValue: 0.6},
            {name: 'notes', title: 'notes',type: 'froala_field'},
        ]
    }

    renderCustomField(meta){

        let {type} = meta;

        switch(type){
            case 'severity':
                return this.renderSeverityField(meta);
            default:
                return super.renderCustomField(meta);
        }
    }

    renderSeverityField(meta){

        let value = Number(this.getValueForField(meta));

        // Handler for a NaN
        if (isNaN(value)) return <span style={{color: 'red'}}>NaN</span>;

        let props = this.getFieldProps(meta);

        return <Form.Field key={props.key}>
            <label>{props.label}</label>
            <ProblemSeverityField {...{...props, value}} {...this.props}/>
        </Form.Field>
    }

};