import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {BasicUpdateForm} from "../../utils/proto/mixins/form/update";

class FormProto extends BasicUpdateForm('user_type') {


    title = () => getLocalString(this.props, 'edit_user_type');

    getFormMeta() {
        return [
            {name: 'level', title: 'level', type: 'number'},
            {name: 'value', title: 'value', type: 'text'},
        ]
    }


}


export const AdminUserTypeEditForm = (props) => <FormProto {...props} storeName={'user_type'}/>;