import React from 'react';
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {getIconForSeverity, getTitleForSeverity} from "./utils/severity";
import {getLocalString} from "../../../../utils/localString";
import {Button, Icon, Segment} from "semantic-ui-react";
import {kpiResultThresholdsMeta} from "../kpi/form/results_threshold_field";
import {GenericComponent, purl} from "../../../utils/proto/general";
import {ExpandableV2} from "../../../utils/components/expandable";

import {problemSeverityOptions} from "./utils/severity";

export class ProblemListProto extends TableWithStoreList('problem') {

    state = {
        ...super.state || {},
        orderBy: 'severity',
        reverse: true,
    };

    getMeta(){
        return {
            // acronym: {title: 'acronym', type: 'text'},
            description: {type: 'text'},
            severity: {type: 'severity'},
            // last_kpi_result: {type: 'kpi_result', title: 'last_kpi'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},

        }
    }



    renderSeverity(item){
        return <span>{getIconForSeverity(item.severity)} {getLocalString(this.props, getTitleForSeverity(item.severity))}</span>;
    }

    getCellContent(p){
        let {item, key} = p;
        switch (key){
            case 'severity':
                return this.renderSeverity(item);

            case 'last_kpi_result':
                if (item.last_kpi_result || item.last_kpi_result === 0){

                    const m = kpiResultThresholdsMeta[item.last_kpi_result];

                    return <Icon
                            name={m.icon}
                            color={m.color}
                        />
                } else {

                    return getLocalString(this.props, 'no_results')
                }


            default:
                return super.getCellContent(p);
        }
    }

    renderWithVerification(){

        return <Segment basic>

            {super.renderWithVerification()}
        </Segment>
    }
}


export class ProblemListWithPropsData extends ProblemListProto{


    renderPreTable() {
        return null;
    }

    getData(){
        return this.props.problems;
    }

}




export class ProblemList extends GenericComponent {



    renderWithVerification(){


        const problemsBySeverity = {};
        const problems =this.props.state.problem.list;


        problems.forEach(problem => {

            const {severity} = problem;
            if (!problemsBySeverity[severity]) problemsBySeverity[severity] = [];

            // Add problem to bucket
            problemsBySeverity[severity].push(problem);

        });


        const expandables = [];

        for (const [severity, options] of Object.entries(problemSeverityOptions)){


            const bucket = problemsBySeverity[severity] || [];


            const title = getTitleForSeverity(severity);
            const icon = getIconForSeverity(severity);


            const titleRow = <span>
                {icon}&nbsp;

                <b style={{fontSize: 18}}>{getLocalString(this.props, title)}</b>&nbsp;&nbsp;
                <span style={{color: '#747474'}}>{bucket.length} {getLocalString(this.props, bucket.length === 1 ? 'goal':  'goals')}</span>

            </span>;


            expandables.push(
                <ExpandableV2
                    key={severity}
                    titleRow={titleRow}
                    expanded={ Number(severity) === 3.0 || undefined}
                >
                 <ProblemListWithPropsData
                     {...this.props}
                     problems={bucket}

                 />
                </ExpandableV2>)

        }


        return <div>
            <h1>{getLocalString(this.props, 'goals')}</h1>

            <Button
                content={getLocalString(this.props, 'report')}
                color={'green'}
                icon={'table'}
                onClick={()=>{
                    this.props.history.push(purl(this.props, '/reports/problems'))
                }}
            />

            <br/>
            <br/>
            {expandables}

        </div>

    }

}
