import React from 'react';
import {getLocalString} from "../../../../../utils/localString";


export const TemplateControlForm = (sc) => class extends sc {

    getFormMeta(){
        return [
            {name: 'control_area_id', title: 'control_area', type: 'search_dropdown',
                storeName: 'control_area',
                textFieldName: 'name',
                allowNull: false,
            },

            {name: 'description', title: 'description', type: 'textarea'},
            //{name: 'topology', title: 'topology', type: 'dropdown', options: [{text: getLocalString(this.props, 'preventive'), value: 'preventive'},{text: getLocalString(this.props, 'mitigative'), value: 'mitigative'}]},
            {name: 'level', title: 'level', type: 'dropdown', options: [{text: getLocalString(this.props, 'include_everywhere'), value: 1}, {text: getLocalString(this.props, 'include_in_selected'), value: 2}]},

            //{name: 'isPresent', title: 'control_is_present', type: 'checkbox'},
        ]
    }






};