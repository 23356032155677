export function getFromStoreById(props, storeName, id){
    //console.log({props, storeName, id});
    let {state} = props;
    if (!state) throw `Forgot to pass props to getFromStoreById`;

    let store = state[storeName];
    if (!store) throw `Store '${storeName}' doesn't exist`;

    return (store.items && store.items[id]) || null;

}