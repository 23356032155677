import React from 'react';
import {BasicUpdateForm} from "../utils/proto/mixins/form/update";
import {getLocalString} from "../../utils/localString";
import {getCurrentUser} from "../../utils/sessionHelper";

import {Button, Segment} from 'semantic-ui-react';
import {action_drop_session} from "../../mvvm/reducers/custom/session";

export class UserEditForm extends BasicUpdateForm('user') {



    title = () => getLocalString(this.props, 'edit_user_form');

    getFormMeta() {
        return [
            {name: 'first_name', title: 'first_name', type: 'text'},
            {name: 'last_name', title: 'last_name', type: 'text'},
            {name: 'oldPassword', title: 'old_password', type: 'password'},
            {name: 'password', title: 'password', type: 'password'},
            {
                name: 'locale_id',
                title: 'locale',
                type: 'dropdown',
                storeName: 'locale',
                textFieldName: 'display_name',
                allowNull: true
            },
            {name: 'phone', title: 'phone', type: 'phone'},
            {name: 'extra', title: 'company_info', type: 'company_info'}

        ]
    }

    renderWithoutUser(){
        return <Segment padded={'very'}>
               You are trying to edit some other user!
        </Segment>
    }

    renderWithVerification(){


        let {id} = this.props.match.params;
        // console.log(this.props);
        let user = getCurrentUser(this.props);


        if (Number(user.id) !== Number(id)) return this.renderWithoutUser();


        return super.renderWithVerification();
    }


    renderExtra(){
        let {extra={}} = this.state;

        let {createCompany, company_name, tin} = extra;

        if (createCompany){
            return <Segment>
                <h4>{getLocalString(this.props, 'requested_company_to_register')}</h4>
                <b>{getLocalString(this.props, 'company_name')}: </b>{company_name}<br/>
                <b>{getLocalString(this.props, 'registration_number')}: </b>{tin}<br/>
            </Segment>
        } else {
            return getLocalString(this.props, 'no_company_info_provided')
        }
    }

    renderCustomField(meta){

        let {type} = meta;

        let {extra = {}} = this.state;

        switch (type){
            case 'company_info':
                return this.renderExtra(meta);
            default:
                return super.renderCustomField(meta);
        }
    }



    onSessionDropped = (p, o)=>{
        if (super.onSuccessResponse) super.onSuccessResponse(p,o);

        this.props.dispatch({type: action_drop_session});

    };

    dropSession = ()=>{

        this.api('/api/session/remove', {}, {onSuccessResponse: this.onSessionDropped});
    };


    renderWithVerification(){
        return <div>
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <Button
                    color={'red'}
                    icon={'log out'}
                    onClick={()=>{
                        this.dropSession();
                    }}
                    content={getLocalString(this.props, 'log_out')}
                />
            </div>
            {super.renderWithVerification()}

        </div>
    }

}
