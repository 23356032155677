import React from 'react';
import {FormPrototype} from "../../../../utils/components/form/prototype";
import {ProjectUserDropDown} from "../../../../utils/components/form/components/store_dropdown/user_dropdown";
import {getLocalString} from "../../../../../utils/localString";
import {KPIResultThresholdsField} from "./results_threshold_field";
import {purl} from "../../../../utils/proto/general";

class KPIFormPrototype extends FormPrototype {


    getFormMeta() {
        return [

            {
                name: 'problem_id',
                title: 'choose_a_problem_or_create_new',
                type: 'search_dropdown',
                storeName: 'problem',
                textFieldName: 'description',
                allowNull: false,
                onAddItem: (e, {value})=> {
                    this.props.history.push(purl(this.props, `/problems/create?goBackTo=createKPI&description=${value}`))
                }
            },

            {name: 'description', title: 'description', type: 'textarea'},
            {name: 'date', title: 'date', type: 'date'},
            {name: 'user_id', title: 'user', type: 'user_dropdown'},
            {name: 'result_thresholds', title: 'results', type: 'result_thresholds'}

        ]
    }



    renderCustomField(meta){

        let {type} = meta;
        let fieldProps = this.getFieldProps(meta);

        switch (type){
            case 'user_dropdown':
                return <ProjectUserDropDown {...this.props} {...fieldProps} onlyProject />;
            case 'result_thresholds':
                return <KPIResultThresholdsField {...this.props} {...fieldProps} />;
            default:
                return super.renderCustomField(meta);

        }
    }



    renderFields() {
        const {fieldsList} = this.getRenderedFields();

        const top = fieldsList.splice(0, 2);

        const dateAndOwner = fieldsList.splice(0,2).map((x, i)=><div style={{marginRight: 50}} key={i}>
            {x}
        </div>);


        return <div>
            <h1>{getLocalString(this.props, 'create_kpi')}</h1>
            {top}
            <div style={{display: 'flex'}}>
                {dateAndOwner}
            </div>

            {fieldsList}
        </div>
    }

}



export const KPIForm = KPIFormPrototype;