import React from 'react';
import ReactDOM from 'react-dom';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
//import 'font-awesome/css/font-awesome.css';

import FroalaEditor from 'react-froala-wysiwyg';


// Require Editor CSS files.
 import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

// Require Font Awesome.
// import 'font-awesome/css/font-awesome.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/plugins.pkgd.min.css';


// Manual Plugins one by one

// import 'froala-editor/js/plugins/save.min.js';
// import 'froala-editor/js/plugins/print.min.js';
// import 'froala-editor/js/plugins/align.min.js';
// import 'froala-editor/js/plugins/table.min.js';
// import 'froala-editor/js/plugins/code_view.min.js';
// import 'froala-editor/js/plugins/code_beautifier.min.js';
// import 'froala-editor/js/plugins/colors.min.js';
// import 'froala-editor/js/plugins/file.min.js';
// import 'froala-editor/js/plugins/font_size.min.js';
// import 'froala-editor/js/plugins/image.min.js';
// import 'froala-editor/js/plugins/lists.min.js';
// import 'froala-editor/js/plugins/quote.min.js';
// import 'froala-editor/js/plugins/url.min.js';
// import 'froala-editor/js/plugins/word_paste.min.js';

// Require Editor CSS files.

 // import 'froala-editor/css/plugins/table.min.css';
 // import 'froala-editor/css/plugins/code_view.min.css';
// import 'froala-editor/css/plugins/colors.min.css';
// import 'froala-editor/css/plugins/file.min.css';
// import 'froala-editor/css/plugins/image.min.css';

import 'froala-editor/js/languages/it.js';


const toolbarConfig = {

    'viewSource': {
        'buttons': ['html']
    },
    'moreText': {
        'buttons': ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting']
    },
    'moreParagraph': {
        'buttons': ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote']
    },
    'moreRich': {
        'buttons': ['insertLink', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR']
    },
    'moreMisc': {
        'buttons': ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'help'],
        'align': 'right',
        'buttonsVisible': 2
    }
};


export const FROALA_KEY = 'AVB8B-21C2E4C2E3H-9h1A1FOOLb1KOPQGc2CWCQVTDWXGcD3F3H3E2G3A4B4B6C2A1==';

export class FroalaField extends React.Component {


    handleModelChange = (model) => {

        let {onChange, name} = this.props;

        if (onChange){
            return onChange(null, {name, value: model});
        }
    };


    render(){

        let {config = {}, includeInRequest={}} = this.props;

        const conf = {
            key: FROALA_KEY,

            language: 'it',


            // Temp diable fileupload
            fileUpload: false,
            imageUpload: false,


            attribution: false,

            //saveInterval: 0,
            // // Save config
            // saveURL: '/api/froala/update',
            // saveParams: includeInRequest,
            // saveMethod: 'POST',
            //
            // // Image config
            //
            // imageUploadMethod: 'POST',
            // imageUploadURL: '/api/froala/uploadImage',
            // imageUploadParams: includeInRequest,
            //
            //
            // // File config
            // fileUploadURL: '/api/froala/uploadFile',
            // fileUploadMethod: 'POST',
            // fileUploadParams: includeInRequest,
            //


            toolbarButtons: toolbarConfig,

            ...config,
        };

        return <div>
            <FroalaEditor
                key={this.props.name}
                model={this.props.value}
                //onManualControllerReady={this.handleManualController}
                onModelChange={this.handleModelChange}
                tag={`textarea-${this.props.name}`}
                config={conf}
            />

        </div>
    }
}