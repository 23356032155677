import React from 'react';
import {GenericComponent, purl} from "../../../utils/proto/general";
import {WithStoreList} from "../../../utils/proto/mixins/withList";
import {Button, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {FlexRow} from "../../../utils/components/flexRow";
import {routeOnClick} from "../../../../utils/routeTo";


const auditAll = (history, controls, props) => () => {


    const {list} = controls;

    const presentControls = list.filter(x=>x.is_present);

    const ids = presentControls.map(x=>x.id);
    const idString = ids.join(',');


    history.push(purl(props,`/audits/create?multiple=true&controls=${idString}`));

    console.log(list);

};


export class AuditList extends WithExtras(WithStoreList(GenericComponent, 'audit'), ['control']){




    renderWithVerification(){


        return <Segment basic>

            <FlexRow justify={'space-between'}>

                <div>



                <Button
                    onClick={auditAll(this.props.history, this.props.state.control, this.props)}
                    color={'green'}
                    content={getLocalString(this.props, 'audit_all')}
                    icon={'plus'}
                />

                <Button
                    onClick={()=>{this.props.history.push(this.purl('/audits/create'))}}
                    color={'blue'}
                    basic
                    content={getLocalString(this.props, 'add_single_audit')}
                    icon={'plus'}
                />

                </div>


                <div>
                    <Button
                        onClick={routeOnClick(this.props.history, purl(this.props, '/audits/future'))}
                        color={'yellow'}
                        content={getLocalString(this.props, 'future_audits')}
                        icon={'binoculars'}
                    />
                </div>


            </FlexRow>


            <AuditListWithinType type={0} {...this.props} />

            <AuditListWithinType type={1} {...this.props} />

            <AuditListWithinType type={2} {...this.props} />

        </Segment>


    }


}


export const auditTypesMeta = [
    {title: 'nc_full_title', abbreviation: 'nc', icon: 'minus', color: 'red'},
    {title: 'oti_full_title', abbreviation: 'oti', icon: 'plus', color: 'orange'},
    {title: 'c_full_title', abbreviation: 'c', icon: 'check', color: 'green'},
    {title: 'future_full_title', abbreviation: 'f', icon: 'binoculars', color: 'grey'},
];


export class AuditListWithinType extends TableWithStoreList('audit') {


    get auditTypeMeta(){
        let {type} = this.props;
        return auditTypesMeta[type];
    }

    /**
     * Audit Control
     * Date
     * Title
     * Actions
     * @returns {{}}
     */

    getMeta(){
        return {
            'control_id': {title: 'control', collapsing: true, type: 'external_value', store: 'control', targetField: 'acronym'},
            'date': {title: 'on_date', collapsing: true, type: 'date'},
            'title': {title: 'title'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true},

        }
    }


    getData(){
        const {state, type} = this.props;

        const {audit} = state;
        const {list: storeItems} = audit;
        const values = storeItems || [];

        // Sometimes during movement items are doubled, so filter them additionally
        const typedValues = values.filter(x=>x.type == type);

        return typedValues;
    }


    renderPreTable(){
        let {auditTypeMeta: meta} = this;

        return <h1>{getLocalString(this.props, meta.title)}</h1>;
    }

    componentDidMount(){

    }

    renderTableFooter(){
        return null;
    }

    renderWithVerification(){
        return <Segment>
            {super.renderWithVerification()}
        </Segment>;
    }

}