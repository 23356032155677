import React from 'react';
import {SidebarPrimaryMenu as Menu} from "../../../utils/proto/layouts/sidebar/primary";
import {SidebarPrimaryBasicItems} from "./basic";
import {TasksPrimaryMenuItem, UserSettingsMenuItem} from "./user";
import {getLocalString} from "../../../../utils/localString";
import {isCompanyOwner} from "../../../../utils/sessionHelper";
import {curl, purl} from "../../../utils/proto/general";



const AddButtons = (props) => {

        const createProject = <Menu.Item
            hint={getLocalString(props, 'create_project')}
            icon={'add'}
            key={'create_project'}
            onClick={()=>{
                 props.history.push(curl(props,'/create_project'));
            }}
        />;


        const createTemplate = <Menu.Item
            hint={getLocalString(props, 'create_template')}
            icon={'copy outline'}
            key={'create_template'}
            onClick={()=>{
                    props.history.push(curl(props,'/create_template'));
            }}
        />;

        return isCompanyOwner(props) ?
            [createProject, createTemplate]:
            [createTemplate]

};


export const CompanySidebarPrimaryMenu = (props) => {


        return <Menu {...props}>

                <Menu.Menu>
                        <SidebarPrimaryBasicItems {...props}/>

                        <AddButtons {...props} />

                </Menu.Menu>

                <Menu.Menu>
                        <TasksPrimaryMenuItem {...props} />
                        <Menu.Item
                            icon={'settings'}
                            hint={getLocalString(props, 'company_settings')}
                            onClick={()=>{props.history.push(curl(props, '/settings'))}}
                        />
                        <UserSettingsMenuItem {...props} />
                </Menu.Menu>

        </Menu>;

};