import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {loadReCaptcha} from 'react-recaptcha-v3'
import {WithSession} from "../auth/withSession";
import {WithLocalization} from "./mixins/localized";
import {ApplicationShell} from "./shell";


const AppShell = WithLocalization(WithSession(ApplicationShell));


export class ApplicationRoot extends React.Component {


    render() {
        return <AppShell {...this.props} />
    }

    /**
     * Application wide things. Theoretically this component is mounted once.
     */
    componentDidMount(){
        if (super.componentDidMount) super.componentDidMount();
        loadReCaptcha(`6LeaMYsUAAAAAPvyjsJYspA3wBnuHDsqzlC_8DM7`);
    }


}


export default withRouter(connect(state => ({state}),
        dispatch => ({dispatch}))(ApplicationRoot));

