import React from 'react';
import {TableWithStoreList} from "../../../../utils/components/collections/table/store";
import {WithFilter} from "../../../../utils/components/collections/list/filter/withFilter";
import {Dropdown, Icon, Label} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
import {FlexRow} from "../../../../utils/components/flexRow";
import {getLoiForRisk, levelsOfImpact} from "../../utils/loi";


const QuickRelationSelector = (props) => {

    const {risk, onChange} = props;

    const options = [{name: 'none', icon: 'cancel', color: 'grey'} ,...getLoiForRisk(risk)];

    const dropdownOptions = [

        ...options.map( (option, index) => (
        {
            value: index,
            icon: {name: 'arrow up', color: option.color},
            text: getLocalString(props, option.name),

        }))];

    const selectedOption = options[risk.relation || 0];

    return <div>
        <Icon name={selectedOption.icon || 'arrow up'} color={selectedOption.color} style={{marginRight: 10}} />
        <Dropdown
            selection
            value={risk.relation || 0}
            options={dropdownOptions}
            onChange={(e,{value})=>{

               // console.log(value);
                onChange(value)
            }}
        />
    </div>;
};

const hasFilters = filterBy =>{

    if (!filterBy) return false;

    for (const val of Object.values(filterBy)){
        if (val) return  true;
    }

    return false;
};


class QuickRiskControlRelationsTable extends WithFilter(TableWithStoreList('risk')){

    state = {
        ...super.state || {},
        relations: this.props.value,
        filterBy: {},
        orderBy: 'relation',
        reverse: true,
    };

    getData(){

        const risks = super.getData();
        const relations = this.props.riskRelations || this.state.relations;

        const risksWithRelations = risks.map(risk => {
            if (relations[risk.id]){
                risk.relation = relations[risk.id];
            } else {
                risk.relation = 0;
            }

            return risk;
        });

        const risksWithActiveRelations = risksWithRelations.filter(risk=>risk.relation > 0);

        return hasFilters(this.state.filterBy) ? this.getFilteredData(risksWithRelations) : risksWithActiveRelations;
    }

    getMeta(){
        return {
            //acronym: {type: 'label', collapsing: true},
            'risk_category_id':{title: 'category', collapsing: true, type: 'external_value', store: 'risk_category', targetField: 'name'},

            description: {type: 'text'},
            relation: {type: 'relation_selector', title: 'level_of_impact', collapsing: true, navigates: false}
        }
    }

    get filterMeta(){

        return {
            //acronym: {type: 'text'},
            risk_category_id: {type: 'storeDropdown', store: 'risk_category', text: item=>item.name},
            description: {type: 'text'}
        }

    }


    getCellContent(props){

        const {meta, item} = props;

        switch (meta.type){
            case 'label':
                return <Label color={item.is_opportunity ? 'teal' : 'red'}>{item.acronym}</Label>;

            case 'relation_selector':
                return <QuickRelationSelector
                    {...this.props}
                    risk={item}
                    onChange={(status)=>{
                    const {relations = {}}= this.state;
                    relations[item.id] = status;

                    if (this.props.onChange){
                        this.props.onChange(relations);
                    } else {
                        this.setState({relations});
                    }

                }} />
        }

        return super.getCellContent(props);
    }

    renderFormContent(){
        const fields = this.renderFilterFields();

        const [acr, descr] = fields;

        return <FlexRow>
            <div style={{margin: '0 20px', width: 200}}>
                {acr}
            </div>
            <div style={{width: '100%', marginRight: '20px'}}>
                {descr}
            </div>
        </FlexRow>

    }

    renderTableFooter(){
        return null;
    }

    renderPreTable(){
        return null;
    }






}


export const QuickRiskControlRelationsForm = (props) => {

    return <div>
        <h1 style={{marginLeft: '20px'}}>{getLocalString(props, 'quick_risk_relations')}</h1>
        <QuickRiskControlRelationsTable {...props} />
    </div>


};
