import React from 'react';
import {Header, Segment} from "semantic-ui-react";
import {getFromStoreById} from "../../../../../../utils/getFromStore";

export const MonitoringReportKPIPreview = (props) => {

    const kpi = getFromStoreById(props, 'kpi', props.item.kpi_id);
    const problem = getFromStoreById(props, 'problem', props.item.problem_id);
    const problem_category = getFromStoreById(props, 'problem_category', problem.problem_category_id)
    return <Segment>
        <span>{kpi.description}</span>
    </Segment>

};