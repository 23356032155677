import React from 'react';
import {BasicCreateForm} from "../../../../utils/proto/mixins/form/create";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";
import {Dropdown} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";


const getProblemsRisksRelations = (relations, problem_id) => {

    return Object.values(relations || {}).filter(relation => {
        return Number(relation.problem_id) === Number(problem_id);
    });

};

const getRisksUnrelatedToProblem = (risks, relations, problem_id) => {


    const pRelations = getProblemsRisksRelations(relations, problem_id);
    const rids = pRelations.map(x=>Number(x.risk_id));

    //console.log({rids, risks, relations, pRelations, problem_id});
    return risks.filter(risk=> rids.indexOf(Number(risk.id)) === -1)

};


export class ProblemRiskRelationCreateForm extends BasicCreateForm('risk_problem_relation'){


    getFormMeta(){
        return [
            {name: 'risk_id', type: 'risk_dropdown'}
        ]
    }


    getRiskOptions(){
        const {showOnlyOpportunities: ops} = this.props;
        const pid = Number(getCurrentProjectId(this.props));

        const allRisks = Object.values(this.props.state.risk.items);
        const relations = this.props.state.risk_problem_relation.items;
        const unusedRisks = getRisksUnrelatedToProblem(allRisks, relations, pid);

        const risks = unusedRisks.filter(risk=>Number(risk.project_id) === pid && ops === risk.is_opportunity);


        return (risks || []).map(risk=>({value: risk.id, text: risk.description}));
    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission && super.prepareItemForSubmission(item);

        item.problem_id = this.props.problem_id

    }

    renderCustomField(meta){

        const {type} = meta;

        const fieldProps = this.getFieldProps(meta);

        if (type === 'risk_dropdown'){
            const options = this.getRiskOptions();

            return <Dropdown
                {...this.props}
                {...fieldProps}
                placeholder={getLocalString(this.props, 'select_relation')}
                options={options}
                search
            />
        }


        return super.renderCustomField(meta);

    }
}


export const ProblemRiskRelationCreateView = (props) => {

    return <ProblemRiskRelationCreateForm {...props} />
};