import React from 'react';
import {Form, Icon} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";


export const kpiResultThresholdsMeta = [

    {icon: 'thumbs up', color: 'green', title: 'great', emoji: '🚀'},
    {icon: 'thumbs up outline', color: 'green', title: 'good', emoji: '🏎️'},
    {icon: 'thumbs up outline', color: 'orange', title: 'middle', emoji: '🚴'},
    {icon: 'thumbs down outline', color: 'red', title: 'bad', emoji: '🎽'},
    {icon: 'thumbs down', color: 'red', title: 'awful', emoji: '👟'},

];



const ResultRow = (props) => {

    const {onChange, value, meta} = props;



    return <div

        style={{
            display: 'flex',
            //justifyContent: 'space-around'
            margin: '10px 0'
        }}
    >

        <div style={{width: 200}}>
            <span>{meta.emoji}</span>
            <span style={{
                marginLeft: 10,
                fontSize: 16,
                lineHeight: 2
            }}>{getLocalString(props, meta.title)}</span>
        </div>

        <div>
            <Form.Input type={'number'} value={value} onChange={onChange} />
        </div>

    </div>;

};



export class KPIResultThresholdsField extends React.Component {



    get value(){
        let {value} = this.props;
        if (! (value && value.length && value.length === kpiResultThresholdsMeta.length)) value = kpiResultThresholdsMeta.map(()=> 0);
        return value;
    }


    getRowValue = (index) => {
        return this.value[index];
    };

    onChange = (index) => (e, {value: inputValue}) => {

        const {onChange, name} = this.props;
        const {value} = this;

        value[index] = inputValue;

        onChange(e, {name, value});

    };


    render(){

        return <div>

            <div
                style={{
                    display: 'flex',
                    //justifyContent: 'space-around'
                }}
            >

                <div style={{width: 200}}>
                    <h4>{getLocalString(this.props, 'result')}</h4>
                </div>

                <div>
                    <h4>{getLocalString(this.props, 'if_value_is_near')}</h4>
                </div>
            </div>

            {kpiResultThresholdsMeta.map((meta, index) => <ResultRow {...this.props}
                                                                     meta={meta}
                                                                     onChange={this.onChange(index)}
                                                                     value={this.getRowValue(index)}
            />)}
        </div>
    }
}
