import React from 'react'
import { Tab } from 'semantic-ui-react'
import {GenericComponent} from "../../../utils/proto/general";
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {ProjectThresholdSettings} from "./thresholds";
import {getLocalString} from "../../../../utils/localString";
import {ProjectEditView} from "../update";
import {ProjectAccessList} from "./access";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";

const routes = [
    '/settings',
    '/settings/access_control',
    '/settings/thresholds',

];

export default class ProjectSettingsView extends WithExtras(WithRouterLayout(GenericComponent), ['project_type']){

    prefix = this.purl('/settings');

    routesMeta = {
        '/access_control': {Component: ProjectAccessList},
        '/thresholds': {Component: ProjectThresholdSettings},
        '*': {Component: ProjectEditView}
    };


    renderWithVerification(){

        let content = super.renderWithVerification();

        let loc = this.props.location.pathname;

        // Get the index, it's hardcoded now until I figure out a certain way
        let activeIndex = 0;

        for (let [index, route] of Object.entries(routes)){
            if (loc.indexOf(route) > -1) activeIndex = index;
        }

        //console.dir(this.props);

        const panes = [
            { menuItem: getLocalString(this.props, 'general'), render: () => content },
            { menuItem: getLocalString(this.props, 'access_control'), render: () => content },
            { menuItem: getLocalString(this.props, 'thresholds'), render: () => content },
        ];

        return <Tab menu={{ secondary: true }}
                    panes={panes}
                    defaultActiveIndex={activeIndex}
                    onTabChange={(e, {activeIndex})=>{
                        this.props.history.push(this.purl(routes[activeIndex]));
                    }}
        />
    }
}


