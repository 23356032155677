import React from 'react';
import {TableWithStoreList} from "../../../../utils/components/collections/table/store";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {getProblemIdFromURL} from "../view";
import {getRisksForProblemId} from "../../../../../mvvm/reducers/utils/risk_problem_relation";
import {getIconForRiskProbability, getTitleForRiskProbability} from "../../risk/utils/probability";
import {Label} from "semantic-ui-react";
import {getRiskIcon} from "../../risk/utils/category";
import {ResidualRiskIcon} from "../../risk/list";
import {getLocalString} from "../../../../../utils/localString";





export class ProblemRiskList extends WithExtras(TableWithStoreList('risk'), ['risk_problem_relation', 'risk_category']){

    state = {
        orderBy: 'residual_risk',
    };

    //_showOnlyOpportunities = false;
    get showOnlyOpportunities(){

        if (this.props.showOnlyOpportunities === undefined) {
            return this._showOnlyOpportunities;
        }

        return this.props.showOnlyOpportunities;
    }

    renderPreTable(){
        return null;
    }

    getMeta(){
        return {
            acronym: {type: 'text', collapsing: true},
            'risk_category_id':{title: 'category', collapsing: true, type: 'external_value', store: 'risk_category', targetField: 'name'},
            description: {type: 'text'},

            residual_risk: {type: 'residual_risk', collapsing: true,}
        }
    }

    getNoDataMessage(){
        return getLocalString(this.props, this.showOnlyOpportunities ? 'no_opportunities_message' : 'no_risks_message');
    }

    getCellContent(p){

        const {item, key} = p;

        switch (key) {


            case 'acronym':

                return <Label
                    content={item[key]}
                    color={'teal'}
                />;

            case 'residual_risk':
                return <ResidualRiskIcon {...this.props} item={item} />



            default:
                return super.getCellContent(p);
        }



    }

    getData(){

        const getRisks = ()=> {

            const problem_id = this.props.problem_id || getProblemIdFromURL();
            const risks = getRisksForProblemId(this.props, problem_id);


            if (this.showOnlyOpportunities !== undefined){



                const filtered = risks.filter( ({is_opportunity}) => !!is_opportunity === this.showOnlyOpportunities);

                //console.log({originalRisks: risks, filteredRisks: filtered});
                return filtered || [];
            }


            return risks || [];
        };


        const riskMap = {};
        getRisks().forEach(r=>riskMap[r.id] = r);

        return Object.values(riskMap);
    }


    renderTableFooter(){
        return null;
    }

}
