import React from 'react'
import {GenericComponent} from "../../../utils/proto/general";
import {Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../utils/localString";
import {LocalizationEntryListView} from "./list";

export class LocaleEntryView extends GenericComponent {


    get locale_id(){
        return this.props.locale_id;
    }



    renderWithoutLocaleId(){

        return <Segment textAlign={'center'} style={{marginTop: 50}}>
            {getLocalString(this.props, 'no_locale_selected')}
        </Segment>
    }


    renderWithVerification(){

        if (!this.locale_id) return this.renderWithoutLocaleId();


        return <LocalizationEntryListView {...this.props} />
    }

}