import React from 'react';
import {TableWithStoreList} from "../../../../utils/components/collections/table/store";
import {Icon, Popup} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
import {WithStarFilter} from "../../../../utils/components/collections/table/modifiers/withStarFilter";




export class AttachProblemsField extends TableWithStoreList('problem'){



    getMeta(){
        return {
            acronym: {title: 'acronym'},
            description: {title: 'description'},
            status: {title: 'status', type: 'connection'}
        }
    }


    renderTableFooter(){
        return null;
    }



    getCellContent(args){

        let {meta, item} = args;


        if (meta.type === 'connection'){
            let {value={}} = this.props;
            if (value[item.id]) return <Popup trigger={<Icon name={'check'} color={'green'}/>} conten={getLocalString(this.props, 'problem_is_selected')}/>
            else return <div>{getLocalString(this.props, 'click_to_attach')}</div>


        }


        return super.getCellContent(args);
    }

    onCellClick({item}){

        let {value={}, onChange, name} = this.props;

        if (typeof(value) !== 'object') value = {};

        value[item.id] = !value[item.id];

        onChange(null, {name, value});

    }


}