import React from 'react';
import {FlexRow} from "../../../utils/components/flexRow";
import {Header, Message} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {SimpleResponsive} from "../../../utils/SimpleResponsive";
import {Button} from "semantic-ui-react";
import {routeOnClick} from "../../../../utils/routeTo";
import {purl} from "../../../utils/proto/general";
import {kpiResultThresholdsMeta} from "../kpi/form/results_threshold_field";
import moment from 'moment';
import "../../../../styles/components/DashboardKPITable.scss"

const KPIRow = ({kpi, history, ...props}) => {

    const {last_result, last_value} = kpi;
    const meta = kpiResultThresholdsMeta[last_result || 0];



    const date_parsed = moment(kpi.last_date).format("DD/MM/YYYY");

    const clickHandler = routeOnClick(history, purl(props, `/problems/${kpi.problem_id}`));

    return <div onClick={clickHandler}><SimpleResponsive>


        <div className={'rh-kpi'}>

            <FlexRow justify={'center'}>


                <span style={{fontSize: 20, margin: "0 10px"}}>{meta.emoji}</span>
                <span>{last_value}</span>
            </FlexRow>
            <FlexRow justify={'center'} style={{marginTop: 10}}>
                {kpi.description}
            </FlexRow>
        </div>

        <FlexRow justify={'center'}>
        <div className={"rh-kpi row"}>

        <div className={"col result"}>
            <span style={{fontSize: 20, margin: "0 10px"}}>{meta.emoji}</span>
            <span>{last_value}</span>
        </div>
        <div className={"col date"}>{date_parsed}</div>
        <div className={"col description"}>{kpi.description}</div>
    </div>
        </FlexRow>


    </SimpleResponsive></div>

};


const Title = ({history,...props}) => {

    const header = <Header size={'large'}>{getLocalString(props, 'strategic_kpis')}</Header>;

    const buttonFuture = <Button
        content={getLocalString(props, 'create_kpi')}
        color={'blue'}
        onClick={routeOnClick(history, purl(props,'/kpis/create'))}
    />;

    const buttonTrack = <Button
        content={getLocalString(props, 'track_now')}
        color={'green'}
        onClick={routeOnClick(history, purl(props,'/monitoring_reports'))}
    />;


    return <div style={{marginTop: 40}}>


        <SimpleResponsive>

            <div>
                <FlexRow justify={'center'} >{header}</FlexRow>

                {/*<FlexRow justify={'space-between'}>*/}
                {/*    {buttonFuture}*/}
                {/*    {buttonTrack}*/}
                {/*</FlexRow>*/}
            </div>

            <FlexRow justify={'space-around'}>
                {/*<div>*/}
                {/*    {buttonFuture}*/}
                {/*</div>*/}
                <div>
                    {header}
                </div>
                {/*<div>{buttonTrack}</div>*/}
            </FlexRow>

        </SimpleResponsive>





    </div>
}


export const DashboardKPITable = props => {

    const {dashboard} = props;
    if (!dashboard) return null;


    const {kpis} = dashboard;

    const kpiRows = kpis.map(kpi=><KPIRow {...props} kpi={kpi} key={kpi.id} />);


    return <div style={{marginTop: 40}}>

        <Title {...props} />
        <div style={{marginTop: 10}}>
            {kpiRows && kpiRows.length ? kpiRows : <Message style={{textAlign: 'center'}} content={getLocalString(props, 'no_kpis_tracked_yet')} />}
        </div>


    </div>
};
