import React from 'react';
import {WithRouterLayout} from "../../utils/proto/layouts/router";
import {GenericComponent} from "../../utils/proto/general";
import {AdminUserEditForm} from "./edit";
import {AdminUserList} from "./list";



export default class AdminUsersView extends WithRouterLayout(GenericComponent){

    prefix = '/admin/users';
    routesMeta = {
        '/:id' : {Component: AdminUserEditForm},
        '*': {Component: AdminUserList}
    }

}