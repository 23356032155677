import React from 'react';
import {WithActivitiesMatrix} from "./prototype";
import {MatrixPrototype} from "../../../../../utils/components/collections/matrix/basic";
import {GenericComponent} from "../../../../../utils/proto/general";
import {Segment} from 'semantic-ui-react';
import {WithExtras} from "../../../../../utils/proto/mixins/withExtras";
import {WithApi} from "../../../../../utils/proto/mixins/api";

class RiskCategoryActivityMatrix extends WithActivitiesMatrix(WithApi(MatrixPrototype)){

    horizontalStore = 'risk_category';
    relationStore = 'risk_activity_setup_config';




}



export class RiskCategoryActivityMatrixView extends WithExtras(GenericComponent, ['company_activity', 'risk_category', 'risk_activity_setup_config']) {



    renderWithVerification(){

        return <Segment>
            <RiskCategoryActivityMatrix {...this.props}  />
        </Segment>

    }
}