import React from 'react';
import  {TemplateControlForm} from "./form";
import {BasicCreateForm} from "../../../../utils/proto/mixins/form/create";


export class ControlTemplateCreateForm extends TemplateControlForm(BasicCreateForm('control')){

        state = {
            ...super.state || {},
            level: 1,
            topology: 'preventive',
        };



        prepareItemForSubmission(item) {
            super.prepareItemForSubmission(item);

            item.is_present = true;
        }
}
