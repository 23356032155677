import React from 'react';
import {getLocalString} from "../../../utils/localString";
import {Segment} from 'semantic-ui-react';
import {BasicUpdateForm} from "../../utils/proto/mixins/form/update";
import {WithExtras} from "../../utils/proto/mixins/withExtras";


class UserUpdateFormPrototype extends WithExtras(BasicUpdateForm('user'), ['user_type', 'locale']) {



    title = () => getLocalString(this.props, 'edit_user_form');

    getFormMeta() {
        return [

            {name: 'extra', title: 'company_info', type: 'company_info'},
            {name: 'email', title: 'email', type: 'email'},
            {name: 'first_name', title: 'first_name', type: 'text'},
            {name: 'last_name', title: 'last_name', type: 'text'},
            {name: 'oldPassword', title: 'old_password', type: 'password'},
            {name: 'password', title: 'password', type: 'password'},
            {
                name: 'locale_id',
                title: 'locale',
                type: 'dropdown',
                storeName: 'locale',
                textFieldName: 'display_name',
                allowNull: true
            },

            {
                name: 'user_type_id',
                title: 'user_type',
                type: 'dropdown',
                storeName: 'user_type',
                textFieldName: 'value',
                allowNull: false
            },
            {name: 'phone', title: 'phone', type: 'phone'},
        ]
    }


    renderExtra(){
        let {extra={}} = this.state;

        let {createCompany, company_name, tin} = extra;

        if (createCompany){
            return <Segment>
                <h4>{getLocalString(this.props, 'requested_company_to_register')}</h4>
                <b>{getLocalString(this.props, 'company_name')}: </b>{company_name}<br/>
                <b>{getLocalString(this.props, 'registration_number')}: </b>{tin}<br/>
            </Segment>
        } else {
            return <Segment>{getLocalString(this.props, 'no_company_info_provided')}</Segment>
        }
    }

    renderCustomField(meta){

        let {type} = meta;

        let {extra = {}} = this.state;

        switch (type){
            case 'company_info':
                return this.renderExtra(meta);
            default:
                return super.renderCustomField(meta);
        }
    }




}


export const AdminUserEditForm = (props) => <UserUpdateFormPrototype {...props} storeName={'user'}/>;