import React from 'react';
import {Button, Icon} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";
import moment from "moment";
import DatePicker from "react-datepicker";

export const TableWithEditableDateCell = (sc) => class extends sc {



    renderEditDateCell(args){

        const {item, key} = args;
        const {editedForm} = this.state;

        const formValue = editedForm[key];
        const itemValue = item[key];
        const noValue = formValue === undefined;


        const date = noValue ? itemValue : formValue;


        if (!date){

            return <Button
                icon={'calendar'}
                content={getLocalString(this.props, 'set_date')}
                color={'blue'}
                onClick={(e)=>{
                    e.preventDefault();
                    this.setState({
                        editedForm: {
                            ...editedForm || {},
                            [key]: new Date(),
                        }
                    });
                }}
            />


        }

        const momentDate =  moment.isMoment(date) ? date : moment(date);

        return  <div className={'dp-table-group'}>
            <DatePicker
                className={'dp-table-field'}
                selected={momentDate}
                onSelect={(updatedDate) => {
                    this.setState({
                        editedForm: {
                            ...editedForm || {},
                            [key]: updatedDate,
                        }
                    });
                }}
                dateFormat={'DD-MM-YYYY'}
            />

            <Icon

                className={'dp-table-button'}
                name={'cancel'}
                onClick={()=>{

                    this.setState({
                        editedForm: {
                            ...editedForm || {},
                            [key]: null,
                        }
                    });
                }}
            />

        </div>


    }


    renderEditCell(args){
        const {editMeta} = args;
        if (editMeta.type === 'date'){
            return this.renderEditDateCell(args);
        }


        return super.renderEditCell ? super.renderEditCell(args) : null;
    }



};