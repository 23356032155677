import React from 'react';
import {PresenceSelector} from "./presenceSelector";
import {getDefaultFromQuery} from "../../../../utils/path";

export const ControlForm = (sc) => class extends sc {

    state = {
        ...super.state,

        is_present: getDefaultFromQuery('is_present', 'boolean'),
        control_area_id: getDefaultFromQuery('control_area_id', 'number'),
        riskRelations: getDefaultFromQuery('risk_id', undefined, val=>({[val]: 2})),
    };


    get controlAreaTitle(){
        const {is_present} = this.state;

        return is_present ? 'control_area' : 'improvement_area';
    }

    getFormMeta(){
        return [
            {name: 'acronym', type: 'label'},
            {name: 'is_starred', type: 'checkbox_button', icon: 'star', title: 'star'},

            {name: 'control_area_id', title: this.controlAreaTitle, type: 'search_dropdown',
                storeName: 'control_area',
                textFieldName: 'name',
                allowNull: false,

            },
            {name: 'description', title: 'description', type: 'textarea'},
            //{name: 'topology', title: 'topology', type: 'dropdown', options: [{text: getLocalString(this.props, 'preventive'), value: 'preventive'},{text: getLocalString(this.props, 'mitigative'), value: 'mitigative'}]},
            // {name: 'level', title: 'level', type: 'number', defaultValue: 1},
            {name: 'motivation', title: 'motivation', type: 'froala_field'},
            {name: 'is_present', title: 'control_is_present', type: 'presenceSelector'},

        ]
    }

    renderCustomField(meta){

        let {type} = meta;

        let fieldProps = this.getFieldProps(meta);
        switch (type){
            case 'presenceSelector':
                return <PresenceSelector {...this.props} {...fieldProps} />;
            default:
                return super.renderCustomField(meta);
        }
    }









};
