import React from 'react';
import {GenericComponent} from "../../../../utils/proto/general";

import {Button, Grid, Icon, Menu, Segment} from 'semantic-ui-react'
import {getLocalString} from "../../../../../utils/localString";
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {PresenceSelector} from "../../control/presenceSelector";
import {Link} from "react-router-dom";









export class ControlSetupForm extends BasicUpdateForm('control', undefined, props=>props.item.id) {


    getFormMeta(){
        return [
            {name: 'is_starred', type: 'checkbox_button', icon: 'star', title: 'star'},
            {name: 'is_present', type: 'presenceSelector', title: 'control_is_present'},
            {name: 'motivation', type: 'froala_field', title: 'motivation'}
        ]

    }


    renderCustomField(meta){

        let {type} = meta;

        let fieldProps = this.getFieldProps(meta);
        switch (type){
            case 'presenceSelector':
                return <PresenceSelector {...this.props} {...fieldProps} />;
            default:
                return super.renderCustomField(meta);
        }
    }


    prepareItemForSubmission(item){
        super.prepareItemForSubmission(item);

        item.is_setting_up = false;

    }

    renderFields(){
        let {fieldsList} = this.getRenderedFields();

        let star = fieldsList.splice(0, 1);


        return <div>
            <div style={{marginLeft: 'calc(100% - 100px)', marginTop: '-120px', marginBottom: '100px'}}>{star}</div>
            {fieldsList}
        </div>
    }


    renderNextButton(){

        return <Segment textAlign={'center'}>
            <Link to={this.purl('/confirmation')}>
                <Button color={'green'}  content={getLocalString(this.props, 'complete_setup')}/>
            </Link>
        </Segment>

    }


    componentDidUpdate(prevProps, prevState){

        if (this.state.id !== prevState.id){
            this.setState({complete: false});
        }

    }

    renderComplete(){

        let {nextItem} = this.props;

        if (nextItem && !this.props.item.is_setting_up){

            this.props.history.push(this.purl(`/controls/${nextItem.id}`));
            return super.renderComplete();

        } else {
            return this.renderNextButton();
        }



    }

}


export class SetupControlView extends WithExtras(GenericComponent, ['control']) {




    renderMenuWithItems(items, id){


        return <Menu vertical>


            {items.map((i, pos) => <Menu.Item
                key={i.id}
                active={(Number(i.id) === Number(id)) || (pos === 0 && id === 0)}
                as={'a'}
                onClick={()=>{
                    this.props.history.push(this.purl(`/controls/${i.id}`))
                }}
                >

                {i.is_setting_up ? null : <Icon name='check'  color={i.is_present ? 'green' : undefined} />}
                {i.acronym || i.id || getLocalString(this.props, 'unknown')}


            </Menu.Item>)}



        </Menu>
    }

    renderItemForm(item, nextItem){

        return <Segment>

            <h1>{getLocalString(this.props, 'control_setup')}</h1>

            <div>{item.description}</div>

            <ControlSetupForm {...this.props} item={item} nextItem={nextItem} storeName={'control'}/>
        </Segment>;
    }


    renderWithVerification(){

        // Get items
        let {list, items: itemsById} = this.props.state.control;


        let items = list.map(x=>itemsById[x.id]);

        items.sort((a,b)=>Number(a.id) - Number(b.id));

        let selectedId = Number(this.props.match.params.id) || 0;


        let selectedItem = Number(selectedId) ? itemsById[selectedId] : items.length ? items[0] : null;


        // Caclulate nextItem
        let otherItems = [...items].reverse();

        // Remove items until we find the selected one



        while (otherItems.length > 0){
            let i = otherItems.pop();
            if (selectedItem && i && (i.id == selectedItem.id)) break;
        }

        let nextItem = otherItems.length ? otherItems.pop() : null;
        while (otherItems.length>0 && !nextItem.is_setting_up) nextItem = otherItems.pop();

        //console.log({selectedId, selectedItem, itemsById, items});

        return <Grid padded>
            <Grid.Column width={4}>

                <Button.Group>
                    <Button color={'grey'} basic onClick={()=>{this.props.history.push(this.purl('/risks'))}}>

                        <Icon name={'arrow left'} />
                        {getLocalString(this.props, 'go_to_risks')}

                    </Button>
                    <Button color={'green'} onClick={()=>{this.props.history.push(this.purl('/confirmation'))}}>
                        {getLocalString(this.props, 'finish')}
                        <Icon name={'arrow right'} />
                    </Button>

                </Button.Group>

                <br/>
                <br/>


                <div style={{color: '#5d5c5c'}}>
                    <Icon color={'green'} name={'check'} />
                    {getLocalString(this.props, 'control_is_set_up_and_present')}
                </div>

                <div style={{color: '#5d5c5c'}}>
                    <Icon name={'check'} />
                    {getLocalString(this.props, 'control_is_just_set_up')}
                </div>

                <br/>

                {this.renderMenuWithItems(items, selectedId)}


                <br/>
                <br/>


            </Grid.Column>

            <Grid.Column width={12}>
                {selectedItem ? this.renderItemForm(selectedItem, nextItem) : null}
            </Grid.Column>
        </Grid>

    }

}
