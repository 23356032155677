import React from 'react';
import {WithRouterLayout} from "../utils/proto/layouts/router";
import {AppComponent} from "../utils/proto/general";


import AdminCompanyView from './company';
import AdminUsersView from "./user";
import AdminProjectTypeView from './project_type';
import AdminUserTypeView from './user_type';
import AdminLocaleView from './locale';

//
// import AdminUserTypeView from "./user_type";
// import AdminUsersView from "./user";
// import AdminProjectTypeView from "./project_type";


export default class extends WithRouterLayout(AppComponent) {

    prefix = '/admin';

    routesMeta ={

        '/users': {Component: AdminUsersView},
        '/localizations': {Component: AdminLocaleView},

        '/user_types': {Component: AdminUserTypeView},
        '/project_types': {Component: AdminProjectTypeView},

        '*': {Component: AdminCompanyView},

    };

}