import React from 'react';
import {Button, Segment} from "semantic-ui-react";
import {GenericComponent, purl} from "../../../../../utils/proto/general";
import {getKpiReportItemsForReportId, getReportIdFromUrl} from "../preview";
import {getFromStoreById} from "../../../../../../utils/getFromStore";
import {UpdateItemForm} from "./update";
import {MonitoringReportKPIPreview} from "./kpi";
import {MonitoringReportProblemPreview} from "./problem";
import {MonitoringReportNavSlider} from "./nav";
import {getLocalString} from "../../../../../../utils/localString";
import {MonitoringReportItemProgressBar} from "./progress";
import {WithApi} from "../../../../../utils/proto/mixins/api";
import {WithCRUD} from "../../../../../utils/proto/mixins/withList";
import {ReportClosedItemView} from "./closed_view";
import {ImproveMonitoringRelationsView} from "../risks";
import {FlexRow} from "../../../../../utils/components/flexRow";
import {NavToButton} from "../../../../../utils/components/button/nav";


const sortById = (a,b) =>  Number(a.id) - Number(b.id);

const getItemsAround = (items, item) => {

  const before = [];
  const after = [];

  let isBefore = true;

  for (let i of items){

      if(Number(i.id) === Number(item.id)){
          isBefore = false;
      } else {

          if (isBefore) before.push(i);
          else {after.push(i)}
      }



  }

  return {before, after};
};

const getNextIncompleteItem = (incomplete_items, item) => {

    for (const incomplete_item of incomplete_items){
        if (Number(incomplete_item.id) !== Number(item.id)){
            return incomplete_item;
        }
    }

    return null;

};

export class UpdateReportItemView extends WithCRUD(WithApi(GenericComponent), 'monitoring_report'){


    get update_url(){return `/api/monitoring_report/complete`}

    get items(){
        const report_id = getReportIdFromUrl(this.props);
        return getKpiReportItemsForReportId(this.props, report_id, sortById);
    }

    get item(){

        const id = this.props.match.params.kpi_report_id;
        return getFromStoreById(this.props, 'kpi_report_item', id);

    }


    endReport = () => {

        const report_id = getReportIdFromUrl(this.props);
        const {items} = this;

        const item_id = items.length && items[0].id || 0;
        if (!window.confirm(getLocalString(this.props, 'confirm_completing_report'))) return;
        this.updateItem({id: report_id}, undefined,{


            onSuccessResponse: ({payload})=> {

                this.updateItemInStore({payload});
                this.setState({is_complete: true, loading: false, report: payload});
                this.props.history.push(purl(this.props, `/monitoring_reports/${report_id}/${item_id}`));
            }
        });


    };

    improveRisks = (risks) => {

        const report_id = getReportIdFromUrl(this.props);
        const {items} = this;

        let description = "";
        if (items.length){
            const kpi = getFromStoreById(this.props, 'kpi', items[0].kpi_id);
            description = kpi.description;
        }

        this.updateItem({id: report_id, risksToImprove: risks}, undefined,{

            onSuccessResponse: ()=> {
                this.props.history.push(purl(this.props, `/kpis/create?description=${encodeURI(description)}`))
            }

        });


    };



    deleteReport = () => {

        const report_id = getReportIdFromUrl(this.props);
        if (!window.confirm(getLocalString(this.props, 'confirm_deleting_report'))) return;

        this.removeItem({id: report_id}, undefined,  {
            afterRequest: ()=>{
                this.props.history.push(purl(this.props, '/monitoring_reports'))
            }
        });

    };


    renderWithVerification(){

        const {items, item} = this;

        if (!item) return getLocalString(this.props, 'no_kpi_report');

        const report_id = getReportIdFromUrl(this.props);
        const report = this.state.report ||  getFromStoreById(this.props, 'monitoring_report', report_id);

        // Do the logical things outside so that each component
        // doesn't redo it by itself.

        const incomplete_items = items.filter(x=>!x.is_complete);
        const next_incomplete = getNextIncompleteItem(incomplete_items, item);

        // Get next item in general
        const {after, before} = getItemsAround(items, item);

        const next_item = after.length && after[0];
        const previous_item = before.length && before[before.length-1];

        const localProps = {
            items,
            item,
            incomplete_items,
            next_incomplete,
            previous_item,
            next_item,
            after,
            report,
            before,
            onEndReport: this.endReport,
            onImproveRisks: this.improveRisks,
            onDeleteReport: this.deleteReport
        };

        return <Segment basic>

            <FlexRow justify={'space-between'}>

                <div>
                    <Button
                        icon={'arrow left'}
                        content={getLocalString(this.props, 'go_back_to_reports')}
                        onClick={()=>{this.props.history.push(purl(this.props, '/monitoring_reports'))}}

                    />
                </div>


            </FlexRow>


            <MonitoringReportItemProgressBar {...this.props} {...localProps} />
            <MonitoringReportProblemPreview {...this.props} {...localProps} />

            <Segment color={'blue'}>
                <MonitoringReportKPIPreview {...this.props} {...localProps} />
                {report.is_complete ? <ReportClosedItemView {...this.props} {...localProps} />: <UpdateItemForm {...this.props} onKpiSubmitted={this.endReport} localProps={localProps} />}
                <MonitoringReportNavSlider {...this.props} {...localProps} />

                {report.is_complete ? <ImproveMonitoringRelationsView {...this.props} {...localProps} /> : null}
            </Segment>


        </Segment>

    }
}