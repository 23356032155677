import React from 'react';
import {SidebarSecondaryMenu as Menu} from "../../../utils/proto/layouts/sidebar/secondary";
import {CompaniesDropdown} from "../../../shell/bar/companies";
import {getLocalString} from "../../../../utils/localString";
import {SidebarSecondaryProjectsList} from "./projects";
import {SidebarSecondaryAdmin} from "./admin";



export const CompanySidebarSecondaryMenu = (props) => {



    return <Menu expanded={props.expanded}>

        <Menu.Menu>
        <h4>{getLocalString(props, 'companies')}</h4>
        <CompaniesDropdown {...props} />


        <h4>{getLocalString(props, 'projects')}</h4>
        <SidebarSecondaryProjectsList {...props} />
        </Menu.Menu>



        <Menu.Menu>
            <SidebarSecondaryAdmin {...props} />
        </Menu.Menu>

    </Menu>;

};