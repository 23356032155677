import request from 'superagent';

/**
 * Calls API
 * @param url relative path (no need to combine with config)
 * @param data payload to send (JSON)
 * @param options options for headers, not used, I guess
 */
export const fetchApi = (url, data = null, options = {}) => {

    let {method = 'post'} = options;

    let route = url;
    let files = (data || {}).files;
    let fileNames = [];
    if (files){
        fileNames = Object.keys(files);

    }

    let req = request[method](route).withCredentials();

    if (fileNames.length > 0) {

        // Attach files
        for (let fn of Object.keys(files)) {
            let file = files[fn];
            req.attach(fn, file);
        }

        if (data) {

            // Get all the fields and serialize them
            let fieldNames = Object.keys(data);
            for (let name of fieldNames) {

                let field = data[name];
                if (typeof field === 'object') continue;
                req.field(name, field);
            }

        }

    } else {
        req.send(data);
    }



    return req;
};
