import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {MatrixPrototype} from "../../../utils/components/collections/matrix/basic";

import TemplateRiskView from './risk';
import TemplateOpportunityView from './opportunity';
import TemplateControlView from './control';
import TemplateSetupView from './setup_config';
import {ProjectTemplateCreateForm} from "./create";


class MatrixExample extends MatrixPrototype {

    getHorizontalItems() { return [1,2,3];}
    getVerticalItems(){return [1,2,3,4,5,6];}

}

class Router extends WithRouterLayout(GenericComponent){

    prefix = this.purl('/templates');

    routesMeta = {
        '/risks': {Component: TemplateRiskView},
        '/opportunities': {Component: TemplateOpportunityView},
        '/controls': {Component: TemplateControlView},
        '/create': {Component: ProjectTemplateCreateForm},
        '/testMatrix': {Component: MatrixExample},
        '/setup_config': {Component: TemplateSetupView},
        '*': {Component: ()=>null}
    };


}


export default Router;