import React from 'react';
import {AppComponent} from "../../../proto/general";
import {Responsive, Segment, Table} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {Icon} from 'semantic-ui-react';
import moment from 'moment';
import {withFroalaView} from "./modifiers/withFroala";
import {withNavLink} from "./modifiers/withNavLinks";





export class TablePrototype extends AppComponent{


    state = this.getDefaultState();


    compareItems({a,b,orderBy, meta}){

        let aVal = this.getValueForSorting({item: a, field: orderBy, meta});
        let bVal = this.getValueForSorting({item: b, field: orderBy, meta});
        return this.compareRawValues({aVal: aVal, bVal: bVal, field: orderBy, meta});
    }


    compareRawValues({aVal, bVal, field, meta}){
        let aType = typeof(aVal);
        let bType = typeof(bVal);
        if (aType !== bType){
            throw `Type mismatch: A: ${aType}, B: ${bType}, Field: ${field}`;
        }

        const {type} = meta || {};

        // Custom types comparisons
        switch (type){

            case 'date':

                if (moment.isMoment(aVal) && moment.isMoment(bVal)){
                    return aVal.isBefore(bVal) ? -1 : 1;
                } else {
                    console.warn('Non-moment comparison for moment');
                    console.warn({aVal, bVal});

                }


                break;

            default:
                break;
        }

        switch (aType){
            case 'string':
                return aVal.localeCompare(bVal);
            default:
                return aVal-bVal;
        }
    }

    getDefaultState(){
        return {
            orderBy: null,
            reverse: false,
        }
    }

    getCellContent(args){
        let {key, meta, item} = args;
        switch (meta.type){

            case 'date':
                return this.renderDateCell(args);
            case 'check':
                return this.renderCheckCell(args);
            default:
                return item[key];
        }


    }


    onCellClick({item}){
        this.props.history.push(this.getLinkToItem(item))
    }

    getCellProps({key, item, meta}){

        let {navigates=true, collapsing} = meta;

        let onClick = navigates ? ()=>{this.onCellClick({key, item, meta})} : undefined;
        return {
            collapsing,
            onClick,
            key
        }
    }

    getData(){
        return this.props.data || [{name: 'test1', id: 1}, {name: 'test2', id: 2}];
    }

    getNoDataMessage(){
        getLocalString(this.props, 'no_entries');
    }

    getHeaderCellProps({name}){



        let sorted = this.state && (this.state.orderBy === name && (this.state.reverse ? 'descending' : 'ascending')) || undefined
        return {
            key: name,
            onClick: () => {
                this.onSortedColumnClick(name);
            },
            sorted,
        }

    };
    getLinkToItem(item){
        return '/';
    }

    getMeta(){
        return {
            name: {type: 'text', title: 'name'}
        }
    }

    get colSpan(){
        return Object.values(this.getMeta()).length;
    }

    getRowProps(item){
        return {
            key: item.id || 0
        };
    }

    getTableProps(){
        return {
            celled: true,
            selectable: true,
            sortable: true,
        };
    }

    getValueForSorting({item, field}){

        const tableMeta = this.getMeta();
        const meta = tableMeta[field] || {};

        let {type='text'} = meta;
        let value = item[field];

        switch (type){
            case 'number':
                return Number(value);

            case 'date':

                if (!value) return moment();
                else return moment.isMoment(value) ? value : moment(value);

            default:
                return item[field];
        }
    }

    sortData(data){
        let {orderBy, reverse} = this.state;


        //console.log({msg: 'sorting', orderBy, reverse});
        if (!orderBy) return;

        const meta = this.getMeta()[orderBy];

        data.sort( (a,b)=>{
            return this.compareItems({a,b,orderBy, meta});
        });

        // If reverse-order is selected, reverse the data
        if (reverse) data.reverse();
    }

    renderDateCell(args){


        let {key, item} = args;
        let date = item[key];

        if (!date) return getLocalString(this.props, 'no_date');

        let momentDate = moment.isMoment(date) ? date : moment(date);
        return momentDate.format(`DD-MM-YYYY`);
    }


    renderCheckCell(args){

        let {key, item, meta} = args;
        let boolVal = item[key];

        let {posIcon= 'check' , negIcon='cancel', posIconColor='green', negIconColor='red'} = meta || {};

        let iconProps = {
            color: boolVal ? posIconColor : negIconColor,
            name: boolVal ? posIcon : negIcon
        };

        return <Icon {...iconProps} />
    }


    renderCell(args){
        let content = this.getCellContent(args);

        return <Table.Cell {...this.getCellProps(args)}>
            {content}
        </Table.Cell>
    }

    renderHeaderCell({name, meta}){
        let {title=name} = meta;
        return <Table.HeaderCell {...this.getHeaderCellProps({name, meta})}>
            {getLocalString(this.props, title)}
        </Table.HeaderCell>
    }

    renderPreTable(){
        return <h1>Table Title</h1>;
    }

    renderPostTable(){
        return null;
    }


    renderRowWithoutItem(){

        return <Table.Row>
            <Table.Column>
                {getLocalString(this.props, `no_item`)}
            </Table.Column>
        </Table.Row>
    }

    renderRow(item, rowIndex){
        if (!item) return this.renderRowWithoutItem();
        let renderedCells = Object.entries(this.getMeta()).map(([key, meta], columnIndex)=>this.renderCell({key, meta, item, columnIndex, rowIndex}));

        return <Table.Row {...this.getRowProps(item)}>{renderedCells}</Table.Row>
    }

    renderTable(){
        let data = [...this.getData()];
        data.forEach((x,i)=>{x.storeIndex=i});


        this.sortData(data);



        return <Table {...this.getTableProps()}>
            {this.renderTableHeader()}
            {this.renderTableBody(data)}
            {this.renderTableFooter()}
        </Table>
    }

    renderTableBody(data){

        if (! (data && data.length)){
            return this.renderWithoutItems();
        }
        //console.log('LOW_LEVEL: Rendered Data');
        //console.log(data);
        let renderedRows = data.map((i, k)=>this.renderRow(i, k));
        return <Table.Body>
            {renderedRows}
        </Table.Body>

    }

    renderTableFooter(){
        return null;
    }

    renderTableHeader(){
        let headerCells = Object.entries(this.getMeta()).map(([name, meta])=>this.renderHeaderCell({name, meta}));

        return window.innerWidth  > 600 ?
        <Table.Header>
            <Table.Row>
                {headerCells}
            </Table.Row>
        </Table.Header> : null;



    }

    renderTableView(){
        return <Segment basic padded>
                {this.renderPreTable()}
                {this.renderTable()}
                {this.renderPostTable()}
            </Segment>;
    }

    renderWithoutItems(){
        let colSpan = Object.keys(this.getMeta()).length;
        return <Table.Body>
            <Table.Row>
                <Table.Cell colSpan={colSpan} textAlign={'center'}>
                    {this.getNoDataMessage()}
                </Table.Cell>

            </Table.Row>
        </Table.Body>
    }

    renderWithVerification(){
        return this.renderTableView();
    }

    onSortedColumnClick = (name) => {
        let {orderBy, reverse} = this.state;
        let s = {
            orderBy: name,
            reverse: orderBy === name ? !reverse : false
        };

        this.setState(s);
    };
}


export const SortableTablePrototype = withFroalaView(withNavLink(TablePrototype));
