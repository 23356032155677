import React from 'react';
import {WithStoreList} from "../../../../utils/proto/mixins/withList";
import {WithApi} from "../../../../utils/proto/mixins/api";
import {AppComponent} from "../../../../utils/proto/general";
import {getCurrentProjectId} from "../../../../../utils/sessionHelper";
import {Icon} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";


export class SecondaryMenuFroalaList extends WithStoreList(WithApi(AppComponent), 'project_froala_page'){




    get pages(){
        return this.props.state.project_froala_page.list || [];
    }

    fetchData = ()=>{

        this.api('/api/project_froala_page/list');

    };


    componentDidMount(){

        if (super.componentDidMount) super.componentDidMount();
        this.fetchData();
    }


    componentDidUpdate(prevProps){

        let old_project_id = getCurrentProjectId(prevProps);
        let project_id = getCurrentProjectId(this.props);

        if (old_project_id != project_id){
            this.fetchData();
        }
    }



    renderWithVerification(){

        const pages = this.pages.map(page => <div
            className={'rh-sb-item'}
            key={page.id}
            onClick={()=>{this.props.history.push(this.purl(`/froala/${page.id}`))}}
        >
            {page.name}
        </div>);

        return <div className={'rh-sb-list'}>
            {pages}

            <div
                className={'rh-sb-item'}
                onClick={()=>{this.props.history.push(this.purl(`/froala/create`))}}
            >
                <div className={'icon'}><Icon name={'plus'}/></div>
                <div className={'content'}>{getLocalString(this.props, 'create_page')}</div>

            </div>
        </div>

    }
}