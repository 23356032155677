import React from 'react';
import {TemplateOpportunityList} from "./list";


import {WithRouterLayout} from "../../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../../utils/proto/general";
import {WithExtras} from "../../../../utils/proto/mixins/withExtras";


class TemplateRiskView extends WithExtras(WithRouterLayout(GenericComponent),['risk', 'risk_category', 'risk_control_relation', 'risk_problem_relation']){


    prefix = this.purl('/templates/opportunities');

    routesMeta = {
        '*': {Component: TemplateOpportunityList},
    }
}



export default TemplateRiskView;