import {TableWithStoreList} from "../../utils/components/collections/table/store";


export class AdminUserTypeList extends TableWithStoreList('user_type'){
    storeName = 'user_type';

    getMeta(){
        return {
            id: {type: 'text', title: 'ID'},
            level: {type: 'number', title: 'user_level'},
            value: {type: 'text', title: 'value'},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }

    getLinkToItem = (item)=> `/admin/user_types/${item.id}`;
    getLinkToCreate = ()=>`/admin/user_types/create`;
}