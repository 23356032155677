import React from 'react';
import {curl, GenericComponent} from "../../../utils/proto/general";

import {Grid, Icon, Segment, Statistic, Button, Header} from 'semantic-ui-react';
import {getLocalString} from "../../../../utils/localString";
import {getCurrentCompany, getCurrentProject, getCurrentProjectId} from "../../../../utils/sessionHelper";
import {WithList} from "../../../utils/proto/mixins/withList";
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";

import moment from 'moment';
// import {ProjectSprintInfo} from "./ProjectSprintInfo";
import {DashboardKPITable} from "./DashboardKPITable";
import {DashboardStats} from "./DashboardStats";
import {FlexRow} from "../../../utils/components/flexRow";
import {NavToButton} from "../../../utils/components/button/nav";


const groupsMeta = [
    {color: 'green', icon: 'arrow down', name: 'low_risks'},
    {color: 'orange', icon: 'arrow up', name: 'medium_risks'},
    {color: 'red', icon: 'arrow up', name: 'high_risks'},
];



class ProjectNotesEditor extends BasicUpdateForm('project', undefined, getCurrentProjectId){

    getFormMeta(){
        return [
            {name: 'notes', type: 'froala_field', title: ' '},
        ]
    }


    getFroalaConfig(meta){
      return {
          ...super.getFroalaConfig(meta) || {},
          initOnClick: true
      }
    };




}


class ProjectDashboard extends WithList(GenericComponent, '/api/dashboard/get'){
    get dashboard(){

        return (this.state &&
            this.state.payload &&
            this.state.payload.dashboard) ||
            null;
    }


    renderRiskGroups(){

        let {dashboard: item} = this;


        if (!item || !item.riskBuckets) return null;

        return item.riskBuckets.map((count, position)=> {
            let {color, icon, name} = groupsMeta[position];
            return <Grid.Column key={position} mobile={16} tablet={8} computer={4}><Statistic  size={'huge'}>
                <Statistic.Value>
                    <Icon size={'tiny'} color={color} name={icon} />
                    {count}
                </Statistic.Value>
                <Statistic.Label>{getLocalString(this.props, name)}</Statistic.Label>
            </Statistic></Grid.Column>
        });
    }

    renderControlStats(){
        let {dashboard: item} = this;

        if (!item) return null;

        let {presentControls} = item;
        return <Grid.Column mobile={16} tablet={8} computer={4}><Statistic size={'huge'}>
            <Statistic.Value>{presentControls}</Statistic.Value>
            <Statistic.Label>{getLocalString(this.props, 'active_controls')}</Statistic.Label>
        </Statistic></Grid.Column>

    }


    renderWithVerification(){

        let company = getCurrentCompany(this.props);
        let project= getCurrentProject(this.props);

        //if (!company) return <div>no_company</div>;
        if (!project) return <div>no_project</div>;


        return <div>
            <Button
                floated={'right'}
                color={'green'}
                content={getLocalString(this.props, 'create_new_project')}
                onClick={()=>{
                    this.props.history.push(this.curl('/create_project'));
                }
                }
            />

            <Header>
                {project.name}
                <Header.Subheader>
                    {/*{getLocalString(this.props, 'project_by')} {company && company.name}*/}
                </Header.Subheader>
            </Header>

            <Segment basic>
                {project.description}
            </Segment>



            <Grid>
                {this.renderRiskGroups()}
                {this.renderControlStats()}

                <Grid.Column computer={16}>
                    <h3>{getLocalString(this.props, 'last_monitoring_report')}</h3>
                    <LastMonitoringView {...this.props} dashboard={this.dashboard}/>
                </Grid.Column>

            </Grid>
        </div>

    }
}


export const LastMonitoringView = (props) => {

    const {dashboard} = props;

    if (!dashboard) return null;
    const {monitoring_reports} = dashboard;

    if (!monitoring_reports.length) return <div>{getLocalString(props, 'no_monitoring_reports')}</div>

    const report = monitoring_reports[0];

    const date = moment(report.updatedAt).format("YYYY-MM-DD");
    return <div>
        <div>
            <b>{getLocalString(props, 'date')}:</b> <span>{date}</span>
        </div>
    </div>;

};

export class DashboardView extends  WithList(GenericComponent, '/api/dashboard/get') {


    renderWithVerification(){

        const project = getCurrentProject(this.props);

        return <Segment padded={'very'}>


            <FlexRow justify={'space-between'}>

                <div style={{marginLeft: 50}}>
                    <Header size={'huge'}>{project.name}</Header>
                </div>

                <div>
                    <NavToButton to={curl(this.props, '/projects')} content={getLocalString(this.props, 'home')} icon={'home'} />
                </div>


            </FlexRow>

            {/*<ProjectSprintInfo {...this.props} />*/}

            <DashboardKPITable {...this.props} {...this.state.payload} />
            <DashboardStats {...this.props} {...this.state.payload} />

            <ProjectNotesEditor {...this.props} />
        </Segment>
    }
}
