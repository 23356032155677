import React from 'react';
import {AppComponent} from "../../../proto/general";

import {Icon, Menu} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";

export class MenuListPrototype extends AppComponent {


    getItems() {
        console.dir({msg: 'no getItems()'});
        return [
            {id: 1, title: 'testItem 1'},
            {id: 2, title: 'testItem 2'},
            {id: 3, title: 'testItem 3'},
            {id: 4, title: 'testItem 4'},

        ];
    }

    onAddButtonClicked() {
        this.goTo(this.getRouteToCreate());
    }

    renderAddButton() {
        return <Menu.Item key={'add-button'} color={'teal'} onClick={() => {
            this.onAddButtonClicked();
        }}>
            <Icon name='plus'/>
            {this.getCreateTitle()}
        </Menu.Item>
    }


    renderItem(arg) {
        return <Menu.Item
            key={arg.item.id}
            active={this.isActiveItem(arg)}
            onClick={() => {
                this.onItemClicked(arg)
            }}
        >

            {this.renderItemContent(arg)}
        </Menu.Item>
    }

    isActiveItem(item) {
        return false;
    }

    onItemClicked(item) {
        this.goTo(this.getRouteToItem(item))
    }

    goTo(link) {
        this.props && this.props.history && this.props.history.push(link);
    }

    renderList() {
        let items = this.getItems();

        let renderedItems = items.map((item, position) => this.renderItem({item, position}));
        return <Menu vertical>

            {renderedItems}
            {this.renderAddButton()}
        </Menu>
    }


    renderItemContent(item) {
        return this.getLinkTitle(item);
    }


    renderWithVerification() {
        return this.renderList();
    }

    getLinkTitle(item) {
        return (item && item.title) || 'itemTitle';
    }

    getRouteToItem() {
        return '/edit';
    }

    getRouteToCreate() {
        return '/create';
    }

    getCreateTitle() {
        return getLocalString(this.props, 'create_new_item')
    }
}