import React from 'react';
import {WithRouterLayout} from "../../utils/proto/layouts/router";
import {GenericComponent} from "../../utils/proto/general";
import {WithNavMenuLayout} from "../../utils/components/menu/prototype";
import {WithExtras} from "../../utils/proto/mixins/withExtras";
import {WithGridLayout} from "../../utils/proto/layouts/masterSlave";


import {Segment} from 'semantic-ui-react'
import {WithStoreList} from "../../utils/proto/mixins/withList";
import {getLocalString} from "../../../utils/localString";

import {LocaleCreateForm} from "./create";
import {LocaleEntryView} from "./locale_entry";


class LocaleMenu extends WithStoreList(WithNavMenuLayout(GenericComponent), 'locale'){



    get locales(){

        return this.props.state.locale.list;
    }


    getMenuItems(){

        let {locales = []} = this;

        let map = {};

        for (let locale of locales){
            map[locale.key] = {
                title: locale.display_name,
                route: `/admin/localizations/${locale.id}`
            }

        }


        return map;
    }

}




class LocaleView extends WithExtras(WithGridLayout(GenericComponent), ['locale_entry']){


    get locale_id(){
        return this.props.match.params.locale_id;
    }


    renderLeftPart(){
        return <Segment basic>

            <h3>{getLocalString(this.props, 'locales')}</h3>
            <LocaleMenu {...this.props} />

            <LocaleCreateForm {...this.props} />

        </Segment>
    }



    renderRightPart(){
        return <LocaleEntryView {...this.props} locale_id={this.locale_id} />
    }




}



export default class LocalizationsView extends WithRouterLayout(GenericComponent){



    prefix = '/admin/localizations';
    routesMeta = {
        '/:locale_id' : {Component: LocaleView},
        '*' : {Component: LocaleView},


    }

}