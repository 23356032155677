import React, {Component} from 'react';
import {Segment, Icon, List} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";


export const problemSeverityOptions = {
    3.0: {title: 'highest', color: 'red'},
    2.4: {title: 'high', color: 'orange'},
    1.8: {title: 'medium', color: 'yellow'},
    1.2: {title: 'low', color: 'green', icon: 'arrow down'},
    0.6: {title: 'lowest', color: 'teal', icon: 'arrow down'},

};

export function getIconForSeverity(severity, otherOptions={}){
    let options = problemSeverityOptions[severity];

    let {icon='arrow up', color='red'} = options || {};

    return <Icon name={icon} color={color} {...otherOptions} />;
}

export function getTitleForSeverity(severity){
    let options = problemSeverityOptions[severity];

    let {title='none'} = options || {};

    return title;
}

export class ProblemSeverityField extends Component{



    renderItem(severity, options, position){
        let {value, onChange, fieldName='severity'} = this.props;

        let {icon='arrow up', color='blue', title='severity'} = options;

        let active = (Number(value) === Number(severity)) || undefined;
        return <List.Item
            key={position}
            active={active}
            onClick={()=>{
                onChange(null, {name: fieldName, value: Number(severity)})
            }}
            >
                {getIconForSeverity(severity)}
                <List.Content>
                    {getLocalString(this.props, title)}
                </List.Content>
        </List.Item>
    }

    renderItems(){
        return Object.entries(problemSeverityOptions).map(([s,o],p)=>this.renderItem(s,o,p));
    }

    render(){
        return <Segment basic>
            <List selection>
                {this.renderItems()}
            </List>
        </Segment>
    }
}