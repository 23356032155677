import React from 'react';
import {RiskTemplateAnswersForm} from "./questions";
import {getLocalString} from "../../../../../utils/localString";

import {Form} from 'semantic-ui-react';
export const RiskTemplateForm = (sc) => class extends sc {


    getFormMeta(){
        return [
            {name: 'risk_category_id', title: 'risk_category', type: 'search_dropdown',
                storeName: 'risk_category',
                textFieldName: 'name',
                allowNull: false,
            },


            {name: 'level', title: 'level', type: 'dropdown', options: [{text: getLocalString(this.props, 'include_everywhere'), value: 1}, {text: getLocalString(this.props, 'include_in_selected'), value: 2}]},
            {name: 'description', title: 'description', type: 'textarea'},

            {name: 'extra', title: 'question', type: 'question'},
            {name: 'extra', title: 'answers', type: 'answers'},


        ]
    }





    renderQuestionField(meta){

        let props = this.getFieldProps(meta);
        let {value: extra={}} = props;

        if (!extra) extra = {};
        let {question} = extra;

        return <Form.Field key={props.key}>
            <label>{getLocalString(this.props, meta.title)}</label>
            <input type={'text'} value={question} onChange={ (e)=>{

                let updatedExtra = {...extra, question: e.target.value};
                this.setState({extra: updatedExtra, changed: true});
            }} />
        </Form.Field>


    }

    prepareItemForSubmission(item){

        if (!item.probability) item.probability = 0.6;
    }

    renderCustomField(meta){

        let fieldProps = this.getFieldProps(meta);

        switch (meta.type){
            case 'answers':
                return <RiskTemplateAnswersForm {...this.props} {...fieldProps} />;
            case 'question':
                return this.renderQuestionField(meta);
            default:
                return super.renderCustomField(meta);
        }



    }
};