import React from 'react';
import {StoreDropdown} from "./index";
import {getCurrentProjectId} from "../../../../../../utils/sessionHelper";



export class UserDropdown extends StoreDropdown {

    storeName='user';

    renderItemText(item){
        return `${item.first_name} ${item.last_name}`;
    }

}


const getUserById = (props, id) => {
    return props.state.user.items[id];
};

export class ProjectUserDropDown extends StoreDropdown{



    storeName = 'user_project_relation';


    get storeList(){
        let pid = getCurrentProjectId(this.props);
        return super.storeList.filter(x=>x.project_id == pid);
    }




    getItemValue(item){
        let user = getUserById(this.props, item.user_id);
        return user.id;
    }

    renderItemText(item){

        let user = getUserById(this.props, item.user_id);
        let {first_name, last_name} = user;

       return `${first_name} ${last_name}`;
    }

}