import React from 'react';
import {GenericComponent, purl} from "../../../../utils/proto/general";
import {getFromStoreById} from "../../../../../utils/getFromStore";
import {Button, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";


export const getReportIdFromUrl = (props) => {
    const matchParamsId = props.match.params.id;


    return matchParamsId
};


export const goToKpiReportItem = (props, item) => (e) => {

    e && e.preventDefault && e.preventDefault();

    props.history.push(purl(props, `/monitoring_reports/${item.monitoring_report_id}/${item.id}`))

};


export const getKpiReportItemsForReportId = (props, report_id, sortFunc=null) => {

    const {items} = props.state.kpi_report_item;

    const reports_items = Object.values(items).filter(x=>Number(x.monitoring_report_id) === Number(report_id));

    if (sortFunc){
        reports_items.sort(sortFunc)
    }

    return reports_items;
};

export class MonitoringReportPreview extends GenericComponent{





    get report(){

        const {props} = this;

        const id = getReportIdFromUrl(props);
        return getFromStoreById(props, 'monitoring_report', id);
    }

    get kpi_report_items(){

        const report_id = getReportIdFromUrl(this.props);

        //reports_items.sort(sortById);

        return getKpiReportItemsForReportId(this.props, report_id);
    }



    renderWithVerification(){

        const items = this.kpi_report_items;
        const {report} = this;

        const total = items.length;
        const incomplete = items.filter(x=>!x.is_complete);

        const next_incomplete_item = incomplete.length && incomplete[0];
        const start_item = items.length && items[0];

        if (next_incomplete_item){

            goToKpiReportItem(this.props, next_incomplete_item, getReportIdFromUrl(this.props))()

        } else if (start_item) {
            goToKpiReportItem(this.props, start_item, getReportIdFromUrl(this.props))()
        }


        return <Segment>
            <h2>{getLocalString(this.props, 'monitoring_report')}</h2>

            {!report.is_complete && next_incomplete_item ? <Button
                onClick={goToKpiReportItem(this.props, next_incomplete_item, getReportIdFromUrl(this.props))}
                content={getLocalString(this.props, 'go_to_next_kpi')}
                color={'blue'}
                icon={'edit'}
            /> : null}


            {start_item ?
                <Button
                    onClick={goToKpiReportItem(this.props, start_item, getReportIdFromUrl(this.props))}
                    content={getLocalString(this.props, 'go_to_start')}
                    icon={'checkered flag'}
                /> : null

            }

        </Segment>

    };
}