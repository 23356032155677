import {WithExtras} from "../../../../utils/proto/mixins/withExtras";
import {WithRouterLayout} from "../../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../../utils/proto/general";
import {MonitoringReportPreview} from "./preview";
import {UpdateReportItemView} from "./item";


class Router extends WithExtras(WithRouterLayout(GenericComponent), ['monitoring_report']){

    prefix=this.purl('/monitoring_reports/:id');

    routesMeta = {

        '/:kpi_report_id': {Component: UpdateReportItemView},
        '*': {Component: MonitoringReportPreview},
    }

}

export default Router;