import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {AppComponent} from "../../../utils/proto/general";
import {FroalaCreatePageView} from "./create";
import {FroalaEditView} from "./edit/view";


export class FroalaRouter extends WithRouterLayout(AppComponent){

    prefix = this.purl('/froala');

    routesMeta = {

        '/create': {Component: FroalaCreatePageView},
        '/:id': {Component: FroalaEditView},
        '*': {Component: ()=>'Froala'},
    }
}