import React from 'react';
import {BasicUpdateForm} from "../../utils/proto/mixins/form/update";
import {getCurrentProject, getCurrentProjectId} from "../../../utils/sessionHelper";
import {Grid} from "semantic-ui-react";
import {getLocalString} from "../../../utils/localString";
import {RiskCategoriesTable, CompanyActivitiesTable, ControlAreasTable, ProblemCategoriesTable} from "./settings/categories";


class FormProto extends BasicUpdateForm('project', undefined, (p)=>getCurrentProjectId(p)) {



    getFormMeta(){
        return [
            {name: 'name', title: 'name', type: 'text'},
            {name: 'description', title: 'description', type: 'textarea'},

            {
                name: 'sprint_number',
                title: 'sprint_number',
                type: 'number',
                value: 1,
                hidden: true
            },

            {
                name: 'sprint_due_date',
                title: 'sprint_due_date',
                type: 'date',
                value: new Date('2999-12-31T00:00:00'),
                hidden: true
            },

            {name: 'level', title: 'project_level', type: 'dropdown', options: [
                    {value: 0, text: getLocalString(this.props, 'strategy')},
                    {value: 1, text: getLocalString(this.props, 'level_1')},
                    {value: 2, text: getLocalString(this.props, 'level_2')},

                ]},
            // {name: 'documents_link', title: 'documents_link', type: 'text'},
            // {name: 'meetings_link', title: 'meetings_link', type: 'text'},
            //{name: 'prc', title: 'upload_one_more_PRC_package', type: 'file'},
        ];
    }
}


export const ProjectEditView = (p)=> {

    const project = getCurrentProject(p) || {};

    return <div>
        <Grid>

            <Grid.Column mobile={16} tablet={16} computer={8}>
                <FormProto {...p} storeName={'project'}/>
            </Grid.Column>


            {project.is_template ?
                <Grid.Column mobile={16} tablet={16} computer={8}>
                    <h1>{getLocalString(p, 'company_activities')}</h1>
                    <CompanyActivitiesTable {...p} />
                </Grid.Column> : null
            }

            <Grid.Column mobile={16} tablet={16} computer={8}>
                <h1>{getLocalString(p, 'risk_categories')}</h1>
                <RiskCategoriesTable {...p} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
                <h1>{getLocalString(p, 'control_areas')}</h1>
                <ControlAreasTable {...p} />
            </Grid.Column>

            <Grid.Column mobile={16} tablet={16} computer={8}>
                <h1>{getLocalString(p, 'problem_categories')}</h1>
                <ProblemCategoriesTable {...p} />
            </Grid.Column>



        </Grid>


    </div>;
};
