import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";
import {MonitoringReportList} from "./list";
import {ReportCreateView} from "./create";
import UpdateView from './update';


class Router extends WithExtras(WithRouterLayout(GenericComponent), ['monitoring_report', 'problem_category', 'risk', 'risk_problem_relation', 'problem']){

    prefix=this.purl('/monitoring_reports');

    routesMeta = {
        '/create': {Component: ReportCreateView},
        '/:id': {Component: UpdateView},
        '*': {Component: MonitoringReportList},
    }

}

export default Router;