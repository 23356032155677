import React from 'react';
import {BasicUpdateForm} from "../../../../utils/proto/mixins/form/update";
import {FroalaField} from "../../../../utils/components/form/components/froala_editor/proto";
import {Button, Message, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
import {WithCRUD} from "../../../../utils/proto/mixins/withList";
import {purl} from "../../../../utils/proto/general";


export class FroalaBasicUpdateForm extends WithCRUD(BasicUpdateForm('project_froala_page'), 'project_froala_page') {


    getFormMeta(){

        return [
            {name: 'name', type: 'text', title: 'page_name'},
            {name: 'content', type: 'froala_field', title: 'content'},

        ]
    }


    renderComplete(){
        return this.renderForm();
    }


    onRemove = e => {
        e && e.preventDefault && e.preventDefault();
        if (window.confirm(getLocalString(this.props, 'confirm_removing_froala_page'))){
            this.removeItem(this.state);
        }


    };


    afterRemove() {
        this.props.history.push(purl(this.props, '/'));
    }

    renderWithVerification() {
        const form = super.renderWithVerification();


        const onSubmit = () => this.onSubmit;

        return <div>
            <div style={{
                display: 'flex',
                flexDirection: 'row-reverse'
            }}>





                <Button
                color={'red'}
                basic
                icon={'cancel'}
                content={getLocalString(this.props, 'remove')}
                onClick={this.onRemove}
                />

                <Button
                    color={'teal'}
                    icon={'save'}
                    content={getLocalString(this.props, 'save')}
                    onClick={onSubmit.bind(this)}
                />

            </div>

            <Message
                content={getLocalString(this.props, 'froala_no_autosave_message')}

            />

            {form}
        </div>
    }


}