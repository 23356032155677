import React from 'react';
import {BasicCreateForm} from "../../../utils/proto/mixins/form/create";
import {Message, Segment} from "semantic-ui-react";
import {getLocalString} from "../../../../utils/localString";
import {purl} from "../../../utils/proto/general";


export class FroalaCreatePageView extends BasicCreateForm('project_froala_page'){



    renderComplete() {

        const {id} = this.state.payload;

        if (id){
            this.props.history.push(purl(this.props, `/froala/${id}`));
            return null;
        }
        return super.renderComplete();
    }


    getFormMeta(){

        return [
            {name: 'name', type: 'text', title: 'page_name'},
        ]
    }


}
