import React from 'react';


export const WithCompany = (Component) => class extends React.Component {


    get company_id(){
        return this.props.match.params.company_id;
    }

    get company(){
        return this.props.state.company.items[this.company_id];
    }

    render(){

        let {company_id, company} = this;
        return <Component {...this.props} currentCompany={{company_id, company}}/>
    }
};