/**
 * Basic Store
 */
export class Store {


    name = 'storeDropdown.js';
    multiple = 'stores';


    get defaultState() {
        return {

        }
    }

    constructor({name, multiple=`${name}s`}){
        this.name = name;
        this.multiple = multiple;
    }


    onAction(state){
        return state;
    }

    get reducer(){

        return (state=this.defaultState, action) => {
            return this.onAction(state, action);
        }

    }



}