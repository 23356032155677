import {Store} from "../store";

export const action_update_session = 'action_update_session';
export const action_drop_session = 'action_drop_session';

export class SessionStore extends Store {



    get defaultState(){
        return {
            user: null,
            locale: null,
        }
    }


    onAction(state, action){


        switch (action.type){

            case action_update_session:
                return {...state, ...action.data};
            case action_drop_session:
                return this.defaultState;

            default:
                return state;
        }
    }

}