import {getLocalString} from "../../../utils/localString";


export const AdminCompanyForm = (sc) => class extends sc {


    title() {
        return this.state && this.state.name || getLocalString(this.props, 'new_company');
    }

    getFormMeta() {
        return [
            {name: 'name', title: 'name', type: 'text'},
            {name: 'address', title: 'address', type: 'text'},
            {name: 'trn', title: 'trn', type: 'text'},
            {name: 'reg_number', title: 'reg_number', type: 'text'}
        ];
    }


};