import React from 'react';
import {Button} from "semantic-ui-react";
import {getLocalString} from "../../../../../utils/localString";
export class GoalsFilter extends React.Component {






    renderCategories(){
        let {onChange, value, categories} = this.props;

        return categories.map(cat=><div style={{margin: 10}}>
            <Button
                content={cat.name}
                icon={value[cat.id] ? 'check' : 'circle outline'}
                color={value[cat.id] ? 'blue' : undefined}
                basic={value[cat.id] ? undefined : true}
                onClick={(e)=>{
                    e.preventDefault();
                    if (onChange){
                        onChange(e, {value: {...value, [cat.id]: !value[cat.id]}})
                    }
                }}
                size={'tiny'}
            />
        </div>)
    }

    toggleAll = state => e => {
        e && e.preventDefault && e.preventDefault();

        const {value, onChange} = this.props;

        for (const [key, enabled] of Object.entries(value)){

            if (state === true || state === false){
                value[key] = state;
            } else {
                value[key] = !enabled;


            }

        }

        onChange(null, {value});


    };

    render(){

        return <div>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
            {this.renderCategories()}

        </div>

            <div>
                <h4>{getLocalString(this.props, 'filter_actions')}</h4>
                <Button.Group size={'small'}>
                    <Button onClick={this.toggleAll(true)} icon={'check square outline'} content={getLocalString(this.props, 'select_all')} />
                    <Button onClick={this.toggleAll(false)} icon={'square outline'} content={getLocalString(this.props, 'unselect_all')}/>
                    <Button onClick={this.toggleAll()} icon={'exchange'} content={getLocalString(this.props, 'invert_selection')} />
                </Button.Group>
            </div>

        </div>

    }
}