import React from 'react';
import {TableWithStoreList} from "../../../utils/components/collections/table/store";
import {getLocalString} from "../../../../utils/localString";

import {Button, Segment} from 'semantic-ui-react';
import {WithStarFilter} from "../../../utils/components/collections/table/modifiers/withStarFilter";
import {WithFilter} from "../../../utils/components/collections/list/filter/withFilter";
import {GenericComponent, purl} from "../../../utils/proto/general";
import {ExpandableV2} from "../../../utils/components/expandable";
import {getCurrentProjectId} from "../../../../utils/sessionHelper";
export class ControlListProto extends TableWithStoreList('control') {


    showPresent = true;


    getMeta(){
        return {
            toDoTasksCount:  {title: 'to_do', type: 'todo_count', collapsing: true},
            acronym: {title: 'acronym', type: 'text'},
            description: {title: 'description', type: 'text'},
            //connected_risks: {title: 'risks', type: 'connected_risks', collapsing: true, navigates: false},

            lastAuditDate:  {title: 'last_audit', type: 'date',  collapsing: true},
            actions: {title: 'actions', type: 'actions', navigates: false, collapsing: true}
        }
    }

    renderTableFooter(){
        return null;
    }


    getData(){

        //const pid = getCurrentProjectId(this.props);

        return this.props.controls || this.props.state.control.list;
    }



    renderPreTable(){
        return null;
    }

    renderConnectedControls(item){
        let {risk_control_relation} = this.props.state;

        let relations = risk_control_relation.itemsByControlId[item.id];
        let relationsCount = Object.entries(relations || {}).length;

        return this.renderRelationsCount(relationsCount, `/projects/controls/${item.id}/risks`);
    }

    renderRelationsCount(count, editLink='/'){

        let props = {
            basic: true,
            color: 'red',

            //onClick: ()=>{this.props.history.push(editLink)},
            content: getLocalString(this.props, `no_relations`)
        };

        if (count) {
            props.color = 'blue';
            props.content = getLocalString(this.props, `connections`);
        }


        return <Button {...props} label={count || `0`} />

    }

    getCellContent(p){
        let {item, key} = p;
        switch (key){

            case 'toDoTasksCount':
                const value = Number(item[key]);

                return <span style={{color: value ? undefined : 'red'}}>{value}</span>

            case 'efficacy':
                const eff = Number(item.efficacy);
                return Math.round(eff);


            case 'connected_risks':
                return this.renderConnectedControls(item);
            case 'control_area':

                let {control_area_id} = item;
                let area = this.props.state.control_area.items[control_area_id];

                return area && area.name || getLocalString(this.props, 'no_control_area');

            default:
                return super.getCellContent(p)
        }
    }

}



class ControlListView extends GenericComponent {


    renderReportButton(){
        return <Button
            color={'green'}
            content={getLocalString(this.props, 'report')}
            icon={'table'}

            onClick={()=>{
                this.props.history.push(this.purl(`/reports/controls`));
            }}
        />
    }


    get isFiltered() {

        for (let field of Object.values(this.state.filterBy || {})) {
            if (field) return true;
        }

        return this.state.only_starred;
    }


    getData(){
        return this.props.state.control.list || [];
    }


    renderTitle(){
        return <h1>{getLocalString(this.props, 'controls')}</h1>
    }

    renderWithVerification(){

        const {isFiltered, showPresent} = this;
        const data = this.getData();

        if (isFiltered) {
            return <ControlListProto {...this.props} controls={data}/>
        }


        // Sort by efficacy
        data.sort((a,b)=>Number(b.efficacy) - Number(a.efficacy));

        const top5 = data.splice(0, 5)

        return <div style={{marginTop: 20}}>

            {this.renderTitle()}

            {this.renderReportButton()}
            <Button
                color={'green'}
                icon={'plus'}
                content={getLocalString(this.props, 'create')}

                onClick={()=>{
                    this.props.history.push(purl(this.props, `/controls/create?is_present=${Number(showPresent)}`))
                }}
            />

            <br/><br/>
            <ExpandableV2
                titleRow={<b style={{fontSize: 25}}>{getLocalString(this.props, 'top_5')}</b>}
                expanded
            >
                <ControlListProto {...this.props} controls={top5} />
            </ExpandableV2>

            <ExpandableV2
                titleRow={<b style={{fontSize: 18}}>{getLocalString(this.props, 'other')} ({data.length})</b>}
                expanded
            >
                <ControlListProto {...this.props} controls={data} />
            </ExpandableV2>

        </div>

    }
}




export class ControlList extends WithFilter(WithStarFilter(ControlListView)) {

    showPresent = true;

    get filterMeta(){
        return {
            acronym: {type: 'text'},
            description: {type: 'text'},
            control_area_id: {type: 'storeDropdown', store: 'control_area', text: item=>item.name}
        }
    }


    getData(){
        const data = super.getData().filter(x=>(this.showPresent === null) || (x.is_present === this.showPresent));
        return this.getFilteredData(data);
    }


}

