import React from 'react';
import {GenericComponent} from "../../utils/proto/general";

import {Grid, Segment} from 'semantic-ui-react'
import {Route, Switch} from 'react-router-dom';
import {AdminCompanyCreateForm} from "./create";
import {AdminCompanyEditForm} from "./edit";
import {AdminCompanyMenu} from "./menu";
import {getLocalString} from "../../../utils/localString";
import {CompanyAccessList} from "../../company/settings/advisors";
import {action_update_global_list} from "../../../mvvm/reducers/global";




class CompanyForm extends GenericComponent {

    componentDidUpdate(prevProps){


        let {id} =  this.props.match.params;
        let {id: prevId} = prevProps.match.params;
        if (prevId !== id) {
            //console.log(`Changed company ID: ${prevId} -> ${id}`);
            this.fetchRelations();
        } else {
            //console.log(`${prevId} === ${id} `);
        }


    }


    get company_id(){
        let {id} =  this.props.match.params;


        return id;
    }

    onSuccessResponse = (args)=>{


        let {payload, success} = args;

        if (success){

            console.log(`Dispatching '${action_update_global_list}'...`);
            this.props.dispatch({type: action_update_global_list, storeName: 'user_company_relation', data: payload})
        }

        return {};
    };



    fetchRelations = ()=>{
        let {id} =  this.props.match.params;


        this.api('/api/user_company_relation/list', {company_id: id});

    };

    componentDidMount(){

        this.fetchRelations();

    }

    renderWithVerification(){

        return [
            <Grid.Row key={'row_form'}>

                <Grid.Column width={4} textAlign={'center'}>
                    <Segment basic padded={'very'} compact>
                        <AdminCompanyMenu {...this.props} />
                    </Segment>

                </Grid.Column>

                <Grid.Column width={12}>
                    <AdminCompanyEditForm {...this.props}  />
                </Grid.Column>


            </Grid.Row>,
            <Grid.Row key={'row_invites'}>
                <Grid.Column width={4}>&nbsp;</Grid.Column>
                <Grid.Column mobile={12} tablet={6} computer={6}>
                    <h1>{getLocalString(this.props, 'company_owners')}</h1>
                    <CompanyAccessList {...this.props} userType={2} storeName={'user_company_relation'} company_id={this.company_id} noRefresh={true}/>
                </Grid.Column>

                <Grid.Column mobile={12} tablet={6} computer={6}>
                    <h1>{getLocalString(this.props, 'company_advisors')}</h1>
                    <CompanyAccessList {...this.props} userType={3}  storeName={'user_company_relation'} company_id={this.company_id} noRefresh={true} />
                </Grid.Column>
            </Grid.Row>
        ];
    }

}



export default class extends GenericComponent {




    renderCompanyForm(rp){

        return <CompanyForm {...this.props} {...rp} />
    }



    renderWithVerification() {
        return <Grid>
            <Switch>
                <Route path={'/admin/companies/create'} render={(rp) => <Grid.Row columns={1}>
                    <Grid.Column>
                        <AdminCompanyCreateForm {...this.props} {...rp} />
                    </Grid.Column>
                </Grid.Row>} />
                <Route path={'/admin/companies/:id'} render={ rp => this.renderCompanyForm(rp)} />
                <Route exact path={'*'} render={() => <Grid.Row columns={1}>
                    <Grid.Column textAlign={'center'} width={16}>
                        <Segment basic padded={'very'} compact>
                            <AdminCompanyMenu {...this.props} />
                        </Segment>
                    </Grid.Column>

                </Grid.Row>} />
            </Switch>


        </Grid>;
    }


}