import React from "react";
import {FroalaField} from "../components/froala_editor/proto";


const baseConfig = {
    videoUpload: false,





};

export const withFroala = (sc) => class extends sc {


    getFroalaConfig(meta){

        let {name} = meta;
        let {id} = this.state;

        ///console.log('Update and submit', this.updateUrl, this.submitUrl);

        if (this.updateUrl){

            return {
                ...baseConfig,
                saveURL: this.updateUrl,
                saveParams: {id},
                saveParam: name
            }

        } else {
            return {...baseConfig}
        }
    };


    getBasicConfig = () => {

        return {

        }
    };


    renderCustomField(meta) {
        let {type} = meta;

        let fieldProps = this.getFieldProps(meta);

        // Build config
        let config = this.getFroalaConfig(meta, fieldProps);


        switch (type){
            case 'froala_field':
                return <div>
                    <h5>{fieldProps.label}</h5>
                    <FroalaField {...fieldProps} {...this.props} config={config} />
                </div>;

            case 'froala_basicField':
                config = {...config, ...this.getBasicConfig(meta, fieldProps)};
                return <div>
                    <h5>{fieldProps.label}</h5>
                    <FroalaField {...fieldProps} {...this.props} config={config}/>
                </div>;

            default:
                return super.renderCustomField(meta);
        }

    }

};
