



export const WithLocalization = (sc) => class extends sc {


    onLanguagesLoaded = (resp)=>{
        //console.log(resp);
    };

    componentDidMount = ()=>{
        if (super.componentDidMount) super.componentDidMount();


        if (this.api) {
            this.api('/api/locale_entry/list', {}, {onSuccessResponse: this.onLanguagesLoaded})
        }

    }
};