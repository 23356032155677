import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";


import {Button, Segment} from 'semantic-ui-react';
import {getLocalString} from "../../../../utils/localString";
import {SetupRiskView} from "./risk";
import {SetupControlView} from "./control";

import {getCurrentProject, getCurrentProjectId} from "../../../../utils/sessionHelper";
import {BasicUpdateForm} from "../../../utils/proto/mixins/form/update";
import {WithExtras} from "../../../utils/proto/mixins/withExtras";
import {action_update_global_list} from "../../../../mvvm/reducers/global";


class SetupUpdateForm extends BasicUpdateForm('setup', undefined, props=>props.setup.id) {


    renderPreForm(){
        let project = getCurrentProject(this.props);
        return <Segment padded>
                <h3>{project.name}</h3>
                {project.description}
            </Segment>
    }

    onSuccessResponse = () => {

        this.props.dispatch({type: action_update_global_list, storeName: 'setup', data: []});

    };

    getCompanyActivities = ()=>{

        let activities = Object.values(this.props.state.company_activity.items);
        let project = getCurrentProject(this.props);

        return activities.filter(x=>x.project_id == project.template_project_id).map(activity=>({
            text: activity.name,
            value: activity.id,
        }))
    };

    getFormMeta(){
        return [
            {name: 'company_activity_id', title: 'company_activity', type: 'dropdown', options: this.getCompanyActivities, storeName: 'company_activity', textFieldName: 'name', allowNull: false}
        ]
    }


}


export class SetupConfirmation extends GenericComponent {




    render(){

        return <Segment textAlign={'center'} padded={true}>

            <Segment.Group>
            <Segment compact>
                <h1>{getLocalString(this.props, 'risks')}</h1>

                <p>
                {getLocalString(this.props, 'risks_setup_is_complete')}
                </p>
                <Button
                    content={getLocalString(this.props, 'go_back_to_risk_setup')}
                    color={'purple'}
                    basic={true}
                    onClick={()=>{this.props.history.push(this.purl('/risks'))}}
                />
            </Segment>

            <Segment compact>
                <h1>{getLocalString(this.props, 'controls')}</h1>

                <p>
                {getLocalString(this.props, 'controls_setup_is_complete')}
                </p>
                <Button
                    content={getLocalString(this.props, 'go_back_to_controls_setup')}
                    color={'purple'}
                    basic={true}
                    onClick={()=>{this.props.history.push(this.purl('/controls'))}}
                />
            </Segment>

            <Segment compact>
                <h1>{getLocalString(this.props, 'complete')}</h1>

                <p>
                {getLocalString(this.props, 'setup_is_complete')}
                </p>
                <Button
                    content={getLocalString(this.props, 'press_confirm_to_finish_setup')}
                    color={'teal'}
                    basic={true}
                    onClick={()=>{
                        let project_id = getCurrentProjectId(this.props);

                        let pr = {
                            id: project_id,
                            is_setting_up: false,
                        };

                        this.api('/api/project/update', pr);
                    }}
                />
            </Segment>
            </Segment.Group>



        </Segment>

    }

}



class SetupRouter extends WithExtras(WithRouterLayout(GenericComponent), ['setup','company_activity']){



    prefix=this.purl('');

    routesMeta = {

        '/controls/:id': {Component: SetupControlView},
        '/controls': {Component: SetupControlView},

        '/confirmation': {Component: SetupConfirmation},

        '/risks/:id': {Component: SetupRiskView},
        '*': {Component: SetupRiskView},

    };


    renderWithVerification(){


        let setupList = this.props.state.setup.list;


        if (setupList.length > 0){

            let setupItem = setupList[0];

            return <Segment padded>
                <h1>{getLocalString(this.props, 'select_company_activity')}</h1>
                <div>{getLocalString(this.props, 'select_company_activity_description')}</div>
                <SetupUpdateForm {...this.props} setup={setupItem} />
            </Segment>
        }


        return super.renderWithVerification();

    }

}


export default SetupRouter;