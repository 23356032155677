import React from 'react';
import {getCurrentProject} from "../../../../../utils/sessionHelper";
import {Icon} from 'semantic-ui-react';
const categories = [
    {name: 'low', color: 'green', icon: 'check'},
    {name: 'medium', color: 'orange', icon: 'exclamation'},
    {name: 'high', color: 'red', icon: 'cancel'},

];

export const getRiskLevel = (props, residualRisk, is_opportunity) => {
    let project = getCurrentProject(props);

    let {risk_categories_thresholds = []} = project;

    let level = 0;

    while (level < categories.length-1){
        let ts = risk_categories_thresholds[level];
        if (Number(residualRisk)<ts) break;

        level++;
    }


    if (is_opportunity){
        level = categories.length-1 - level;
    }

    return level
};

export function getRiskIcon(props, residualRisk, is_opportunity, otherProps={}){
    const level = getRiskLevel(props, residualRisk, is_opportunity);

    let category = categories[level];
    let {color, icon, name} = category;


    return <Icon {...otherProps} name={icon} color={color} key={name} />

}