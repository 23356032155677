import React from 'react';
import {AuditTypeSelector} from "./typeSelector";
import {ControlDropdown} from "../../../../utils/components/form/components/store_dropdown/control_dropdown";

export const AuditForm = (sc)=> class extends sc {



    getFormMeta(){
        return [

            {name: 'title', title: 'audit_title', type: 'text'},


            {name: 'control_id', title: 'control', type: 'control_dropdown'},

            {name: 'date', title: 'date', type: 'date'},

            {name: 'type', type: 'audit_type', title: 'audit_type'},

            {name: 'notes', title: 'notes', type: 'froala_field'}

        ]
    }


    renderCustomField(meta){

        let {type} = meta;
        let fieldProps = this.getFieldProps(meta);

        switch (type){

            case 'audit_type':
                return <AuditTypeSelector {...this.props}  {...fieldProps}/>;
            case 'control_dropdown':
                return <ControlDropdown {...this.props} {...fieldProps}  />;
            default:
                return super.renderCustomField(meta);

        }
    }

};

