import React from 'react';
import {Segment, Button} from 'semantic-ui-react';
import {getLocalString} from "../../../../../utils/localString";
import {GenericFormPrototype} from "../../../components/form/prototype";
import {WithApi} from "../api";

export const WithSubmit = (sc, url) => class extends sc {


    get submitUrl(){
        return url;
    }

    state = {
        ...(super.state || {}),
        complete: false,
        errorBody: null
    };



    onSuccessResponse = (body, options)=>{
        //this.props.dispatch({type: action_update_global, data});
        //this.props.dispatch({type: action_update_session, payload});
        let initState = {};
        if (super.onSuccessResponse) initState=super.onSuccessResponse(body, options);
        return {...initState, errorBody: false, complete: true};
    };


    onErrorResponse = (body)=>{

        //if (super.onErrorResponse) super.onErrorResponse(body, options);


        let {payload, message} = body;


        let map = {};
        if (message === 'VALIDATION_ERROR'){

            (payload || []).forEach(error=>{
              map[error.path] = error;
            });
        }


        return {errorBody: {...body, validationMap: map} };

    };


    onSubmitPrepared = (item)=>{
        if (this.api){
            this.api(url, item);
        }
    };


    setIncomplete = () => {
        this.setState({
            complete: false,
        })
    };

    renderComplete(){
        return <Segment basic padded={'very'} textAlign={'center'}>

            <Button
                content={getLocalString(this.props, 'done')}
                color={'green'}
                icon={'check'}
                onClick={this.setIncomplete}
            />

        </Segment>
    }

    renderWithVerification(){

        //console.log(this.state);
        let {complete} = this.state;

        if (complete) return this.renderComplete();
        else return super.renderWithVerification();
    }
};


export const SubmitForm = (url) => WithSubmit(WithApi(GenericFormPrototype), url);