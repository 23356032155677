import React from 'react';
import {Message, Segment} from 'semantic-ui-react';


export const WithState = (superclass) => class extends superclass {


    get stores(){
        return this.props.state;
    }

    get dispatch(){
        return this.props.dispatch;
    }

    renderWithoutState() {
        return <Segment padded={'very'}><Message color={'red'}
                                                 header={'No state'}
                                                 content={`Component has no state!`}
        /></Segment>;
    }

    renderWithoutActions() {
        return <Segment padded={'very'}><Message color={'red'}
                                                 header={'No dispatch'}
                                                 content={`Component has no dispatch!`}
        /></Segment>;
    }

    renderAppComponent() {

        let {state, dispatch} = this.props;

        if (!state) return this.renderWithoutState();
        if (!dispatch) return this.renderWithoutActions();


        return super.renderAppComponent();
    }
};


