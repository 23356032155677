import React from 'react';
import {Form} from "semantic-ui-react";
import {getLocalString} from "../../../../../../utils/localString";

const typeName = 'storeDropdown';

export const WithStoreDropdownFilter = (sc) => class extends sc {



    filterItemByMeta(args) {

        if (args.meta.type === typeName){
            const filterValue = this.getFilterFieldValue(args);

            if (!filterValue) return true;
        }



        return super.filterItemByMeta(args);


    }



    getOptionsForStoreDropdown(args){

        const {meta} = args;

        const {store: storeName, text: textGetter, id: idGetter = item=>item.id} = meta;


        const store = this.props.state[storeName] || {};

        const values = (store.list || []).map(item=>({
            value: idGetter(item),
            text: textGetter(item)
        }));

        return [
            ...values,
            {value: null, text: getLocalString(this.props, 'none')}
        ];
    }

    getFilterFieldProps(args){

        const props = super.getFilterFieldProps(args);

        const options = this.getOptionsForStoreDropdown(args);

        return {
            ...props,
            options,
            placeholder: getLocalString(this.props, 'none')
        }
    }


    renderFilterField(args){
        const props = this.getFilterFieldProps(args);

        if (args.meta.type === typeName){
            return <Form.Dropdown {...props} />;
        }

        return super.renderFilterField(args);




    }

};