import React from 'react';
import {WithRouterLayout} from "../../../utils/proto/layouts/router";
import {GenericComponent} from "../../../utils/proto/general";

import {WithExtras} from "../../../utils/proto/mixins/withExtras";

import {OpportunityView} from "./list";


class Router extends WithExtras(WithRouterLayout(GenericComponent), ['risk_category', 'risk_control_relation', 'risk_problem_relation', 'risk']){

    prefix = this.purl('/opportunities' );

    routesMeta = {

        '*': {Component: OpportunityView},
    };

}



export default Router;