import React from 'react';
import {AppComponent, GenericComponent} from "../utils/proto/general";

import {Route, Switch} from 'react-router-dom';
import {getLocalString} from "../../utils/localString";


import {CompanyView} from '../company';
import ProjectView from '../company/project';
import SubscriptionView from '../subscription';
import {UserEditForm} from "../user/edit";
import AdminView from '../admin';
import {MyTasksList} from "../tasks/list";
import {Message} from "semantic-ui-react";
import {WithSidebarLayout} from "../utils/proto/layouts/sidebar";
import {DefaultPrimarySidebar} from "../sidebar/primary";
import {DefaultSecondarySidebar} from "../sidebar/secondary";

const LOGO_COMPRESSION_FACTOR = 10;
class Shell extends AppComponent {


    render(){

        let {props} = this;

        return <div>



            <img src={'/api/logo.png'} className={'print-only'} style={{
                width: 1024 / LOGO_COMPRESSION_FACTOR,
                height: 464 / LOGO_COMPRESSION_FACTOR,
            }}/>

            {this.props.render && this.props.render(this.state)}




        </div>

    }
}




class NotFound extends AppComponent {

    renderWithVerification() {


        return <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>

            <Message>
                {getLocalString(this.props, 'no_company_invites_text')}
            </Message>

        </div>
    }
}


const WithBasicShell = (Component) => WithSidebarLayout(DefaultPrimarySidebar, DefaultSecondarySidebar, Component);

export class ApplicationShell extends GenericComponent {


    routes = [
        {path: '/companies/:company_id/projects/:project_id/*', Cmp: ProjectView},
        {path: '/companies/:company_id/projects/:project_id', Cmp: ProjectView},
        {path: '/companies/:company_id/*', Cmp: CompanyView},
        {path: '/companies/:company_id', Cmp: CompanyView},
        {path: '/subscribe/:company_id', Cmp: SubscriptionView},
        {path: '/user/:id', Cmp: WithBasicShell(UserEditForm)},
        {path: '/admin', Cmp: WithBasicShell(AdminView)},
        {path: '/tasks', Cmp: WithBasicShell(MyTasksList)},
        {path: '*', Cmp: WithBasicShell(NotFound)},



    ].map(({path, Cmp}, key) =>
        <Route key={key}
               path={path}
               render={(rp)=><Shell {...this.props} {...rp} render={()=><Cmp {...this.props} {...rp} /> } />} />
    );


    renderWithVerification(){
        return <Switch>
            {this.routes}
        </Switch>
    }

}
